import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const MilestoneDrawer = ({
  showDrawer,
  setShowDrawer,
  milestones,
  handleRemoveMilestone,
  handleToggleComplete,
  handleAddMilestone,
  showAllMilestones,
  setShowModal,
  currentSemester
}) => {
  const [showInfo, setShowInfo] = useState(false);

  const drawerRef = useRef();

  const toggleInfo = (e) => {
    e.stopPropagation();
    setShowInfo((prev) => !prev);
  };

  // Sort milestones by due date
  const sortedMilestones = milestones.sort((a, b) => {
    const dateA = new Date(a.DueDate);
    const dateB = new Date(b.DueDate);
    return dateA - dateB;
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        event.target.closest('#crud-modal') === null &&
        event.target.closest('#alert-additional-content-1') === null
      ) {
        setShowDrawer(false);
      }
    };

    if (showDrawer) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDrawer, setShowDrawer]);

  return (
    <>
      <div
        ref={drawerRef}
        className={`fixed top-0 right-0 z-50 h-screen p-4 overflow-y-auto transition-transform ${
          showDrawer ? 'translate-x-0' : 'translate-x-full'
        } bg-gray-50 w-80`}
        tabIndex="-1"
        aria-labelledby="drawer-right-label"
        id="drawer-content"
      >
        <div className="flex flex-col h-full relative">
          <div className="flex justify-between items-center mb-4">
            <h5
              id="drawer-right-label"
              className="text-base font-semibold text-gray-500 flex items-center"
            >
              {showAllMilestones ? 'All Milestones' : 'Milestones'}
              <button
                className="p-1 ml-2 rounded"
                onClick={toggleInfo}
                aria-label="Show Info"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </button>
            </h5>
            <button
              type="button"
              onClick={() => setShowDrawer(false)}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex items-center justify-center"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
          </div>
          {sortedMilestones.length > 0 ? (
            <div className="relative flex-grow mb-4">
              <ol className="relative border-l border-gray-200">
                {sortedMilestones.map((milestone) => (
                  <li key={milestone.Milestone_ID} className="mb-10 ms-6">
                    <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3">
                      <svg
                        className="w-2.5 h-2.5 text-blue-800"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                      </svg>
                    </span>
                    <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                      {milestone.Title}
                    </h3>
                    {showAllMilestones && (
                      <p className="mb-2 text-sm font-normal leading-none text-gray-400">
                        <strong>Year: </strong>
                        {milestone.Milestone_Year} -{' '}
                        <strong>Semester: </strong>
                        {milestone.Milestone_Sem === 'S1' ? 'Fall' : 'Spring'}
                      </p>
                    )}
                    <time className="block mb-2 text-sm font-normal leading-none text-gray-400">
                      {new Date(milestone.createdAt).toLocaleDateString()}
                    </time>
                    <p className="mb-4 text-base font-normal text-gray-500">
                      {milestone.Description}
                    </p>
                    <p className="mb-4 text-base font-normal text-gray-500">
                      <strong>Due by: </strong>
                      {new Date(milestone.DueDate).toLocaleDateString()}
                    </p>
                    <div className="flex items-center mb-4">
                      <input
                        id={`completed-checkbox-${milestone.Milestone_ID}`}
                        type="checkbox"
                        checked={milestone.completed}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          handleToggleComplete(milestone, e);
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label
                        htmlFor={`completed-checkbox-${milestone.Milestone_ID}`}
                        className="ms-2 text-sm font-medium text-gray-900"
                      >
                        Completed
                      </label>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveMilestone(milestone);
                      }}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-red-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-red-100 focus:text-red-700"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ol>
            </div>
          ) : (
            <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
              No milestones added
            </span>
          )}
          {!showAllMilestones && (
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mt-0 w-full mt-auto"
              onClick={() => {
                if (currentSemester.year && currentSemester.semester) {
                  handleAddMilestone(currentSemester.year, currentSemester.semester);
                } else {
                  console.error('CurrentSemester is not set');
                }
                setShowModal(true);
              }}
            >
              Add Milestone
            </button>
          )}
        </div>
      </div>

      {showInfo && (
        <div
          id="alert-additional-content-1"
          className="fixed top-4 right-4 z-[9999] p-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
          role="alert"
          style={{ width: '25rem' }}
        >
          <div className="flex items-center">
            <svg
              className="flex-shrink-0 w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium">Milestone Information</h3>
          </div>
          <div className="mt-2 mb-4 text-sm">
            Milestones are a simple way to set goals during the school year. You
            can add, remove, and mark milestones as completed. You can also add
            recommended milestones from your Pathway.
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="text-blue-800 bg-transparent border border-blue-800 hover:!bg-blue-900 hover:!text-white focus:!ring-4 focus:!outline-none focus:!ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:!bg-blue-900 dark:!border-blue-800 dark:!text-blue-400 dark:hover:!text-white dark:focus:!ring-blue-800"
              onClick={toggleInfo}
              aria-label="Close"
            >
              Dismiss
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MilestoneDrawer;
