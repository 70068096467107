import React, { useState } from 'react';

const CollapsibleExtracurricular = ({ activity, onAddToFinalizedExtracurriculars }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleAddToFinalizedExtracurriculars = () => {
    onAddToFinalizedExtracurriculars(activity.ActivityName);
  };

  return (
    <div style={{ borderStyle: 'none', border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={toggleCollapse}>
        <p>Activity Name: {activity.ActivityName}</p>
        <p>Type: {activity.ActivityType}</p>
      </div>
      {!collapsed && (
        <div>
          <p>Description: {activity.ActivityDescription}</p>
          <p>Meeting Time: {activity.MeetingTime}</p>
          <p>Activity Advisor: {activity.ActivityAdvisor}</p>
          <button className="btn btn-primary m-2" onClick={handleAddToFinalizedExtracurriculars}>
            Join
          </button>
        </div>
      )}
    </div>
  );
};

export default CollapsibleExtracurricular;
