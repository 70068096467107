import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../firebase';

const ProfileJoyrideTour = () => {
  const { currentUser } = useAuth();
  const [showTutorial, setShowTutorial] = useState(false);
  const [disableScrolling, setDisableScrolling] = useState(true);

  useEffect(() => {
    const checkTutorialStatus = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);   
        if (userDoc.exists()) {
          let hasCompletedTutorial = userDoc.data().hasCompletedTutorial || {};
          if (typeof hasCompletedTutorial !== 'object') {
            hasCompletedTutorial = {};
          }
          if (!hasCompletedTutorial.StudentProfile) {
            setShowTutorial(true);
          }
        }
      }
    };

    checkTutorialStatus();
  }, [currentUser]);

  const handleJoyrideCallback = async (data) => {
    const { status, index } = data;
    const finishedStatuses = ['finished', 'skipped'];

    // Check if the current step is the last step
    if (index === steps.length - 1) {
      setDisableScrolling(false);
    }

    if (finishedStatuses.includes(status) && currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      let hasCompletedTutorial = userDoc.data().hasCompletedTutorial || {};
      if (typeof hasCompletedTutorial !== 'object') {
        hasCompletedTutorial = {};
      }
      hasCompletedTutorial.StudentProfile = true;
      await updateDoc(userDocRef, { hasCompletedTutorial });
      setShowTutorial(false);
    }
  };

  const steps = [
    {
      target: '.profile-header',
      content: 'This is your profile header with your personal information.',
    },
    {
      target: '.progress-bars',
      content: 'Here you can see your progress in your major specific courses and milestones.',
    },
    {
      target: '.portals',
      content: 'This section allows you to navigate to all your schools portals.',
    },
    // {
    //   target: '.grades',
    //   content: 'This section shows your current and cumulative GPA.',
    // },
    // {
    //   target: '.interests',
    //   content: 'These are your interests.',
    // },
    {
      target: '.schedule-card',
      content: 'This is your current years schedule.',
    },
  ];

  return (
    <Joyride
      steps={steps}
      continuous
      scrollToFirstStep={false}
      showSkipButton
      disableScrolling={disableScrolling}
      run={showTutorial}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          beaconSize: 36,
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          primaryColor: '#6497d6',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          textColor: '#333',
          width: undefined,
          zIndex: 100,
        },
      }}
    />
  );
};

export default ProfileJoyrideTour;
