import React, { useState } from 'react';

const AddVolunteerOpportunityForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    organization: '',
    location: '',
    dateAndTime: '',
    duration: '',
    skillsRequired: '',
    contactInformation: '',
    // Add other form fields as needed
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:4000/volunteeropportunities', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        console.error('Error adding volunteer opportunity. Status:', response.status);
        setSubmissionStatus('error');
        return;
      }

      const data = await response.json();
      console.log('Volunteer opportunity added successfully:', data);

      // Optionally, reset the form after successful submission
      setFormData({
        title: '',
        description: '',
        organization: '',
        location: '',
        dateAndTime: '',
        duration: '',
        skillsRequired: '',
        contactInformation: '',
        // Reset other form fields as needed
      });

      // Set submission status to success
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error adding volunteer opportunity:', error);
      setSubmissionStatus('error');
    }
  };

  return (
    <div style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} className="form-container">
      <h2 style={{ textAlign: 'center' }}>Add New Volunteer Opportunity</h2>
      {submissionStatus === 'success' && <p className="success-message">Volunteer opportunity added successfully!</p>}
      {submissionStatus === 'error' && <p className="error-message">Error adding volunteer opportunity. Please try again.</p>}
      <form onSubmit={handleSubmit} className="volunteer-opportunity-form">
        <div>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            style={{ width: '50%', padding: '2px', boxSizing: 'border-box', marginBottom: '2px', border: '1px solid #ddd', borderRadius: '5px' }}
          />
        </div>

        <div>
          <label htmlFor="description">Description:</label>
          <input
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            style={{ width: '50%', padding: '2px', boxSizing: 'border-box', marginBottom: '2px', border: '1px solid #ddd', borderRadius: '5px' }}
          />
        </div>

        <div>
          <label htmlFor="organization">Organization:</label>
          <input
            type="text"
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleInputChange}
            style={{ width: '50%', padding: '2px', boxSizing: 'border-box', marginBottom: '2px', border: '1px solid #ddd', borderRadius: '5px' }}
          />
        </div>

        <div>
          <label htmlFor="location">Location:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            style={{ width: '50%', padding: '2px', boxSizing: 'border-box', marginBottom: '2px', border: '1px solid #ddd', borderRadius: '5px' }}
          />
        </div>

        <div>
          <label htmlFor="dateAndTime">Date and Time:</label>
          <input
            type="text"
            id="dateAndTime"
            name="dateAndTime"
            value={formData.dateAndTime}
            onChange={handleInputChange}
            style={{ width: '50%', padding: '2px', boxSizing: 'border-box', marginBottom: '2px', border: '1px solid #ddd', borderRadius: '5px' }}
          />
        </div>

        <div>
          <label htmlFor="duration">Duration:</label>
          <input
            type="text"
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleInputChange}
            style={{ width: '50%', padding: '2px', boxSizing: 'border-box', marginBottom: '2px', border: '1px solid #ddd', borderRadius: '5px' }}
          />
        </div>

        <div>
          <label htmlFor="skillsRequired">Skills Required:</label>
          <input
            type="text"
            id="skillsRequired"
            name="skillsRequired"
            value={formData.skillsRequired}
            onChange={handleInputChange}
            style={{ width: '50%', padding: '2px', boxSizing: 'border-box', marginBottom: '2px', border: '1px solid #ddd', borderRadius: '5px' }}
          />
        </div>

        <div>
          <label htmlFor="contactInformation">Contact Information:</label>
          <input
            type="text"
            id="contactInformation"
            name="contactInformation"
            value={formData.contactInformation}
            onChange={handleInputChange}
            style={{ width: '50%', padding: '2px', boxSizing: 'border-box', marginBottom: '2px', border: '1px solid #ddd', borderRadius: '5px' }}
          />
        </div>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button type="submit" className="button" style={{ backgroundColor: '#235dfc', color: 'white', padding: '8px 16px', borderRadius: '5px', outline: 'none' }}>
            Add Volunteer Opportunity
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddVolunteerOpportunityForm;
