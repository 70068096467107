const calculateUserRecRating = (interests, personalities, skills) => {
    const RealisticInterests = ['hands-on tasks', 'tangible results', 'tools and machines', 'outdoors', 'sports', 'engineering', 'plants and animals', 'physical challenges'];
    const InvestigativeInterests = ['observing', 'researching', 'strategy games', 'solving problems', 'reading sci-fi and mysteries', 'analyzing', 'intellectual challenges', 'science'];
    const ArtisticInterests = ['attending music/dance/drama performances', 'writing/composing', 'other cultures', 'decorating', 'unstructured activities', 'cooking', 'literature', 'creative challenges'];
    const SocialInterests = ['volunteering', 'joining clubs', 'conversation', 'people challenges', 'being on a team', 'reality TV', 'education', 'healthcare'];
    const EnterprisingInterests = ['watching sports', 'debating', 'starting something new', 'politics', 'managing people or projects', 'making money', 'leadership challenges', 'real estate'];
    const ConventionalInterests = ['collecting info/things', 'concrete tasks', 'keeping records', 'keeping a detailed planner', 'cleaning', 'behind-the-scenes work', 'lists', 'organizing challenges'];
    const RealisticPersonalities = ['practical', 'action oriented', 'logical', 'persistent', 'calculated', 'reserved'];
    const InvestigativePersonalities = ['curious', 'rational', 'scholarly', 'reflective', 'independent', 'thorough'];
    const ArtisticPersonalities = ['creative', 'idealistic', 'imaginative', 'expressive', 'unconventional', 'impulsive'];
    const SocialPersonalities = ['compassionate', 'outgoing', 'cooperative', 'considerate', 'perceptive', 'intuitive'];
    const EnterprisingPersonalities = ['persuasive', 'assertive', 'competitive', 'optimistic', 'confident', 'risk-taker'];
    const ConventionalPersonalities = ['organized', 'detailed-oriented', 'systematic', 'punctual', 'efficient', 'precise'];
    const RealisticSkills = ['physical coordination', 'mechanical/electrical skills'];
    const InvestigativeSkills = ['evaluating information', 'hypothesizing', 'drawing conclusions', 'using research instruments'];
    const ArtisticSkills = ['writing/singing/acting/creating', 'improvisation', 'working under pressure', 'generating new ideas'];
    const SocialSkills = ['teaching/training', 'listening', 'mentoring/encouraging', 'developing relationships'];
    const EnterprisingSkills = ['public speaking', 'negotiating', 'making decisions', 'persuading'];
    const ConventionalSkills = ['following directions', 'note taking', 'managing information', 'creating processes'];
  
    const toLowerCaseArray = (arr) => arr.map(item => item.toLowerCase());
  
    const countOccurrences = (arr, categories) => arr.reduce((acc, item) => {
      categories.forEach(category => {
        if (category.includes(item.toLowerCase())) {
          acc++;
        }
      });
      return acc;
    }, 0);
  
    const interestsLower = toLowerCaseArray(interests);
    const personalitiesLower = toLowerCaseArray(personalities);
    const skillsLower = toLowerCaseArray(skills);
  
    const interestsSize = interestsLower.length;
    const personalitiesSize = personalitiesLower.length;
    const skillsSize = skillsLower.length;
  
    const RealisticInterestsRating = countOccurrences(interestsLower, [RealisticInterests.map(item => item.toLowerCase())]) / interestsSize || 0;
    const RealisticPersonalitiesRating = countOccurrences(personalitiesLower, [RealisticPersonalities.map(item => item.toLowerCase())]) / personalitiesSize || 0;
    const RealisticSkillsRating = countOccurrences(skillsLower, [RealisticSkills.map(item => item.toLowerCase())]) / skillsSize || 0;
  
    const InvestigativeInterestsRating = countOccurrences(interestsLower, [InvestigativeInterests.map(item => item.toLowerCase())]) / interestsSize || 0;
    const InvestigativePersonalitiesRating = countOccurrences(personalitiesLower, [InvestigativePersonalities.map(item => item.toLowerCase())]) / personalitiesSize || 0;
    const InvestigativeSkillsRating = countOccurrences(skillsLower, [InvestigativeSkills.map(item => item.toLowerCase())]) / skillsSize || 0;
  
    const ArtisticInterestsRating = countOccurrences(interestsLower, [ArtisticInterests.map(item => item.toLowerCase())]) / interestsSize || 0;
    const ArtisticPersonalitiesRating = countOccurrences(personalitiesLower, [ArtisticPersonalities.map(item => item.toLowerCase())]) / personalitiesSize || 0;
    const ArtisticSkillsRating = countOccurrences(skillsLower, [ArtisticSkills.map(item => item.toLowerCase())]) / skillsSize || 0;
  
    const SocialInterestsRating = countOccurrences(interestsLower, [SocialInterests.map(item => item.toLowerCase())]) / interestsSize || 0;
    const SocialPersonalitiesRating = countOccurrences(personalitiesLower, [SocialPersonalities.map(item => item.toLowerCase())]) / personalitiesSize || 0;
    const SocialSkillsRating = countOccurrences(skillsLower, [SocialSkills.map(item => item.toLowerCase())]) / skillsSize || 0;
  
    const EnterprisingInterestsRating = countOccurrences(interestsLower, [EnterprisingInterests.map(item => item.toLowerCase())]) / interestsSize || 0;
    const EnterprisingPersonalitiesRating = countOccurrences(personalitiesLower, [EnterprisingPersonalities.map(item => item.toLowerCase())]) / personalitiesSize || 0;
    const EnterprisingSkillsRating = countOccurrences(skillsLower, [EnterprisingSkills.map(item => item.toLowerCase())]) / skillsSize || 0;
  
    const ConventionalInterestsRating = countOccurrences(interestsLower, [ConventionalInterests.map(item => item.toLowerCase())]) / interestsSize || 0;
    const ConventionalPersonalitiesRating = countOccurrences(personalitiesLower, [ConventionalPersonalities.map(item => item.toLowerCase())]) / personalitiesSize || 0;
    const ConventionalSkillsRating = countOccurrences(skillsLower, [ConventionalSkills.map(item => item.toLowerCase())]) / skillsSize || 0;
  
    const RealisticFinalRating = (RealisticInterestsRating + RealisticPersonalitiesRating + RealisticSkillsRating) / 3;
    const InvestigativeFinalRating = (InvestigativeInterestsRating + InvestigativePersonalitiesRating + InvestigativeSkillsRating) / 3;
    const ArtisticFinalRating = (ArtisticInterestsRating + ArtisticPersonalitiesRating + ArtisticSkillsRating) / 3;
    const SocialFinalRating = (SocialInterestsRating + SocialPersonalitiesRating + SocialSkillsRating) / 3;
    const EnterprisingFinalRating = (EnterprisingInterestsRating + EnterprisingPersonalitiesRating + EnterprisingSkillsRating) / 3;
    const ConventionalFinalRating = (ConventionalInterestsRating + ConventionalPersonalitiesRating + ConventionalSkillsRating) / 3;
  
    return {
      realistic: RealisticFinalRating,
      investigative: InvestigativeFinalRating,
      artistic: ArtisticFinalRating,
      social: SocialFinalRating,
      enterprising: EnterprisingFinalRating,
      conventional: ConventionalFinalRating
    };
  };
  
  export default calculateUserRecRating;
  