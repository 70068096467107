import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'; // Import fontkit

// Function to fetch and embed images or fonts
async function fetchAndEmbedAsset(pdfDoc, url, type) {
  try {
    const response = await fetch(url);

    // Check if the response is OK
    if (!response.ok) {
      throw new Error(`Failed to fetch ${type} from ${url}: ${response.statusText}`);
    }

    const assetBytes = await response.arrayBuffer();

    if (type === 'image') {
      return pdfDoc.embedPng(assetBytes);
    } else if (type === 'font') {
      return pdfDoc.embedFont(assetBytes);
    }
  } catch (error) {
    console.error(`Error fetching and embedding ${type}:`, error);
    throw error; // Re-throw the error to be caught later
  }
}

export const createPDFDocument = async (finalizedOrganizedCourses, currentUser) => {
  try {
    const pdfDoc = await PDFDocument.create();

    // Register fontkit
    pdfDoc.registerFontkit(fontkit);

    // Load custom fonts
    const robotoFont = await fetchAndEmbedAsset(
      pdfDoc,
      `${process.env.PUBLIC_URL}/fonts/Roboto-Regular.ttf`,
      'font'
    );
    const robotoBoldFont = await fetchAndEmbedAsset(
      pdfDoc,
      `${process.env.PUBLIC_URL}/fonts/Roboto-Bold.ttf`,
      'font'
    );

    // Fetch and embed the logo image
    const logoImage = await fetchAndEmbedAsset(
      pdfDoc,
      `${process.env.PUBLIC_URL}/edviseicon.png`,
      'image'
    );

    // Fetch the numYears from the active tab
    const activeTab = currentUser?.activeTab || '';
    const numYears = currentUser?.schedules?.[activeTab]?.numYears || 4; // Default to 4 years if numYears is not defined

    // Define constants for layout and styling
    const marginTop = 0;
    const marginBottom = 50; // Adjusted bottom margin
    const marginLeft = 20; // Decreased left margin
    const marginRight = 20; // Decreased right margin
    const lineHeight = 15;
    const columnWidth = 270;
    const headerFontSize = 18;
    const subHeaderFontSize = 14;
    const bodyFontSize = 10;
    const headerColor = rgb(0, 0, 0); // Black color
    const subHeaderColor = rgb(0, 0, 0); // Black color
    const bodyTextColor = rgb(0, 0, 0);

    let page = pdfDoc.addPage([612, 792]); // Standard US Letter size (8.5 x 11 inches)
    let { width, height } = page.getSize();
    let yPosition = height - marginTop;

    // Function to add a new page
    const addNewPage = () => {
      page = pdfDoc.addPage([612, 792]);
      width = page.getSize().width;
      height = page.getSize().height;
      yPosition = height - marginTop;
      drawHeader(); // Draw the header for each new page
    };

    // Function to draw header information
    const drawHeader = () => {
      // Draw header background
      page.drawRectangle({
        x: 0,
        y: height - 80,
        width: width,
        height: 80,
        color: rgb(0.95, 0.95, 0.95), // Light gray background
      });

      // Draw the logo on the top-left corner
      const logoDims = logoImage.scale(0.03); // Make logo smaller
      page.drawImage(logoImage, {
        x: 20,
        y: height - 70,
        width: logoDims.width,
        height: logoDims.height,
      });

      // Draw the document title next to the logo
      page.drawText(`${numYears}-Year Plan of Study`, {
        x: marginLeft + logoDims.width + 10, // Position next to logo with some spacing
        y: height - 50,
        size: headerFontSize,
        font: robotoBoldFont,
        color: headerColor,
      });

      // Draw student information on the top-right within the header
      const studentInfoLabels = ['Student Name:', 'Major:', 'Minor:', 'Plan Name:'];
      const studentInfoValues = [
        `${currentUser?.firstName ?? 'N/A'} ${currentUser?.lastName ?? 'N/A'}`,
        `${currentUser?.surveyAnswers?.Majors?.join(', ') ?? 'N/A'}`,
        `${currentUser?.surveyAnswers?.Minors?.join(', ') ?? 'N/A'}`,
        `${currentUser?.activeTab ?? 'N/A'}`,
      ];

      const infoStartX = width - marginRight - 175; // Increased width for better spacing
      const infoStartY = height - 20;
      const infoSpacing = 16;

      studentInfoLabels.forEach((label, index) => {
        // Draw label
        page.drawText(label, {
          x: infoStartX,
          y: infoStartY - index * infoSpacing,
          size: bodyFontSize,
          font: robotoBoldFont,
          color: bodyTextColor,
        });

        // Calculate dynamic x position based on label width
        const labelWidth = robotoBoldFont.widthOfTextAtSize(label, bodyFontSize);
        const valueX = infoStartX + labelWidth + 5; // 5px space after label

        // Draw value
        page.drawText(studentInfoValues[index], {
          x: valueX,
          y: infoStartY - index * infoSpacing,
          size: bodyFontSize,
          font: robotoFont,
          color: bodyTextColor,
        });
      });

      // Update yPosition after header
      yPosition = height - 100;
    };

    // Draw the initial header
    drawHeader();

    // Update yPosition after header
    yPosition = yPosition - 20; // Add some space after header

    // Dynamically create coursesByYear based on numYears
    const coursesByYear = {};
    for (let i = 1; i <= numYears; i++) {
      coursesByYear[`year${i}`] = {
        Fall: finalizedOrganizedCourses?.[`year${i}`]?.Fall || [],
        Spring: finalizedOrganizedCourses?.[`year${i}`]?.Spring || [],
      };
    }

    const startYear = Number(currentUser?.surveyAnswers?.SchoolStart?.year || new Date().getFullYear());

    // Iterate over each dynamically generated year
    for (let yearIndex = 1; yearIndex <= numYears; yearIndex++) {
      const year = `year${yearIndex}`;
      const yearTitle = `Year ${yearIndex} (${startYear + yearIndex - 1} - ${startYear + yearIndex})`;

      // Check if the year will fit on the current page, otherwise add a new page
      if (yPosition - 150 < marginBottom) {
        addNewPage();
      }

      // Move yPosition up to position the year title correctly
      yPosition += 10; // Adjusted from yPosition -= 10 to yPosition += 10

      // Draw the year title
      page.drawText(yearTitle, {
        x: marginLeft,
        y: yPosition,
        size: subHeaderFontSize,
        font: robotoBoldFont,
        color: subHeaderColor,
      });
      yPosition -= lineHeight;

      // Introduce additional spacing before drawing the separator line
      yPosition += 5; // Added extra space above the separator line

      // Draw a separator line after the year title
      page.drawLine({
        start: { x: marginLeft, y: yPosition },
        end: { x: width - marginRight, y: yPosition },
        thickness: 1,
        color: rgb(0.8, 0.8, 0.8),
      });
      yPosition -= 20; // Reduced space below the separator line

      // Set separate x positions for Fall and Spring columns
      const fallX = marginLeft;
      const springX = marginLeft + columnWidth + 20; // 20px gap between columns
      const semesterYStart = yPosition;

      // Function to draw semester courses
      const drawSemester = (semesterName, courses, startX) => {
        let currentY = semesterYStart;

        // Draw semester header
        page.drawText(semesterName, {
          x: startX,
          y: currentY,
          size: subHeaderFontSize,
          font: robotoBoldFont,
          color: headerColor,
        });
        currentY -= lineHeight;

        // Draw courses
        let totalCredits = 0;
        courses.forEach((course) => {
          totalCredits += course.Credits;
          const courseText = `${course.courseInfo_courseNumber}: ${course.courseInfo_courseName} (${course.Credits} Credits)`;
          const courseTextWrapped = wrapText(courseText, 60); // Increased max line length to allow longer course names

          courseTextWrapped.forEach((line) => {
            // Check if the current position is below the margin and add a new page if needed
            if (currentY < marginBottom + 60) { // Added extra space to accommodate the semester header gap
              addNewPage();
              currentY = height - marginTop - 120; // Increased gap from top (e.g., 120px)
              // Redraw semester header on the new page
              page.drawText(semesterName, {
                x: startX,
                y: currentY,
                size: subHeaderFontSize,
                font: robotoBoldFont,
                color: headerColor,
              });
              currentY -= lineHeight;
            }

            // Align course names with semester headers and total credits
            page.drawText(line, {
              x: startX, // Align with semester headers
              y: currentY,
              size: bodyFontSize,
              font: robotoFont,
              color: bodyTextColor,
            });
            currentY -= lineHeight;
          });
        });

        // Draw total credits
        page.drawText(`Total Credits: ${totalCredits}`, {
          x: startX, // Align with semester headers
          y: currentY - 10,
          size: bodyFontSize,
          font: robotoBoldFont,
          color: bodyTextColor,
        });
        currentY -= lineHeight + 10;

        return currentY;
      };

      // Draw Fall and Spring semesters
      const fallCourses = coursesByYear[year]['Fall'] || [];
      const springCourses = coursesByYear[year]['Spring'] || [];

      const fallYPosition = drawSemester('Fall Semester', fallCourses, fallX);
      const springYPosition = drawSemester('Spring Semester', springCourses, springX);

      // Update the main yPosition to the lowest point of both columns
      yPosition = Math.min(fallYPosition, springYPosition) - 20;

      // Add new page if the remaining space is insufficient
      if (yPosition < marginBottom) {
        addNewPage();
      }
    }

    // Add page numbers
    const totalPages = pdfDoc.getPageCount();
    pdfDoc.getPages().forEach((page, index) => {
      page.drawText(`Page ${index + 1} of ${totalPages}`, {
        x: width / 2 - 30,
        y: marginBottom - 20,
        size: 8,
        font: robotoFont,
        color: rgb(0, 0, 0), // Black color for consistency
      });
    });

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};

// Helper function to wrap text within a specific character length
const wrapText = (text, maxLineLength) => {
  const words = text.split(' ');
  const lines = [];
  let currentLine = '';

  words.forEach((word) => {
    if ((currentLine + word).length > maxLineLength) {
      lines.push(currentLine.trim());
      currentLine = '';
    }
    currentLine += `${word} `;
  });

  if (currentLine.trim().length > 0) {
    lines.push(currentLine.trim());
  }

  return lines;
};
