// Staff.js
import React, { useState } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import UsersList from './UsersList'; // New component for displaying users
import GroupsList from './GroupsList'; // New component for displaying groups
import { Button } from 'flowbite-react';

const Staff = () => {
  const [activeTab, setActiveTab] = useState('users'); // Manage active tab state

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-64">
        {/* Top bar with tab navigation */}
        <div className="fixed top-0 right-0 w-full bg-white dark:bg-gray-800 z-10 px-4 py-3 flex justify-between items-center" style={{ width: 'calc(100% - 16rem)' }}>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Staff Management</h2>
          <div className="flex space-x-4">
            <Button onClick={() => setActiveTab('users')} color={activeTab === 'users' ? 'blue' : 'gray'}>
              Users
            </Button>
            <Button onClick={() => setActiveTab('groups')} color={activeTab === 'groups' ? 'blue' : 'gray'}>
              Groups
            </Button>
          </div>
        </div>

        {/* Main content based on active tab */}
        <div className="mt-20 p-6">
          {activeTab === 'users' && <UsersList />} {/* Display Users tab */}
          {activeTab === 'groups' && <GroupsList />} {/* Display Groups tab */}
        </div>
      </div>
    </div>
  );
};

export default Staff;
