import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

const ExceptionsSummary = ({ data }) => {
  if (!data) return <div className="text-center p-4">No data available.</div>;

  return (
    <div className="bg-white shadow-md rounded p-4 mb-4">
      <div className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-sm font-medium uppercase tracking-wide">
            Exceptions Summary
          </h2>
          <InformationCircleIcon className="w-4 h-4 ml-1 text-gray-500" />
        </div>
        <div className="text-center">
          <p className="text-3xl font-bold">{data.exceptions}</p>
          <p className="text-sm text-gray-500">Exceptions</p>
          <p className="text-3xl font-bold">{data.students}</p>
          <p className="text-sm text-gray-500">Students</p>
        </div>
      </div>
    </div>
  );
};

export default ExceptionsSummary;
