import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import CourseSearch from './CourseSearch';
import { useAuth } from '../../../contexts/AuthContext';
import CustomSidebar from '../Sidebar/Sidebar'

const EditCourseData = () => {
  const [initialSelectedCourse, setInitialSelectedCourse] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [displayedCourses, setDisplayedCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const [levelFilter, setLevelFilter] = useState('');
  const [majorFilter, setMajorFilter] = useState('');
  const [creditsFilter, setCreditsFilter] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 50;

  const { fetchedCourseData } = useAuth();

  useEffect(() => {
    if (fetchedCourseData) {
      setLoading(false);
    }
  }, [fetchedCourseData]);

  useEffect(() => {
    if (fetchedCourseData) {
      const results = Object.values(fetchedCourseData).filter(course => {
        const matchesSearchQuery = course.courseInfo_courseName.toLowerCase().includes(searchQuery.toLowerCase()) || course.courseInfo_courseNumber.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesLevelFilter = levelFilter ? parseInt(levelFilter) === course.courseYear : true;
        const matchesMajorFilter = majorFilter ? course.Major.includes(majorFilter) : true;
        const matchesCreditsFilter = creditsFilter ? parseInt(creditsFilter) === parseInt(course.Credits, 10) : true;
        
        return matchesSearchQuery && matchesLevelFilter && matchesMajorFilter && matchesCreditsFilter;
      });
      setFilteredCourses(results);
      setDisplayedCourses(results.slice(0, coursesPerPage * currentPage));
    }
  }, [searchQuery, levelFilter, majorFilter, creditsFilter, fetchedCourseData, currentPage]);

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setInitialSelectedCourse(course);
    setSearchQuery(course.courseInfo_courseNumber + ' - ' + course.courseInfo_courseName);
    setFilteredCourses([]);
    setIsEditMode(true);
  };

  const handleResetFilters = () => {
    setSearchQuery('');
    setLevelFilter('');
    setMajorFilter('');
    setCreditsFilter('');
  };

  const loadMoreCourses = () => {
    if (displayedCourses.length < filteredCourses.length) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handleScroll = (e) => {
    if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight) {
      loadMoreCourses();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="w-full p-4 ml-64">
        <div className="fixed top-0 left-0 w-full bg-white dark:bg-gray-800 z-10 flex justify-center items-center border-b border-gray-200" style={{ height: '6.3rem' }}>
          <div className="ml-64">
            <h1 className="text-lg font-medium text-blue-600">Edit Courses</h1>
          </div>
        </div>
        <div className="mt-28 mb-8 grid grid-cols-3 gap-4">
          <div className="col-span-1">
            <Sidebar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              levelFilter={levelFilter}
              setLevelFilter={setLevelFilter}
              majorFilter={majorFilter}
              setMajorFilter={setMajorFilter}
              creditsFilter={creditsFilter}
              setCreditsFilter={setCreditsFilter}
              handleResetFilters={handleResetFilters}
              displayedCourseCount={filteredCourses.length}
              handleShowRecommendations={() => {}}
              showRecommendedCourses={false}
            />
          </div>
          <div className="col-span-2">
            <CourseSearch
              loading={loading}
              filteredData={displayedCourses}
              handleAddClass={handleCourseClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCourseData;
