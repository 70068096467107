import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function NavBar({ disabled, onNavClick }) {
    const { currentUser, logout } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const userFullName = () => {
        if (currentUser && currentUser.firstName && currentUser.lastName) {
            return `${currentUser.firstName} ${currentUser.lastName}`;
        }
        return 'User';
    };

    const pages = [
        { to: '/Profile', label: 'Profile' },
        { to: '/Plan', label: 'My Plan' },
        { to: '/Pathways', label: 'Pathways' },
        { to: '/Search', label: 'Class Search' }
    ];

    return (
        <nav className="bg-white border-gray-200 dark:bg-gray-900 fixed w-full top-0 left-0 z-50">
            <div className="max-w-screen-xl flex items-center justify-between mx-auto h-20 px-4">
                <div className="flex items-center space-x-2 rtl:space-x-reverse fixed left-0 pl-4">
                    <Link
                        to="/Profile"
                        className={`flex items-center space-x-2 rtl:space-x-reverse ${disabled ? 'cursor-not-allowed' : ''}`}
                        onClick={disabled ? onNavClick : null}
                    >
                        <img src={`${process.env.PUBLIC_URL}/edviseicon.png`} className="h-16" alt="Logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Edvise</span>
                    </Link>
                </div>
                <div className="flex items-center md:order-2 space-x-3 rtl:space-x-reverse fixed right-0 pr-4">
                    <button
                        ref={buttonRef}
                        id="dropdownAvatarNameButton"
                        data-dropdown-toggle="dropdownAvatarName"
                        className="flex items-center text-sm pe-1 font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:me-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white"
                        type="button"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                        <span className="sr-only">Open user menu</span>
                        <img className="w-8 h-8 me-2 rounded-full" src={currentUser?.photoUrl || '/default.jpg'} alt="user photo" />
                        {userFullName()}
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                        </svg>
                    </button>
                    {dropdownOpen && (
                        <div ref={dropdownRef} className="z-50 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute right-0" style={{ top: '2.4rem' }} id="user-dropdown">
                            <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                                <div className="font-medium">{userFullName()}</div>
                                <div className="truncate">{currentUser.email}</div>
                            </div>
                                {!disabled && (
                                    <>
                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownAvatarNameButton">
                                            <li>
                                                <Link to="/Profile" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Profile</Link>
                                            </li>
                                            <li>
                                                <Link to="/Survey" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Survey</Link>
                                            </li>
                                        </ul>
                                    </>
                                )}
                            <div className="py-2">
                                <a
                                    onClick={handleLogout}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
                                >
                                    Sign out
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                <button data-collapse-toggle="navbar-user" type="button" className="inline-flex items-center p-1 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-user" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1 ml-auto mr-auto">
                    <ul className="flex flex-col font-medium p-0 md:space-x-4 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-transparent">
                        {pages.map((page, index) => (
                            <li key={index}>
                                <Link
                                    to={page.to}
                                    onClick={(e) => { if (disabled) onNavClick(e); }}
                                    className={`block py-2 px-2 text-gray-900 rounded ${disabled ? 'cursor-not-allowed' : 'hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 focus:outline-none dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'} ${location.pathname === page.to ? 'text-blue-700' : ''}`}
                                >
                                    {page.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
