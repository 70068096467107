import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FaPlus, FaCheck, FaTimes } from 'react-icons/fa';
import { db, auth } from '../firebase';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PossibilitiesModal2 = ({ course, isOpen, onClose, year, semester, onKeepCourse, finalizedSchedule, majorRecData}) => {
  const { fetchedCourseData } = useAuth();

  const [searchQuery, setSearchQuery] = useState('');
  const [levelFilter, setLevelFilter] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [userRecsData, setUserRecsData] = useState({});
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [showRecommendedCourses, setShowRecommendedCourses] = useState(false);
  const [showKeptCourses, setShowKeptCourses] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const sidebarRef = useRef(null);
  const dropdownRef = useRef(null);

  const levelFilterMap = {
    '1': '100',
    '2': '200',
    '3': '300',
    '4': '400',
  };

  const toggleInfo = () => {
    setShowInfo((prev) => !prev);
  };

  const getCourseName = (courseNumber) => {
    const course = fetchedCourseData.find((c) => c.courseInfo_courseNumber === courseNumber);
    return course ? course.courseInfo_courseName : 'Course Name Not Found';
  };

  const filterPossibilities = (possibilities) => {
    return possibilities.filter((possibility) => {
      const courseName = getCourseName(possibility.trim());
      const courseLevel = parseInt(possibility.trim().match(/\d+/)[0].charAt(0), 10) * 100;

      return (
        (searchQuery === '' || courseName.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (levelFilter.length === 0 || levelFilter.includes(String(courseLevel / 100)))
      );
    });
  };

  const possibilities = course.Possibilities ? course.Possibilities.split(', ') : [];
  const filteredPossibilities = filterPossibilities(possibilities);
  const filteredPossibilitiesCourses = fetchedCourseData.filter(course =>
    filteredPossibilities.includes(course.courseInfo_courseNumber)
  );

  const handleAddCourse = (courseNumber) => {
    onKeepCourse(course, courseNumber);
  };

  const isPossibilityKept = (courseNumber) => {
    const yearSchedule = finalizedSchedule[`year${year}`];
    if (!yearSchedule) return false;

    const semesterSchedule = yearSchedule[`S${semester}`];
    if (!semesterSchedule) return false;

    return semesterSchedule.courses.includes(courseNumber);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowRecommendedCourses(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        setCurrentUser(user);
        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userData = userDoc.data();

            const backendSurveyAnswers = userData.surveyAnswers || [];
            setSurveyAnswers(backendSurveyAnswers);

            const backendUserRecData = userData.recommendationRatings || {};
            setUserRecsData(backendUserRecData);

            const calculateRecScore = require('../CourseRecommendation');

            const matchingCourses = fetchedCourseData.filter(course =>
              filteredPossibilities.includes(course.courseInfo_courseNumber)
            );

            const recommendedCourses = matchingCourses.map(course => {
                const recScore = calculateRecScore(majorRecData[course.Major], backendUserRecData, course, backendSurveyAnswers);
                return { ...course, recScore };
              })
              .sort((a, b) => b.recScore - a.recScore);

            setRecommendedCourses(recommendedCourses);
          }
        }
      } catch (error) {
        console.error('User data not found', error);
      }
    };
    fetchUserData();
  }, []);

  const handleShowRecommendations = () => {
    setShowRecommendedCourses(!showRecommendedCourses);
  };

  const handleResetFilters = () => {
    setSearchQuery('');
    setLevelFilter([]);
    setShowRecommendedCourses(false);
  };

  const getBackgroundGradient = (score) => {
    const green = [144, 238, 144];
    const yellow = [255, 255, 224];
    const red = [255, 182, 193];

    let startColor, endColor;
    let percentage;

    if (score >= 50) {
      startColor = yellow;
      endColor = green;
      percentage = (score - 50) / 50;
    } else {
      startColor = red;
      endColor = yellow;
      percentage = score / 50;
    }

    const interpolateColor = (start, end, factor) =>
      start.map((startComponent, index) =>
        Math.round(startComponent + factor * (end[index] - startComponent))
      );

    const [r, g, b] = interpolateColor(startColor, endColor, percentage);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const renderFilterTags = () => {
    const tags = [];
    levelFilter.forEach(level => {
      tags.push({ label: `${levelFilterMap[level]} level`, type: 'level', value: level });
    });
    if (showRecommendedCourses) {
      tags.push({ label: 'Recommended', type: 'recommended' });
    }

    return tags.map((tag) => (
      <div
        key={tag.type + tag.value}
        className="bg-blue-100 bg-opacity-50 text-blue-700 font-semibold rounded-lg text-sm px-2 py-1 mr-2 flex items-center"
      >
        {tag.label}
        <button
          onClick={() => {
            if (tag.type === 'level') setLevelFilter(levelFilter.filter(level => level !== tag.value));
            if (tag.type === 'recommended') setShowRecommendedCourses(false);
          }}
          className="ml-2 text-blue-900 hover:text-blue-600"
        >
          <FaTimes />
        </button>
      </div>
    ));
  };

  const handleLevelChange = (e) => {
    const selectedLevel = e.target.value;
    if (!levelFilter.includes(selectedLevel)) {
      setLevelFilter([...levelFilter, selectedLevel]);
    }
  };

  return (
    <>
      <div className={`fixed top-0 right-0 z-50 h-screen p-4 overflow-y-auto transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} bg-white w-1/3 shadow-lg`} tabIndex="-1" aria-labelledby="drawer-right-label">
        <div ref={sidebarRef} className="flex flex-col h-full">
          <div className="sticky top-0 bg-white z-10 flex justify-between items-center">
            <div className="flex items-center">
            <button
              className="p-1 rounded text-gray-600"
              onClick={toggleInfo}
              aria-label="Show Info"
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </button>
            </div>
            <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex items-center justify-center">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
          </div>
          {course.Requirements && (
            <div className="p-2 text-center">
            <h4 className="text-lg font-medium text-gray-700">
              Requirements:{' '}
              <span className="text-lg font-light text-gray-600">
                {course.Requirements}
              </span>
            </h4>
          </div>
          )}
          <div className="p-2 border-b border-gray-300 text-center">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search courses..."
              className="w-full p-2 border border-gray-300 rounded mb-2"
            />
            <select
              value=""
              onChange={handleLevelChange}
              className="w-full p-2 border border-gray-300 rounded mb-2"
            >
              <option value="">Select Level</option>
              <option value="1">100</option>
              <option value="2">200</option>
              <option value="3">300</option>
              <option value="4">400</option>
            </select>
            <div className="flex items-center justify-end mt-2 relative">
              <div className="flex items-center mr-2">
                {renderFilterTags()}
              </div>
              <button
                className="text-gray-500 hover:text-gray-900 focus:outline-none"
                onClick={handleShowRecommendations}
              >
                {showRecommendedCourses ? 'Hide Recommendations' : 'Show Recommendations'}
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto mt-4">
            {(showRecommendedCourses ? recommendedCourses : filteredPossibilitiesCourses).map((possibility, index) => {
              const isKept = isPossibilityKept(possibility.courseInfo_courseNumber.trim());
              const course = showRecommendedCourses ? possibility : recommendedCourses.find(course => course.courseInfo_courseNumber === possibility.courseInfo_courseNumber.trim());

              return (
                <div
                  key={index}
                  className={`flex justify-between p-4 mb-4 border border-gray-300 rounded-lg bg-white shadow-sm cursor-pointer hover:bg-gray-100 transition duration-200`}
                  style={{ width: '95%', margin: '0 auto' }}
                >
                  <div className="w-1/2">
                    <div className="flex flex-col items-start mb-2">
                      <div className="font-medium text-lg mb-2">{possibility.courseInfo_courseNumber.trim()}</div>
                    
                      {showRecommendedCourses && course && (
                        <span
                          style={{ background: getBackgroundGradient(course.recScore) }}
                          className="ml-3 px-2 py-1 rounded-md text-xs font-semibold"
                        >
                          {course.recScore}
                        </span>
                        
                      )}
                     <div className="text-sm text-left text-gray-600 mb-2">{possibility.courseInfo_courseName}</div>
                      <div className="text-sm text-gray-600"><span className='font-bold'>Credits: </span>{possibility.Credits}</div>
                    </div>
                    
                  </div>
                  <button
                    onClick={() => handleAddCourse(possibility.courseInfo_courseNumber.trim())}
                    className={` ${
                      isKept
                        ? 'text-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-500 dark:focus:ring-green-800'
                        : 'text-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800'
                    } font-medium rounded-lg text-sm px-w text-center`}
                    disabled={isKept}
                  >
                    {isKept ? (
                      <span className="inline-flex items-center justify-center w-14 h-14 bg-green-100 rounded-full text-green-700">
                        <FaCheck />
                      </span>
                    ) : (
                      <span className="inline-flex items-center justify-center w-14 h-14 bg-blue-100 rounded-full text-blue-700">
                        <FaPlus />
                      </span>
                    )}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {showInfo && (
          <div id="alert-additional-content-1" className="fixed top-4 right-4 z-[9999] p-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800" role="alert" style={{ width: '25rem' }}>
            <div className="flex items-center">
              <svg className="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 1 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <h3 className="text-lg font-medium">Requirement Sidebar Info</h3>
            </div>
            <div className="mt-2 mb-4 text-sm">
              See all the possible requirement courses that fulfill the requirement for the selected requirement.
            </div>
            <div className="flex">
              <button 
                type="button" 
                className="text-blue-800 bg-blue-50 border border-blue-800 hover:bg-blue-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-blue-900 dark:border-blue-800 dark:text-blue-400 dark:hover:text-white dark:focus:ring-blue-800" 
                onClick={toggleInfo} 
                aria-label="Close"
              >
                Dismiss
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PossibilitiesModal2;
