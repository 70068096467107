import React from 'react';

const StudentDistribution = ({ students }) => {
  const distribution = students.reduce((acc, student) => {
    const year = student.gradYear || 'Unknown';
    if (!acc[year]) acc[year] = 0;
    acc[year]++;
    return acc;
  }, {});

  const distributionArray = Object.entries(distribution).map(([name, count]) => ({ name, count }));
  const maxCount = distributionArray.length > 0 ? Math.max(...distributionArray.map(student => student.count)) : 1;

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow p-4 md:p-6">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h2 className="text-xl font-bold mb-2">Graduation Distribution</h2>
        </div>
      </div>
      <div>
        {distributionArray.map((student, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <div className="flex-1">
              <span className="text-sm font-medium text-gray-900">{student.name}</span>
              <div className="w-full h-2 bg-gray-200 rounded-full mt-1">
                <div
                  className="h-full rounded-full bg-blue-600"
                  style={{ width: `${(student.count / maxCount) * 100}%` }}
                ></div>
              </div>
            </div>
            <div className="ml-2 text-sm font-medium text-gray-900">{student.count}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentDistribution;
