import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import CustomSidebar from '../Sidebar/Sidebar';
import { FaEnvelope, FaExclamationTriangle, FaUserFriends, FaCheckCircle } from 'react-icons/fa';
import { Modal, Button, TextInput, Label, Textarea } from 'flowbite-react';

// Sample data
const initialUpcomingItems = [
    { title: "Student Meeting", date: "2024-05-20", time: "10:00 AM", description: "Discuss academic progress and course selection for the upcoming semester." },
    { title: "Course Plan Review", date: "2024-05-21", time: "2:00 PM", description: "Review and finalize course plans for new students." },
    { title: "Advisory Board Meeting", date: "2024-05-22", time: "9:00 AM", description: "Monthly meeting to discuss department updates and student performance." },
    { title: "Workshop on Study Skills", date: "2024-05-23", time: "1:00 PM", description: "Conduct a workshop to help students improve their study skills." },
];

const Dashboard = () => {
    const navigate = useNavigate(); // Use the useNavigate hook
    const [upcomingItems, setUpcomingItems] = useState(initialUpcomingItems);
    const [showModal, setShowModal] = useState(false);
    const [newEvent, setNewEvent] = useState({
        title: '',
        date: '',
        time: '',
        description: ''
    });

    const [completedItems, setCompletedItems] = useState([]);

    const handleItemComplete = (index) => {
        const completedItem = upcomingItems.splice(index, 1)[0];
        setUpcomingItems([...upcomingItems]);
        setCompletedItems([...completedItems, completedItem]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent({ ...newEvent, [name]: value });
    };

    const handleAddEvent = () => {
        setUpcomingItems([...upcomingItems, newEvent]);
        setNewEvent({
            title: '',
            date: '',
            time: '',
            description: ''
        });
        setShowModal(false);
    };

    return (
        <div className="flex">
            <CustomSidebar />
            <div className="w-full p-4 ml-64">
                <div className="fixed top-0 left-0 w-full bg-white dark:bg-gray-800 z-10 flex justify-center items-center border-b border-gray-200" style={{ height: '6.3rem' }}>
                    <div className="ml-64">
                        <h1 className="text-lg font-medium text-blue-600">Dashboard</h1>
                    </div>
                </div>
                <div className="mt-28 mb-8 grid grid-cols-3 gap-4">
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md col-span-2">
                        <h2 className="text-xl font-semibold mb-4">Events and To Do</h2>
                        <ol className="relative border-l border-gray-200 dark:border-gray-700">
                            {upcomingItems.map((item, index) => (
                                <li className="mb-10 ml-4" key={index}>
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                                            <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{item.date} at {item.time}</time>
                                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{item.title}</h3>
                                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">{item.description}</p>
                                        </div>
                                        <button
                                            className="bg-green-500 text-white px-2 py-1 rounded ml-4"
                                            onClick={() => handleItemComplete(index)}
                                        >
                                            Complete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ol>
                        <Button onClick={() => setShowModal(true)} className="bg-blue-600 text-white py-2 px-4 rounded mt-4">Add an Event</Button>
                    </div>
                    <div className="col-span-1 flex flex-col gap-4">
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex items-center">
                            <FaEnvelope className="text-3xl text-blue-600" />
                            <div className="ml-4">
                                <p className="text-3xl font-bold text-gray-900 dark:text-white">4</p>
                                <p className="text-sm text-gray-600 dark:text-gray-400">New Messages</p>
                                <button className="bg-blue-600 text-white py-1 px-3 rounded mt-2" onClick={() => navigate('/AdminDashboard/messages')}>Respond</button>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex items-center">
                            <FaExclamationTriangle className="text-3xl text-yellow-600" />
                            <div className="ml-4">
                                <p className="text-3xl font-bold text-gray-900 dark:text-white">1</p>
                                <p className="text-sm text-gray-600 dark:text-gray-400">Student Alerts</p>
                                <p className="text-red-600 text-xs">John Smith needs attention</p>
                                <button className="bg-blue-600 text-white py-1 px-3 rounded mt-2">View Alerts</button>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex items-center">
                            <FaUserFriends className="text-3xl text-blue-600" />
                            <div className="ml-4">
                                <p className="text-3xl font-bold text-gray-900 dark:text-white">100</p>
                                <p className="text-sm text-gray-600 dark:text-gray-400">Number of Students</p>
                                <button className="bg-blue-600 text-white py-1 px-3 rounded mt-2" onClick={() => navigate('/AdminDashboard/students')}>View Students</button>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex items-center">
                            <FaCheckCircle className="text-3xl text-green-600" />
                            <div className="ml-4">
                                <p className="text-3xl font-bold text-gray-900 dark:text-white">4</p>
                                <p className="text-sm text-gray-600 dark:text-gray-400">Approve Plan Change</p>
                                <button className="bg-blue-600 text-white py-1 px-3 rounded mt-2">Approve</button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={showModal} onClose={() => setShowModal(false)} className="bg-transparent">
                    <Modal.Header className="bg-transparent">Add New Event</Modal.Header>
                    <Modal.Body className="bg-transparent">
                        <div className="space-y-4">
                            <div>
                                <Label htmlFor="title" value="Title" />
                                <TextInput id="title" name="title" value={newEvent.title} onChange={handleInputChange} required />
                            </div>
                            <div>
                                <Label htmlFor="date" value="Date" />
                                <TextInput id="date" name="date" type="date" value={newEvent.date} onChange={handleInputChange} required />
                            </div>
                            <div>
                                <Label htmlFor="time" value="Time" />
                                <TextInput id="time" name="time" type="time" value={newEvent.time} onChange={handleInputChange} required />
                            </div>
                            <div>
                                <Label htmlFor="description" value="Description" />
                                <Textarea id="description" name="description" value={newEvent.description} onChange={handleInputChange} required />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="bg-transparent">
                        <Button onClick={handleAddEvent} className="text-black">Add Event</Button>
                        <Button color="gray" onClick={() => setShowModal(false)}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Dashboard;
