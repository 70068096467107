import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../firebase';

const PlanJoyrideTour = () => {
  const { currentUser } = useAuth();
  const [showTutorial, setShowTutorial] = useState(false);

  useEffect(() => {
    const checkTutorialStatus = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          let hasCompletedTutorial = userDoc.data().hasCompletedTutorial || {};
          if (typeof hasCompletedTutorial !== 'object') {
            hasCompletedTutorial = {};
          }
          if (!hasCompletedTutorial.StudentPlan) {
            setShowTutorial(true);
          }
        }
      }
    };

    checkTutorialStatus();
  }, [currentUser]);

  const handleJoyrideCallback = async (data) => {
    const { status } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status) && currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      let hasCompletedTutorial = userDoc.data().hasCompletedTutorial || {};
      if (typeof hasCompletedTutorial !== 'object') {
        hasCompletedTutorial = {};
      }
      hasCompletedTutorial.StudentPlan = true;
      await updateDoc(userDocRef, { hasCompletedTutorial });
      setShowTutorial(false);
    }
  };

  const steps = [
    {
      target: '.year-heading',
      content: 'This is the heading for the current academic year.',
    },
    {
      target: '.DroppableSemester',
      content: 'Here you can see the planned courses for each semester. Drag and drop courses to organize them.',
    },
    {
      target: '.add-autofill-container',
      content: 'Use these buttons to add, autofill, or remove courses.',
    },
    {
      target: '.bottom-btns',
      content: 'Use these to edit your plan or view it in more detail.',
    },
  ];

  return (
    <Joyride
      steps={steps}
      continuous
      scrollToFirstStep={false}
      showSkipButton
      disableScrolling={true}
      run={showTutorial}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          beaconSize: 36,
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          primaryColor: '#6497d6',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          textColor: '#333',
          width: undefined,
          zIndex: 100,
        },
      }}
    />
  );
};

export default PlanJoyrideTour;
