// RequirementsSection.js

import React from 'react';

const RequirementsSection = ({ requirements, setRequirements, setShowClassSearch }) => {
  const handleRemoveRequirement = (index) => {
    const updatedRequirements = requirements.filter((_, idx) => idx !== index);
    setRequirements(updatedRequirements);
  };

  const handleSelectRequirement = (index) => {
    setShowClassSearch('requirements', index);
  };

  const handleAddRequirement = () => {
    setRequirements([
      ...requirements,
      {
        courseInfo_courseNumber: '',
        courseYear: '',
        CourseSem: '',
        Description: '',
        Requirements: '',
        Possibilities: '',
        courseType: 'Requirement',
      },
    ]);
  };

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Requirements</h3>
      {requirements.length === 0 ? (
        <p>No requirements currently included</p>
      ) : (
        <ul>
          {requirements.map((requirement, index) => (
            <li key={index} className="mt-2">
              <div className="flex items-center justify-between">
                <span>
                  {requirement.courseInfo_courseNumber || 'No requirement selected'}
                </span>
                <button
                  type="button"
                  onClick={() => handleSelectRequirement(index)}
                  className="bg-blue-600 text-white px-2 py-1 rounded"
                >
                  Select Requirement
                </button>
                <button
                  type="button"
                  onClick={() => handleRemoveRequirement(index)}
                  className="bg-red-600 text-white px-2 py-1 rounded ml-2"
                >
                  Remove
                </button>
              </div>
              <div className="flex mt-2">
                <select
                  value={requirement.courseYear}
                  onChange={(e) => {
                    const updatedRequirements = [...requirements];
                    updatedRequirements[index].courseYear = e.target.value;
                    setRequirements(updatedRequirements);
                  }}
                  className="w-1/2 p-2 border border-gray-300 rounded"
                >
                  <option value="" disabled>
                    Select Course Year
                  </option>
                  {[1, 2, 3, 4].map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <select
                  value={requirement.CourseSem}
                  onChange={(e) => {
                    const updatedRequirements = [...requirements];
                    updatedRequirements[index].CourseSem = e.target.value;
                    setRequirements(updatedRequirements);
                  }}
                  className="w-1/2 p-2 border border-gray-300 rounded ml-2"
                >
                  <option value="" disabled>
                    Select Semester
                  </option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                </select>
              </div>
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={handleAddRequirement}
        className="w-full mb-4 mt-2 bg-blue-600 text-white p-2 rounded"
      >
        Add Requirement
      </button>
    </div>
  );
};

export default RequirementsSection;
