import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';

const Sidebar = ({
  searchQuery,
  setSearchQuery,
  levelFilter,
  setLevelFilter,
  majorFilter,
  setMajorFilter,
  creditsFilter,
  setCreditsFilter,
  handleResetFilters,
  displayedCourseCount,
}) => {
  const { fetchedCourseData } = useAuth();
  const [majors, setMajors] = useState([]);

  useEffect(() => {
    if (fetchedCourseData) {
      const uniqueMajors = Array.from(new Set(Object.values(fetchedCourseData).flatMap(item => item.Major.split(',').map(major => major.trim())))).filter(Boolean);
      setMajors(uniqueMajors);
    }
  }, [fetchedCourseData]);

  return (
    <div className="p-3 bg-gray-200">
      <h5 className="text-lg text-center font-bold mb-4">Filters</h5>
      <div className="mb-2">
        <label htmlFor="searchQuery" className="block text-sm font-medium text-gray-700 mb-1">Search:</label>
        <input
          type="text"
          id="searchQuery"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
        />
      </div>
      <div className="mb-2">
        <label htmlFor="levelFilter" className="block text-sm font-medium text-gray-700 mb-1">Level:</label>
        <select
          id="levelFilter"
          value={levelFilter}
          onChange={(e) => setLevelFilter(e.target.value)}
          className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
        >
          <option value="">Select Level</option>
          <option value="1">1000</option>
          <option value="2">2000</option>
          <option value="3">3000</option>
          <option value="4">4000</option>
        </select>
      </div>
      <div className="mb-2">
        <label htmlFor="majorFilter" className="block text-sm font-medium text-gray-700 mb-1">Major:</label>
        <select
          id="majorFilter"
          value={majorFilter}
          onChange={(e) => setMajorFilter(e.target.value)}
          className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
        >
          <option value="">Select Major</option>
          {majors.map((major, index) => (
            <option key={index} value={major}>{major}</option>
          ))}
        </select>
      </div>
      <div className="mb-2">
        <label htmlFor="creditsFilter" className="block text-sm font-medium text-gray-700 mb-1">Credits:</label>
        <select
          id="creditsFilter"
          value={creditsFilter}
          onChange={(e) => setCreditsFilter(e.target.value)}
          className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
        >
          <option value="">Select Credits</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>
      <button
        className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-1"
        onClick={handleResetFilters}
      >
        Reset Filters
      </button>
      <div className="flex justify-center mt-2">
        <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
          {displayedCourseCount} Courses Displayed
        </span>
      </div>
    </div>
  );
};

export default Sidebar;