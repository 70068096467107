import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

const TopUsers = ({ data }) => {
  if (!data || data.length === 0) return <div className="text-center p-4">No data available.</div>;

  // Sort users data by count in descending order
  const sortedData = [...data].sort((a, b) => b.count - a.count);

  return (
    <div className="bg-white shadow-md rounded p-4 mb-4">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-sm font-medium uppercase tracking-wide">Top Users</h2>
        <InformationCircleIcon className="w-4 h-4 ml-1 text-gray-500" />
      </div>
      <div className="text-left">
        {sortedData.map((user, index) => (
          <p key={index}>{user.name} <span className="float-right text-gray-500">{user.count}</span></p>
        ))}
      </div>
      <input
        type="text"
        placeholder="Search for a user"
        className="w-full mt-2 p-2 border rounded"
      />
    </div>
  );
};

export default TopUsers;
