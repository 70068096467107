// UsersList.js
import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase'; // Import Firestore and Auth from Firebase
import { TextInput, Badge, Button } from 'flowbite-react'; // Flowbite components for consistent styling

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  // Fetch users with admin privileges from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await db.collection('users').where('role', 'in', ['admin', 'super_admin']).get();
        const usersData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
        setFilteredUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Filter users based on search input
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users.filter(user => user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
  }, [searchTerm, users]);

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <TextInput
          id="search"
          type="search"
          placeholder="Search by name, username, group..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3"
        />
        <Button>Filter</Button> {/* Add filter button if needed */}
      </div>
      <div className="bg-white rounded-lg shadow-md p-4">
        <h3 className="text-lg font-medium mb-4">{filteredUsers.length} Users</h3>
        <ul>
          {filteredUsers.map(user => (
            <li key={user.id} className="flex justify-between items-center py-2 border-b">
              <div className="flex items-center">
                <span className="bg-gray-300 rounded-full w-10 h-10 flex items-center justify-center mr-3 text-white">{user.firstName.charAt(0)}{user.lastName.charAt(0)}</span>
                <div>
                  <p className="font-medium">{user.firstName} {user.lastName}</p>
                  <p className="text-sm text-gray-500">{user.email}</p>
                </div>
              </div>
              <Badge color="green">Active</Badge>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UsersList;
