// GroupsList.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Import Firestore from your Firebase configuration
import { TextInput, Button, Modal, Checkbox } from 'flowbite-react'; // Using Flowbite for UI components
import StaffGroup from './StaffGroup'; // Component to display each group
import './GroupsList.css'; // CSS for custom styles

const GroupsList = () => {
  const [staffGroups, setStaffGroups] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [members, setMembers] = useState(0);
  const [permissions, setPermissions] = useState({
    advisingBlock: false,
    milestones: false,
    noteReferrals: false,
    notesVisibility: false,
    workflows: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);

  // Fetch groups from Firestore
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const snapshot = await db.collection('groups').get();
        const groupsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStaffGroups(groupsData);
        setFilteredGroups(groupsData);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, []);

  const handleToggleCreateForm = () => {
    setShowCreateForm(!showCreateForm);
    setError('');
  };

  const handleGroupNameChange = (e) => setGroupName(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleMembersChange = (e) => setMembers(e.target.value);
  const handlePermissionChange = (permission) => {
    setPermissions({ ...permissions, [permission]: !permissions[permission] });
  };
  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  // Filter groups based on search term
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredGroups(staffGroups);
    } else {
      setFilteredGroups(
        staffGroups.filter(group =>
          group.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, staffGroups]);

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!groupName) {
        setError('Group name is required');
        setLoading(false);
        return;
      }

      // Create new group in Firestore
      const newGroup = {
        name: groupName,
        description,
        members: parseInt(members, 10) || 0,
        permissions,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      // Add the new group to Firestore
      const docRef = await db.collection('groups').add(newGroup);

      // Update the local state with the new group
      setStaffGroups([...staffGroups, { id: docRef.id, ...newGroup }]);
      setFilteredGroups([...filteredGroups, { id: docRef.id, ...newGroup }]);

      // Reset form fields and close the form
      setGroupName('');
      setDescription('');
      setMembers(0);
      setPermissions({
        advisingBlock: false,
        milestones: false,
        noteReferrals: false,
        notesVisibility: false,
        workflows: false,
      });
      setShowCreateForm(false);
    } catch (err) {
      setError('Error creating group. Please try again.');
      console.error('Error creating group:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <TextInput
          id="search"
          type="search"
          placeholder="Search groups..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-1/3"
        />
        <Button onClick={handleToggleCreateForm} className="create-group-button">
          {showCreateForm ? 'Cancel' : 'Create New Group'}
        </Button>
      </div>

      {/* Create New Group Form Modal */}
      {showCreateForm && (
        <Modal show={showCreateForm} onClose={handleToggleCreateForm}>
          <Modal.Header>Create New Group</Modal.Header>
          <Modal.Body>
            <form onSubmit={handleCreateGroup} className="create-group-form">
              {error && <p className="error-message">{error}</p>}
              <div className="form-group">
                <label htmlFor="groupName">Group Name:</label>
                <TextInput
                  type="text"
                  id="groupName"
                  value={groupName}
                  onChange={handleGroupNameChange}
                  required
                  placeholder="Enter group name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <TextInput
                  type="text"
                  id="description"
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder="Enter group description"
                />
              </div>
              <div className="form-group">
                <label htmlFor="members">Members:</label>
                <TextInput
                  type="number"
                  id="members"
                  value={members}
                  onChange={handleMembersChange}
                  placeholder="Enter initial number of members"
                />
              </div>
              <div className="form-group">
                <label>Permissions:</label>
                <Checkbox
                  id="advisingBlock"
                  checked={permissions.advisingBlock}
                  onChange={() => handlePermissionChange('advisingBlock')}
                  label="One Advising Block For Group"
                />
                <Checkbox
                  id="milestones"
                  checked={permissions.milestones}
                  onChange={() => handlePermissionChange('milestones')}
                  label="Milestones"
                />
                <Checkbox
                  id="noteReferrals"
                  checked={permissions.noteReferrals}
                  onChange={() => handlePermissionChange('noteReferrals')}
                  label="Note Referrals"
                />
                <Checkbox
                  id="notesVisibility"
                  checked={permissions.notesVisibility}
                  onChange={() => handlePermissionChange('notesVisibility')}
                  label="Notes Visibility"
                />
                <Checkbox
                  id="workflows"
                  checked={permissions.workflows}
                  onChange={() => handlePermissionChange('workflows')}
                  label="Workflows"
                />
              </div>
              <Button type="submit" disabled={loading}>
                {loading ? 'Creating...' : 'Create Group'}
              </Button>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {/* Display Existing Groups */}
      <div className="staff-groups grid grid-cols-1 gap-4">
        {filteredGroups.map((group) => (
          <StaffGroup key={group.id} group={group} />
        ))}
      </div>
    </div>
  );
};

export default GroupsList;
