import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const DemandGraph = ({ course }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (course) {
      const updatedChartData = {
        ...course.demandGraph,
        datasets: course.demandGraph.datasets.map(dataset => ({
          ...dataset,
          borderWidth: 5, // Set line thickness here
          borderColor: '#4BC0C0', // Set line color here (darker color)
        })),
      };
      setChartData(updatedChartData);
    }
  }, [course]);

  if (!chartData) {
    return <div>Loading...</div>;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false }, // Hide the legend
      tooltip: { enabled: false } // Disable the tooltip
    },
    scales: {
      x: { display: true },
      y: { display: true, beginAtZero: true, max: 100 }
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4 md:p-6">
      <div className="flex justify-between items-start mb-2">
        <div><h5 className="text-sm font-medium text-gray-500 dark:text-gray-400">DEMAND GRAPH</h5></div>
      </div>
      <div className="h-96">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default DemandGraph;
