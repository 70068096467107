// Inside Messages.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import ChatComponent from '../../Messaging/ChatComponent'; // Adjusted path
import { getConversations } from '../../Messaging/talkjsService'; // Adjusted path
import './Messages.css'; // Add this import for custom CSS
import CustomSidebar from '../Sidebar/Sidebar'; // Adjusted path

const Messages = () => {
    const { studentId } = useParams(); // Use useParams to get the studentId from the URL
    const [conversations, setConversations] = useState([]);
    const currentUser = {
        id: '654321',
        name: 'Bob',
        email: 'bob@example.com',
        photoUrl: 'https://talkjs.com/images/avatar-5.jpg',
        role: 'advisor'
    };

    useEffect(() => {
        const fetchConversations = async () => {
            try {
                const conversations = await getConversations(currentUser.id);
                setConversations(conversations);
                console.log('Fetched Conversations:', conversations);
            } catch (error) {
                console.error('Error fetching conversations:', error);
            }
        };

        fetchConversations();
    }, []);

    const getOtherUser = (participants) => {
        if (participants) {
            const keys = Object.keys(participants);
            for (let i = 0; i < keys.length; i++) {
                const user = participants[keys[i]];
                if (user.id === studentId) { // Match with the studentId from the URL
                    return {
                        id: keys[i], // Use the key as the user id
                        name: user.name || 'Unknown',
                        email: user.email || null,
                        photoUrl: user.photoUrl || '' || null,
                        role: user.role || 'user'
                    };
                }
            }
        }
        return null;
    };

    return (
        <div className="flex">
            <CustomSidebar />
            <div className="w-full p-4 ml-64"> {/* Added margin-left */}
                <div className="fixed top-0 left-0 w-full bg-white dark:bg-gray-800 z-10" style={{ height: '6.3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: '1px solid #e5e7eb' }}>
                    <div className="ml-64"> {/* Added margin-left */}
                        <h1 className="text-lg font-medium text-blue-600">Messages</h1>
                    </div>
                </div>
                <div className="chat-container" style={{ height: '37rem', width: '48rem' }}>
                    {conversations.length === 0 ? (
                        <p>No conversations found.</p>
                    ) : (
                        conversations.map((conversation) => {
                            const otherUser = getOtherUser(conversation.participants);
                            return (
                                <div key={conversation.id} className="conversation">
                                    {otherUser ? (
                                        <ChatComponent currentUser={currentUser} otherUser={otherUser} />
                                    ) : (
                                        <p>No other participant found.</p>
                                    )}
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    );
};

export default Messages;
