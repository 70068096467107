import React, { useState } from 'react';
import { db } from '../../../firebase';

const StudentSearch = ({ onSelectStudent }) => {
  const [query, setQuery] = useState('');
  const [students, setStudents] = useState([]);

  const handleSearch = async () => {
    const snapshot = await db.collection('users').where('role', '==', 'student').get();
    const studentList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setStudents(studentList.filter(student => student.name.toLowerCase().includes(query.toLowerCase())));
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search students..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button onClick={handleSearch}>Search</button>
      <ul>
        {students.map(student => (
          <li key={student.id} onClick={() => onSelectStudent(student)}>
            {student.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StudentSearch;
