import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../NavBar';
import { Card, Button } from 'flowbite-react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../firebase';
import Question from './Question';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import calculateUserRecRating from './courseRecommendations';

const SurveyPage = () => {
  const { currentUser, fetchedSurveyData, fetchUserData, isUserDataFetched } = useAuth();
  const [loading, setLoading] = useState(true);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionKeys, setQuestionKeys] = useState({});
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (fetchedSurveyData) {
      const questions = fetchedSurveyData || {};
      const keys = {};

      const questionsArray = Object.keys(questions).map((key, index) => {
        const questionKey = questions[key]?.Key;
        keys[index] = questionKey;
        return questions[key];
      });


      setSurveyQuestions(questionsArray);

      console.log(questionsArray)

      setQuestionKeys(keys);

      const userSurveyAnswers = currentUser?.surveyAnswers || {};
      const mappedAnswers = {};

      Object.keys(userSurveyAnswers).forEach((answerKey) => {
        const questionIndex = Object.keys(keys).find((index) => keys[index] === answerKey);
        if (questionIndex !== undefined) {
          mappedAnswers[questionIndex] = userSurveyAnswers[answerKey];
        }
      });

      setSelectedAnswers(mappedAnswers);
      setLoading(false);
    }
  }, [fetchedSurveyData, currentUser]);

  const handleAnswerChange = (value, questionIndex) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: value,
    }));
    setIsSubmitted(false);
  };

  const handleSubmit = () => {
    console.log(questionKeys, 'hihi')
    const unanswered = [];
    surveyQuestions.forEach((question, index) => {
      const answer = selectedAnswers[index];
      const isRequired = question.Required;
      let isAnswered = false;

      switch (question.QuestionType) {
        case 'Multiple Choice':
        case 'Select All That Apply':
          isAnswered = Array.isArray(answer)
            ? answer.length > 0
            : answer !== undefined && answer !== null && answer !== '';
          break;
        case 'Text Input':
          isAnswered = answer !== undefined && answer !== null && answer.trim() !== '';
          break;
        case 'Preference Scale':
          isAnswered = answer !== undefined || answer == null;
          break;
        case 'Calendar Selection - Future':
        case 'Calendar Selection - Past':
          isAnswered = answer !== undefined && answer !== null && answer.month && answer.year;
          break;
        default:
          isAnswered = answer !== undefined && answer !== null;
          break;
      }

      if (isRequired && !isAnswered) {
        unanswered.push(index);
      }
    });

    setUnansweredQuestions(unanswered);

    if (unanswered.length > 0) {
      toast.error('Please answer all required questions before submitting.');
    } else {
      setIsSubmitting(true);
      handleConfirmSubmit();
    }
  };

  const handleConfirmSubmit = async () => {
    if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);

        const updatedSurveyAnswers = Object.keys(selectedAnswers).reduce((acc, key) => {
            const firestoreKey = questionKeys[key];
            if (firestoreKey) {
                if (
                    surveyQuestions[key].QuestionType === 'Preference Scale' &&
                    (selectedAnswers[key] === null || selectedAnswers[key] === undefined)
                ) {
                    acc[firestoreKey] = 0;
                } else {
                    acc[firestoreKey] = selectedAnswers[key] || null;
                }
            }
            return acc;
        }, {});

        let major = 'Undeclared';
        if (Array.isArray(updatedSurveyAnswers['Majors']) && updatedSurveyAnswers['Majors'].length > 0) {
            major = updatedSurveyAnswers['Majors'].join(' & ');
        }

        const userRecRatings = calculateUserRecRating(
            updatedSurveyAnswers['RecInterests'],
            updatedSurveyAnswers['RecPersonality'],
            updatedSurveyAnswers['RecSkills']
        );

        try {
            await db.runTransaction(async (transaction) => {
                const doc = await transaction.get(userDocRef);
                let numYearsFromDB = 4;

                // Correctly access SchoolStart and SchoolEnd
                const schoolStartKey = Object.keys(questionKeys).find(key => questionKeys[key] === 'SchoolStart');
                const schoolEndKey = Object.keys(questionKeys).find(key => questionKeys[key] === 'SchoolEnd');

                if (schoolStartKey && schoolEndKey) {
                    const schoolStartYear = selectedAnswers[schoolStartKey]?.year;
                    const schoolEndYear = selectedAnswers[schoolEndKey]?.year;
                    numYearsFromDB = (schoolEndYear - schoolStartYear) || 4;
                }

                // Set the numYears and schedules inside the active tab
                const schedules = doc.exists ? (doc.data().schedules || {}) : {};
                const defaultScheduleName = `${major} - ${numYearsFromDB} Years`;

                if (!schedules[defaultScheduleName]) {
                    // If the schedule doesn't exist, create it with the numYears inside the active tab
                    const newSchedule = {
                        numYears: numYearsFromDB
                    };
                    for (let i = 1; i <= numYearsFromDB; i++) {
                        newSchedule[`year${i}`] = {
                            S1: { courses: [] },
                            S2: { courses: [] },
                        };
                    }

                    schedules[defaultScheduleName] = newSchedule;

                    transaction.set(userDocRef, { schedules: schedules, activeTab: defaultScheduleName, primaryTab: defaultScheduleName }, { merge: true });
                }

                transaction.set(userDocRef, { surveyAnswers: updatedSurveyAnswers }, { merge: true });
                transaction.set(userDocRef, { recommendationRatings: userRecRatings }, { merge: true });
            });

            console.log('Resetting isUserDataFetched to false before calling fetchUserData');
            if (isUserDataFetched && isUserDataFetched.current !== undefined) {
                isUserDataFetched.current = false;
            } else {
                console.error('isUserDataFetched is undefined or null');
            }

            console.log('Calling fetchUserData');
            await fetchUserData(currentUser);

            setIsSubmitting(false);
            setIsSubmitted(true);

            navigate('/Profile');
        } catch (error) {
            console.error('Error submitting survey answers:', error);
            setIsSubmitting(false);
        }
    } else {
        console.error('Error: currentUser or currentUser.uid is undefined.');
        setIsSubmitting(false);
    }
};

  const allRequiredQuestionsAnswered = surveyQuestions.every((question, index) => {
    const answer = selectedAnswers[index];
    const isRequired = question.Required;
    let isAnswered = false;

    switch (question.QuestionType) {
      case 'Multiple Choice':
      case 'Select All That Apply':
        isAnswered = Array.isArray(answer)
          ? answer.length > 0
          : answer !== undefined && answer !== null && answer !== '';
        break;
      case 'Text Input':
        isAnswered = answer !== undefined && answer !== null && answer.trim() !== '';
        break;
      case 'Preference Scale':
        isAnswered = answer !== undefined;
        break;
      case 'Calendar Selection - Future':
      case 'Calendar Selection - Past':
        isAnswered = answer !== undefined && answer !== null && answer.month && answer.year;
        break;
      default:
        isAnswered = answer !== undefined && answer !== null;
        break;
    }
    return !isRequired || isAnswered;
  });

  const handleNavClick = (e) => {
    if (!allRequiredQuestionsAnswered) {
      e.preventDefault();
      toast.error('Please complete the survey before navigating.');
    }
  };

  if (loading) {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const renderQuestions = () => {
    return (
      <div className="flex flex-col space-y-6">
        {surveyQuestions.map((question, index) => (
          <div key={index} className="my-4">
            <Question
              question={question}
              questionIndex={index}
              selectedAnswers={selectedAnswers}
              handleAnswerChange={handleAnswerChange}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderAlert = () => {
    if (unansweredQuestions.length > 0) {
      return (
        <div
          className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          <svg
            className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Danger</span>
          <div>
            <span className="font-medium">Ensure that these requirements are met:</span>
            <ul className="mt-1.5 list-disc list-inside">
              {unansweredQuestions.map((key, index) => (
                <li key={index}>Question {parseInt(key) + 1} is unanswered</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <NavBar disabled={!allRequiredQuestionsAnswered} onNavClick={handleNavClick} />
      <div className="flex flex-col items-center">
        <Card className="w-full max-w-2xl px-4 bg-white-100 shadow-md relative mt-20"> {/* Added marginTop */}
          <div className="flex flex-col justify-between h-full">
            <div className="flex-grow flex flex-col justify-start pt-0">{renderQuestions()}</div>
            <div className="flex flex-col items-center w-full mt-4 space-y-2">
              {renderAlert()}
              <Button
                className={`text-white ${
                  isSubmitted ? 'bg-blue-700' : isSubmitting ? 'bg-blue-700' : 'bg-blue-700 hover:bg-blue-800'
                }`}
                style={{ outline: 'none', pointerEvents: isSubmitting ? 'none' : 'auto' }}
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <div className="inline-flex items-center">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </div>
                ) : isSubmitted ? (
                  'Submitted'
                ) : (
                  'Submit'
                )}
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <ToastContainer />
    </div>
  );  
};

export default SurveyPage;