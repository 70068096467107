import React, { useState } from 'react';
import './PathwayClassCart.css';

const PathwayClassCart = ({ classesInCart, onRemoveClass, onAddCourses }) => {
  console.log('Classes currently in cart:', classesInCart);

  const handleAddToPathway = () => {
    console.log('Adding the following classes to the pathway:', classesInCart);
    onAddCourses(classesInCart); // Passing the entire cart as intended
  };

  return (
    <div className="class-cart p-3 bg-gray-200 w-full min-h-200 mb-20">
      <h5 className="text-lg text-center font-bold mb-4">Class Cart</h5>
      {classesInCart.length === 0 ? (
        <p className="text-md text-center">Add classes to the cart</p>
      ) : (
        <div>
          {classesInCart.map((classData, index) => (
            <ClassCartItem
              key={index}
              classData={classData}
              onRemoveClass={onRemoveClass}
            />
          ))}
          <div className="flex justify-center mt-4">
            <button className="btn btn-primary" onClick={handleAddToPathway}>
              Add To Pathway
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const ClassCartItem = ({ classData, onRemoveClass }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (value) => {
    setIsHovered(value);
  };

  return (
    <div
      className="class-cart-item bg-white mb-2 rounded p-2 relative"
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <p className="font-bold">{classData.courseInfo_courseNumber}</p>
      {isHovered && (
        <div className="absolute bottom-2 right-3">
          <button
            className="text-xs text-red-500 hover:text-black"
            onClick={() => onRemoveClass(classData)}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default PathwayClassCart;