import React, { useState, useEffect, useContext } from 'react';
import NavBar from '../NavBar';
import CollapsibleDegree from './CollapsibleDegree';
import { useNavigate, BrowserRouter as Route } from 'react-router-dom';
import { useClassContext } from '../ClassContext';
import DegreeCart from './DegreeCart';
import { db, auth } from '../firebase';
import firebase from 'firebase/compat/app';
import { DegreeSearchContext } from './DegreeSearchContext';

const fetchCourseDataFromBackend = async () => {
  const apiUrl = 'http://localhost:4000/degree';

  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
  } catch (error) { 
    console.error('Error fetching data:', error);
    return {};
  }
};

const fetchTaskDataFromBackend = async () => {
  const apiUrl = 'http://localhost:4000/tasks';

  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return {};
  }
};
const fetchElectivesFromBackend = async () => {
  try {
    const response = await fetch('http://localhost:4000/electives');

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();
    return data || []; // Return an empty array if data is falsy
  } catch (error) {
    console.error('Error fetching electives data:', error);
    return []; // Return an empty array in case of an error
  }
};
const DegreeSearch = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [showRegularCourses, setShowRegularCourses] = useState(true);
  const [showRecommendedCourses, setShowRecommendedCourses] = useState(false);
  const [offeredDegrees, setOfferedDegrees] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const fetchedData = await fetchCourseDataFromBackend();
        setData(fetchedData);
        // Extract unique school names from data
        const uniqueSchools = Array.from(new Set(Object.values(fetchedData).map(item => item.sch_name)));
        setSchools(uniqueSchools);
      } catch (error) {
        console.error('Error fetching data:', error);
        setData({});
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [subjectFilter, setSubjectFilter] = useState('');
  const [difficultyFilter, setDifficultyFilter] = useState('');
  const [schoolFilter, setSchoolFilter] = useState('');
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recommendedCourses, setRecommendedCourses] = useState([]);

  const handleResetFilters = () => {
    setSubjectFilter('');
    setDifficultyFilter('');
    console.log(filteredData);
    setShowRegularCourses(true);
    setShowRecommendations(false);
    setShowRecommendedCourses(false);
  };

  const handleShowRecommendations = async () => {
    try {
      const apiUrl = `http://localhost:4000/api/recommended-courses/${currentUser.uid}`;
      const response = await fetch(apiUrl);

      if (response.ok) {
        const recommendedData = await response.json();
        console.log('Recommended Courses data:', recommendedData);
        setRecommendedCourses(recommendedData);
      } else {
        console.error(`Error: ${response.statusText}`);
      }

      setShowRegularCourses(false);
      setShowRecommendations(true);
      setShowRecommendedCourses(true);
    } catch (error) {
      console.error('Error fetching recommended courses:', error);
    }
  };

  const { selectedClasses, setSelectedClasses } = useClassContext();
  const [classesInCart, setClassesInCart] = useState([]);
  const [degreesInCart, setDegreesInCart] = useState([]);

  const navigate = useNavigate();
  const degreeSearchContext = useContext(DegreeSearchContext);
  const { selectedDegreeCoursesFromSearch, setSelectedDegreeCoursesFromSearch } = degreeSearchContext;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchCourseDataFromBackend();
        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setData({});
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const existingClasses = JSON.parse(localStorage.getItem('selectedClasses')) || [];
    setSelectedClasses(existingClasses);
  }, [setSelectedClasses]);

  useEffect(() => {
    const filterData = () => {
      const dataArray = Object.values(data);

      if (Array.isArray(dataArray)) {
        const groupedDegrees = dataArray.reduce((accumulator, item) => {
          const majors = item.Major.split(',').map(major => major.trim());
          majors.forEach(major => {
            const degreeKey = major.toLowerCase();
            accumulator[degreeKey] = item;
          });
          return accumulator;
        }, {});

        const filtered = Object.values(groupedDegrees).filter(
          item =>
            !selectedClasses.some(selected => selected.courseInfo_courseName === item.courseInfo_courseName) &&
            (subjectFilter === '' || item.courseCat.toLowerCase() === subjectFilter.toLowerCase()) &&
            (difficultyFilter === '' || item.courseDif.toLowerCase() === difficultyFilter.toLowerCase()) &&
            (schoolFilter === '' || item.sch_name.toLowerCase() === schoolFilter.toLowerCase()) &&
            (Object.values(item).some(value =>
              typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
            ))
        );
        setFilteredData(filtered);
        console.log('Something: ', filtered);
      } else {
        setFilteredData([]);
      }
    };

    filterData();
  }, [data, subjectFilter, difficultyFilter, schoolFilter, searchQuery, selectedClasses]);

  const subjects = ['Math', 'English', 'Social Studies', 'Science', 'Foreign Language', 'Art', 'MISC'];
  const difficulties = ['REG', 'HON', 'AP', 'CE'];
  const [duplicateClassMessage, setDuplicateClassMessage] = useState('');

  const handleAddClass = (classData) => {
    const isClassAlreadyAdded = selectedClasses.some(selected => selected.courseInfo_courseNumber === classData.courseInfo_courseNumber) || classesInCart.some(cartItem => cartItem.courseInfo_courseNumber === classData.courseInfo_courseNumber);
    if (!isClassAlreadyAdded) {
      setClassesInCart((prevClasses) => [...prevClasses, classData]);
      setDuplicateClassMessage('');
    } else {
      setDuplicateClassMessage(`Class with course number ${classData.courseInfo_courseNumber} is already in your cart or schedule.`);
    }
  };

  const handleRemoveClass = (classToRemove) => {
    const updatedClassesInCart = classesInCart.filter((classData) => classData !== classToRemove);
    setClassesInCart(updatedClassesInCart);
  };

  const handleAddCourses = async (classData) => {
    // Move the class from Class Cart to selectedClasses
    const updatedSelectedClasses = [...selectedClasses, ...classesInCart];
    setSelectedClasses(updatedSelectedClasses);
  
    // Get the user's ID from Firebase Authentication
    const userId = currentUser.uid;
  
    // Check if classData is provided and has the Major property
    if (classData && classData.Major) {
      const majors = classData.Major.split(',').map(major => major.trim());
  
      try {

        console.log('Fetching all tasks from backend API...');
        const allTasksData = await fetchTaskDataFromBackend();
        const allTasks = Object.values(allTasksData);
  
        // Filter tasks with any of the majors matching the selected major
        const tasksToAdd = allTasks
          .filter(task => majors.some(major => task.taskMajor.includes(major)))
          .map(task => task.Task_ID);
  
        console.log('Tasks found with matching majors:', tasksToAdd);

        console.log('Fetching all electives from backend API...');
        const allElectivesData = await fetchElectivesFromBackend();
        const allElectives = Object.values(allElectivesData);
        const selectedMajor = majors[0]; // Assuming you want to use the first major if multiple are selected

              
          console.log('Electives found with matching majors:', allElectives);
  
        console.log('User document updated with electives by major successfully.');
  
        // Redirect to MajorPage with the major name
        navigate(`/major/${majors[0]}`);
      } catch (error) {
        console.error('Error adding courses to finalizedSchedule:', error);
      }
    } else {
      console.error('Invalid class data. Unable to determine major.');
    }
  };
 

  return (
    <div>
      
      <NavBar />
      <div className="mt-20 grid grid-cols-10 gap-3">
        <div className="col-span-2">
          <div className="p-3 bg-gray-200">
            <h5 className="text-lg text-center font-bold mb-4">Filters</h5>
            <div className="mb-2">
              <label htmlFor="searchQuery" className="block text-sm font-medium text-gray-700 mb-1">Search:</label>
              <input
                type="text"
                id="searchQuery"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              />
            </div>
            {/* School filter */}
            <div className="mb-2">
              <label htmlFor="schoolFilter" className="block text-sm font-medium twext-gray-700 mb-1">School:</label>
              <select
                id="schoolFilter"
                value={schoolFilter}
                onChange={(e) => setSchoolFilter(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              >
                <option value="">Select School</option>
                {schools.map((school, index) => (
                  <option key={index} value={school}>{school}</option>
                ))}
              </select>
            </div>
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-1"
              onClick={handleResetFilters}
            >
              Reset Filters
            </button>
          </div>
        </div>
  
        <div className="col-span-6">
          <div className="p-3 bg-gray-200 w-full min-h-[600px]">
            <h5 className="text-lg text-center font-bold mb-4">Degree Maps</h5>
            <div className="h-[550px] overflow-y-auto rounded">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <div>
                  {filteredData.map((item, index) => (
                    <div key={index}>
                      <CollapsibleDegree classData={item} onAddClass={handleAddClass} onAddCourses={handleAddCourses}/>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <DegreeCart
            degreesInCart={classesInCart}
            onRemoveDegree={handleRemoveClass}
            onAddCourses={handleAddCourses}
          />
        </div>
      </div>
      {duplicateClassMessage && <p style={{ color: 'red' }}>{duplicateClassMessage}</p>}
    </div>
  );  
};

export default DegreeSearch;
