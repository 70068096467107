import React, { useState } from 'react';
import './ClassCart.css';

const ClassCart = ({ classesInCart, onRemoveClass, onAddCourses }) => {
  return (
    <div className="class-cart p-3 bg-gray-100 rounded-lg w-full min-h-200 mb-20 shadow-none">
      <h5 className="text-lg text-center font-bold mb-4">Class Cart</h5>
      {classesInCart.length === 0 ? (
        <p className="text-md text-center">Add classes to the cart</p>
      ) : (
        <div className="max-h-96 overflow-y-auto bg-gray-100">
          {classesInCart.map((classData, index) => (
            <ClassCartItem
              key={index}
              classData={classData}
              onRemoveClass={onRemoveClass}
            />
          ))}
        </div>
      )}
      <div className="flex justify-center mt-3">
        <button className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-1" onClick={() => onAddCourses(classesInCart)}>
          Add To Plan
        </button>
      </div>
    </div>
  );
};


const ClassCartItem = ({ classData, onRemoveClass }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (value) => {
    setIsHovered(value);
  };

  return (
    <div
      className="class-cart-item bg-white mb-2 rounded p-2 relative shadow-none"
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <p className="font-bold">{classData.courseInfo_courseNumber}</p>
      {/* Add more details if needed */}
      {isHovered && (
        <div className="absolute bottom-2 right-3">
          <button
            className="text-xs text-red-500 hover:text-black"
            onClick={() => onRemoveClass(classData)}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default ClassCart;
