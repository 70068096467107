import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa'; // Import necessary icons

const SemesterCourses = ({ course, onRemove, onUpdateCourse, isAutoFill }) => {
  const [isCardExpanded, setCardExpanded] = useState(false);

  const handleCardClick = () => {
    setCardExpanded(!isCardExpanded);
  };

  const getBackgroundGradient = (score) => {
    const green = [144, 238, 144];
    const yellow = [255, 255, 224];
    const red = [255, 182, 193];

    let startColor, endColor;
    let percentage;

    if (score >= 50) {
      startColor = yellow;
      endColor = green;
      percentage = (score - 50) / 50;
    } else {
      startColor = red;
      endColor = yellow;
      percentage = score / 50;
    }

    const interpolateColor = (start, end, factor) =>
      start.map((startComponent, index) =>
        Math.round(startComponent + factor * (end[index] - startComponent))
      );

    const [r, g, b] = interpolateColor(startColor, endColor, percentage);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const isPrereqMet = course.isPrereqMet;

  return (
    <div
      className={`flex flex-col p-2 mb-4 border rounded-lg bg-white shadow-sm cursor-pointer hover:bg-gray-100 transition duration-200 ${
        isAutoFill ? 'border-blue-500' : 'border-gray-300'
      } ${isPrereqMet ? '' : 'border-red-500'}`}
      style={{ width: '95%', margin: '0 auto' }}
      onClick={handleCardClick}
    >
      {/* Condensed Course Information */}
      <div className="flex items-center justify-between">
        <span className="font-medium text-m">{course.courseInfo_courseNumber}<span
              style={{ background: getBackgroundGradient(course.recScore) }}
              className="ml-3 px-2 py-1 rounded-md text-xs font-semibold"
            >
              {course.recScore}
           </span></span>

            
        <span
          className="ml-3 px-2 py-1 rounded-full bg-blue-100 text-blue-700 text-xs font-semibold"
          style={{ backgroundColor: 'rgba(30, 144, 255, 0.1)' }} // Light blue with slight transparency
        >
          {course.Credits} Credits
        </span>
      </div>

      {isCardExpanded && (
        <div className="mt-4 transition-max-height duration-300 ease-in-out overflow-hidden">
          <p className="text-left text-sm">
            <strong>Instructor:</strong> {course.Instructor}
          </p>
          <p className="text-left mt-2 text-sm">
            <strong>Elective Fulfillment:</strong> {course.Elective_fulfillment || 'None'}
          </p>
          <p className="text-left text-sm mt-2">
            <strong>Class Notes:</strong> {course.Notes}
          </p>
          <p className="text-left text-sm mt-2">
            <strong>Description:</strong> {course.Description}
          </p>
          {!isPrereqMet && (
            <p className="text-red-600 font-bold mt-2 text-sm">
              Warning: Prerequisites not met.
            </p>
          )}
          <div className="flex justify-center mt-2">
            <button
              className="flex items-center justify-center w-8 h-8 rounded-full bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-300 text-red-600"
              onClick={(e) => {
                e.stopPropagation();
                onRemove(course);
              }}
            >
              <FaTimes className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const SemesterDetailsCourses = ({ finalizedCourses, onRemove, onUpdateCourse }) => {
  return (
    <div className="mt-4">
      {finalizedCourses.map((course, index) => (
        <SemesterCourses
          key={index}
          course={course}
          onRemove={onRemove}
          onUpdateCourse={onUpdateCourse}
          isAutoFill={course.isAutoFill}
        />
      ))}
    </div>
  );
};

export default SemesterDetailsCourses;
