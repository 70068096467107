import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/Routing/App';
import "bootstrap/dist/css/bootstrap.min.css";
import 'flowbite/dist/flowbite.css';
import './index.css';

const APP_VERSION = '1.0.0';

const storedVersion = localStorage.getItem('appVersion');
console.log(`Stored version: ${storedVersion}`);
console.log(`Current app version: ${APP_VERSION}`);

if (storedVersion !== APP_VERSION) {
  console.log('Version mismatch detected. Clearing local storage.');
  localStorage.clear();
  localStorage.setItem('appVersion', APP_VERSION);
  console.log(`Updated app version in local storage to: ${APP_VERSION}`);
} else {
  console.log('On latest version');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
