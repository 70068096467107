import React, { useState, useEffect } from 'react';
import NavBar from '../../NavBar';
import { db, auth } from '../../firebase';

const MyCommitmentsPage = () => {
  const [finalizedVolunteerOpportunities, setFinalizedVolunteerOpportunities] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchFinalizedVolunteerOpportunities = async () => {
      try {
        if (currentUser) {
          const userDocRef = db.collection('users').doc(currentUser.uid);
          const doc = await userDocRef.get();

          if (doc.exists) {
            const userData = doc.data();
            if (userData.finalizedVolunteerOpportunities) {
              setFinalizedVolunteerOpportunities(userData.finalizedVolunteerOpportunities);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching finalized volunteer opportunities:', error);
      }
    };

    fetchFinalizedVolunteerOpportunities();
  }, [currentUser]);

  return (
    <div>
      <NavBar />
      <div style={{ marginTop: '100px', padding: '20px' }}>
        <h2>My Commitments</h2>
        {finalizedVolunteerOpportunities.length === 0 ? (
          <p>No finalized volunteer opportunities added</p>
        ) : (
          <div>
            {finalizedVolunteerOpportunities.map((opportunityName, index) => (
              <div key={index} className="commitment-item" style={{ marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0px 0px 5px #999' }}>
                <p style={{ margin: '0' }}>{opportunityName}</p>
                {/* You can include more details here if needed */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyCommitmentsPage;
