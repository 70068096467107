import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set } from 'firebase/database';
import PathwayClassSearchOverlay from '../PathwayClassSearch/PathwayClassSearchOverlay';
import { useAuth } from '../../../../contexts/AuthContext';
import CoursesSection from '../PathwayFormContent/CoursesSection';
import ElectivesSection from '../PathwayFormContent/ElectivesSection';
import MilestonesSection from '../PathwayFormContent/MilestonesSection';
import RequirementsSection from '../PathwayFormContent/RequirementsSection';

const encodePathwayName = (name) => {
  return name
    .replace(/\./g, '%2E')
    .replace(/#/g, '%23')
    .replace(/\$/g, '%24')
    .replace(/\[/g, '%5B')
    .replace(/\]/g, '%5D');
};

const PathwayFormContent = ({ onClose, onSave, selectedCourses }) => {
  const { currentUser } = useAuth();
  const [pathwayName, setPathwayName] = useState('');
  const [courses, setCourses] = useState([]);
  const [electives, setElectives] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [upperDivisionCredits, setUpperDivisionCredits] = useState(45);
  const [overallCredits, setOverallCredits] = useState(120);
  const [missingFields, setMissingFields] = useState([]);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [showClassSearch, setShowClassSearch] = useState({
    open: false,
    section: '',
    index: null,
    onSelect: null,
  });

  useEffect(() => {
    setCourses(selectedCourses);
  }, [selectedCourses]);

  const handleAddClassToSection = (selectedClasses, section, index = null) => {
    if (!Array.isArray(selectedClasses) || selectedClasses.length === 0) {
      console.error('Invalid or empty selected classes:', selectedClasses);
      return;
    }

    switch (section) {
      case 'electives':
        setElectives([...electives, ...selectedClasses]);
        break;
      case 'courses':
        setCourses([...courses, ...selectedClasses]);
        break;
      case 'milestones':
        setMilestones([...milestones, ...selectedClasses]);
        break;
      case 'requirements':
        setRequirements([...requirements, ...selectedClasses]);
        break;
      default:
        console.error('Unknown section:', section);
    }
  };

  const handleShowClassSearch = (section, index) => {
    setShowClassSearch({
      open: true,
      section,
      index,
      onSelect: (selectedClasses) => {
        handleAddClassToSection(selectedClasses, section, index);
      },
    });
  };

  const handleSavePathway = async () => {
    const fields = [];
    if (!pathwayName) fields.push('Pathway Name');
    if (courses.length === 0) fields.push('At least One Course');

    if (fields.length > 0) {
      setMissingFields(fields);
      return;
    }

    const db = getDatabase();
    const encodedPathwayName = encodePathwayName(pathwayName);
    const pathwayRef = ref(db, `${process.env.REACT_APP_MAJORS_SHEET}/${encodedPathwayName}`);

    try {
      const pathwayData = {
        fullSheetUpdate: true,
      };

      await set(pathwayRef, pathwayData);

      const allCourses = [...courses, ...electives, ...milestones, ...requirements];

      for (const course of allCourses) {
        const courseRef = ref(
          db,
          `/${process.env.REACT_APP_MAJORS_SHEET}/${encodedPathwayName}/${course.courseInfo_courseNumber}`
        );
        await set(courseRef, course);
      }

      setNotificationVisible(true);
      onSave();
    } catch (error) {
      console.error('Error saving pathway:', error);
      setNotificationVisible(true);
    }
  };

  const renderAlert = () => {
    if (missingFields.length === 0) return null;

    return (
      <div
        className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 1 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Danger</span>
        <div>
          <span className="font-medium">Ensure that these requirements are met:</span>
          <ul className="mt-1.5 list-disc list-inside">
            {missingFields.map((field, index) => (
              <li key={index}>{field}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const closeNotification = () => {
    setPathwayName('');
    setCourses([]);
    setNotificationVisible(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-full mx-auto">
      {showClassSearch.open ? (
        <PathwayClassSearchOverlay
          onClose={() => setShowClassSearch({ open: false, onSelect: null, section: '', index: null })}
          onAddClass={(classData) => {
            handleAddClassToSection(classData, showClassSearch.section, showClassSearch.index);
          }}
        />
      ) : (
        <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="col-span-full mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pathway Name</label>
            <input
              type="text"
              value={pathwayName}
              onChange={(e) => setPathwayName(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter pathway name"
            />
          </div>
          <div className="col-span-full mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upper Division Credit Requirement</label>
            <input
              type="number"
              value={upperDivisionCredits}
              onChange={(e) => setUpperDivisionCredits(Number(e.target.value))}
              className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Upper Division Credit Requirement"
            />
          </div>
          <div className="col-span-full mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Overall Credit Requirement</label>
            <input
              type="number"
              value={overallCredits}
              onChange={(e) => setOverallCredits(Number(e.target.value))}
              className="block w-full p-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Overall Credit Requirement"
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow overflow-y-auto max-h-96 dark:bg-gray-800">
            <h3 className="sticky top-0 bg-gray-50 p-2 text-lg font-semibold text-gray-900 dark:text-white">Courses</h3>
            <CoursesSection
              courses={courses}
              setCourses={setCourses}
              setShowClassSearch={(section, index) => handleShowClassSearch(section, index)}
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow overflow-y-auto max-h-96 dark:bg-gray-800">
            <h3 className="sticky top-0 bg-gray-50 p-2 text-lg font-semibold text-gray-900 dark:text-white">Electives</h3>
            <ElectivesSection
              electives={electives}
              setElectives={setElectives}
              setShowClassSearch={(section, index) => handleShowClassSearch(section, index)}
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow overflow-y-auto max-h-96 dark:bg-gray-800">
            <h3 className="sticky top-0 bg-gray-50 p-2 text-lg font-semibold text-gray-900 dark:text-white">Requirements</h3>
            <RequirementsSection
              requirements={requirements}
              setRequirements={setRequirements}
              setShowClassSearch={(section, index) => handleShowClassSearch(section, index)}
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow overflow-y-auto max-h-96 dark:bg-gray-800">
            <h3 className="sticky top-0 bg-gray-50 p-2 text-lg font-semibold text-gray-900 dark:text-white">Milestones</h3>
            <MilestonesSection
              milestones={milestones}
              setMilestones={setMilestones}
              setShowClassSearch={(section, index) => handleShowClassSearch(section, index)}
            />
          </div>
          <button
            type="button"
            onClick={handleSavePathway}
            className="col-span-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition-all ease-in-out duration-200"
          >
            Save Pathway
          </button>
        </form>
      )}
    </div>
  );
};

export default PathwayFormContent;
