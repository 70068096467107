import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import './LandingPage.css';

export default function LandingPage() {
  return (
    <div className="landing-page">
      <NavBar />

      <div className="columns is-multiline is-centered">
        {/* Courses */}
        <div className="column is-one-third mb-4">
          <Link to="/ClassSearch" className="box has-text-centered">
            <p className="title is-4">Courses</p>
            <p className="subtitle is-6">View the available courses at your school</p>
          </Link>
        </div>

        {/* Schedule */}
        <div className="column is-one-third mb-4">
          <Link to="/Credits" className="box has-text-centered">
            <p className="title is-4">Schedule</p>
            <p className="subtitle is-6">View your schedule in a 4-year layout</p>
          </Link>
        </div>

        {/* Forms */}
        <div className="column is-one-third mb-4">
          <Link to="/Forms" className="box has-text-centered">
            <p className="title is-4">Counseling Forms</p>
            <p className="subtitle is-6">Directly request and fill out counseling forms</p>
          </Link>
        </div>

        {/* Extracurricular */}
        <div className="column is-one-third mb-4">
          <Link to="/Extracurricular" className="box has-text-centered">
            <p className="title is-4">Extracurricular</p>
            <p className="subtitle is-6">View and join your extracurricular activities</p>
          </Link>
        </div>

        {/* Volunteer Hours */}
        <div className="column is-one-third mb-4">
          <Link to="/VolunteerValidationPage" className="box has-text-centered">
            <p className="title is-4">Volunteer Hours</p>
            <p className="subtitle is-6">Sign up for and post volunteer opportunites</p>
          </Link>
        </div>

        {/* Opportunities */}
        <div className="column is-one-third mb-4">
          <Link to="/addOpportunity" className="box has-text-centered">
            <p className="title is-4">Add Opportunity</p>
            <p className="subtitle is-6">Add new classes and extracurriculars</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
