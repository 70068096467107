import React, { useState, useEffect } from 'react';

const PopularPrograms = ({ students }) => {
  const [showAll, setShowAll] = useState(false);

  const programCounts = students.reduce((acc, student) => {
    const program = student.major || 'Unknown';
    if (!acc[program]) acc[program] = 0;
    acc[program]++;
    return acc;
  }, {});

  const programs = Object.entries(programCounts).map(([name, count]) => ({
    name,
    count,
    color: 'bg-blue-600', // You can customize colors based on your needs
  })).sort((a, b) => b.count - a.count);

  const topPrograms = programs.slice(0, 6);
  const maxCount = programs.length > 0 ? Math.max(...programs.map(program => program.count)) : 1;

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow p-4 md:p-6">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h2 className="text-xl font-bold mb-2">Popular Programs</h2>
        </div>
      </div>
      <div>
        {(showAll ? programs : topPrograms).map((program, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <div className="flex-1">
              <span className="text-sm font-medium text-gray-900">{program.name}</span>
              <div className="w-full h-2 bg-gray-200 rounded-full mt-1">
                <div
                  className={`h-full rounded-full ${program.color}`}
                  style={{ width: `${(program.count / maxCount) * 100}%` }}
                ></div>
              </div>
            </div>
            <div className="ml-2 text-sm font-medium text-gray-900">{program.count}</div>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <button
          onClick={() => setShowAll(!showAll)}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
          {showAll ? 'See Less' : 'See More'}
        </button>
      </div>
    </div>
  );
};

export default PopularPrograms;
