import React, { useEffect, useState } from 'react';

const Instructors = ({ course }) => {
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    if (course) {
      setInstructors(course.instructors);
    }
  }, [course]);

  const maxCount = instructors.length > 0 ? Math.max(...instructors.map(instructor => instructor.count)) : 1;

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h5 className="text-sm font-medium text-gray-500 dark:text-gray-400">INSTRUCTORS</h5>
          <h3 className="text-3xl font-bold text-gray-900 dark:text-white">{instructors.length}</h3>
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Instructors</p>
        </div>
      </div>
      <div>
        {instructors.map((instructor, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <div className="flex-1">
              <span className="text-sm font-medium text-gray-900 dark:text-white">{index + 1}. {instructor.name}</span>
              <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full mt-1">
                <div
                  className={`h-full rounded-full ${instructor.count === maxCount ? 'bg-blue-600' : 'bg-gray-400'}`}
                  style={{ width: `${(instructor.count / maxCount) * 100}%` }}
                ></div>
              </div>
            </div>
            <div className="ml-2 text-sm font-medium text-gray-900 dark:text-white">{instructor.count}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Instructors;
