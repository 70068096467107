import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../firebase';

const MajorPageJoyrideTour = ({ milestones }) => {
  const { currentUser } = useAuth();
  const [showTutorial, setShowTutorial] = useState(false);
  const [disableScrolling, setDisableScrolling] = useState(true);

  useEffect(() => {
    const checkTutorialStatus = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          let hasCompletedTutorial = userDoc.data().hasCompletedTutorial || {};
          if (typeof hasCompletedTutorial !== 'object') {
            hasCompletedTutorial = {};
          }
          if (!hasCompletedTutorial.MajorPage) {
            setShowTutorial(true);
          }
        }
      }
    };

    checkTutorialStatus();
  }, [currentUser]);

  const handleJoyrideCallback = async (data) => {
    const { status } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status) && currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      let hasCompletedTutorial = userDoc.data().hasCompletedTutorial || {};
      if (typeof hasCompletedTutorial !== 'object') {
        hasCompletedTutorial = {};
      }
      hasCompletedTutorial.MajorPage = true;
      await updateDoc(userDocRef, { hasCompletedTutorial });
      setShowTutorial(false);
    }
  };

  const steps = [
    {
      target: '.semester-container',
      content: 'Here you can see all your semester cards with courses for your major.',
    },
    // {
    //   target: '.year-header',
    //   content: 'This heading shows the academic year.',
    // },
    {
      target: '.semester-credits',
      content: 'This section shows the semester and the total credits for the semester.',
    },
    {
      target: '.finalized-courses',
      content: 'These are the finalized courses for the selected semester and year.',
    },
    {
      target: '.majorpage-courses',
      content: 'These are the recommended courses for students in this major. You can also view available electives.',
    },
    {
      target: '#add-minor-dropdown',
      content: 'Use this dropdown to add a minor.',
    },
    {
      target: '#add-major-button',
      content: 'Click this button to add a second major.',
    },
    {
      target: '.form-select-btns',
      content: 'You can change the semester and year here to adjust where you want to keep a course.',
    },
    {
      target: '.keep-course-button',
      content: 'Click this button to keep the selected course in the chosen semester and year.',
    },
    // Conditionally include the milestone step
    ...(milestones.length > 0 ? [{
      target: '.milestones-container-fall',
      content: 'These are the recommended milestones for students in this major.',
    }] : [])
  ];

  return (
    <Joyride
      steps={steps}
      continuous
      scrollToFirstStep={false}
      showSkipButton
      disableScrolling={true}
      run={showTutorial}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          beaconSize: 36,
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          primaryColor: '#6497d6',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          textColor: '#333',
          width: undefined,
          zIndex: 100,
        },
      }}
    />
  );
};

export default MajorPageJoyrideTour;
