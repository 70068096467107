import React from 'react';
import { useNavigate } from 'react-router-dom';
import CollapsibleClassSearchEditPage from './CollapsibleClassSearchEditPage';

const CourseSearch = ({ loading, filteredData, handleAddClass }) => {
  const navigate = useNavigate();

  const handleAddCourse = () => {
    navigate('/add-course'); // assuming the route for AddCourse component is set up as '/add-course'
  };

  return (
    <div className="p-3 bg-gray-200 w-full min-h-[600px]">
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-lg text-center font-bold">Courses</h5>
        <button
          className="text-blue-600 text-sm py-2 px-4 rounded"
          onClick={handleAddCourse}
        >
          Add Course
        </button>
      </div>
      <div className="h-[550px] overflow-y-auto rounded">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {filteredData.map((classData, index) => (
              <CollapsibleClassSearchEditPage
                key={index}
                classData={classData}
                onAddClass={handleAddClass}
                isRec={false}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseSearch;
