import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StudentSearch from './StudentSearch';
import AdSemesterCard from './AdSemCardComp';
import { db } from '../../../firebase';

const AdvisorReviewPage = () => {
  const { studentId } = useParams();
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    const fetchStudentData = async () => {
      const studentDoc = await db.collection('users').doc(studentId).get();
      if (studentDoc.exists) {
        setSelectedStudent({ id: studentDoc.id, ...studentDoc.data() });
      }
    };

    if (studentId) {
      fetchStudentData();
    }
  }, [studentId]);

  return (
    <div>
      <StudentSearch onSelectStudent={setSelectedStudent} />
      {selectedStudent && (
        <div>
          <h2>{selectedStudent.name}'s 4-Year Plan</h2>
          <AdSemesterCard student={selectedStudent} />
        </div>
      )}
    </div>
  );
};

export default AdvisorReviewPage;
