import React, { createContext, useState } from 'react';

export const DegreeSearchContext = createContext();

const DegreeSearchProvider = ({ children }) => {
  const [selectedDegreeCoursesFromSearch, setSelectedDegreeCoursesFromSearch] = useState(null);

  const value = {
    selectedDegreeCoursesFromSearch,
    setSelectedDegreeCoursesFromSearch,
  };

  return (
    <DegreeSearchContext.Provider value={value}>
      {children}
      

    </DegreeSearchContext.Provider>
  );
};

export default DegreeSearchProvider;