import React from 'react';
import './DegreeCart.css'; // Make sure to rename the CSS file accordingly

const DegreeCart = ({ degreesInCart, onRemoveDegree, onAddCourses }) => {
  return (
    <div className="degree-cart p-3 bg-gray-200 w-full min-h-200 mb-20">
      <h5 className="text-lg text-center font-bold mb-4">My Maps</h5>
      {degreesInCart.length === 0 ? (
        <p className="text-md text-center">Add degrees to the cart</p>
      ) : (
        <div>
          {degreesInCart.map((degreeData, index) => (
            <div key={index} className="degree-cart-item bg-white mb-2 rounded p-2">
              <p>Degree Name: {degreeData.Major}</p>
              {/* Add more details if needed */}
              <div className="remove-button-container justify-center flex">
                <button className="btn btn-danger remove-button mt-2" onClick={() => onRemoveDegree(degreeData)}>
                  Remove from Cart
                </button>
              </div>
            </div>
          ))}
          <div className="flex justify-center mt-4">
            <button className="btn btn-primary" onClick={() => onAddCourses(degreesInCart)}>
              Add Degrees
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DegreeCart;
