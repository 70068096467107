// App.js
import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../../contexts/AuthContext';
import { ClassProvider } from '../ClassContext';
import ProtectedRoutes from './ProtectedRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App({ offeredDegrees }) {
  // if (process.env.REACT_APP_TYPE === 'PROD') {
  //   console.log = function () {}; 
  // }
  
  return (
    <AuthProvider>
      <ClassProvider>
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
          <Router>
            <ProtectedRoutes offeredDegrees={offeredDegrees} />
          </Router>
        </Container>
        <ToastContainer 
          position="top-right" 
          autoClose={5000} 
          hideProgressBar={false} 
          newestOnTop={false} 
          closeOnClick 
          rtl={false} 
          pauseOnFocusLoss 
          draggable 
          pauseOnHover 
        />
      </ClassProvider>
    </AuthProvider>
  );
}

export default App;
