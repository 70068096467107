import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import firebase from 'firebase/compat/app';

const AssignRole = () => {
  const { currentUser } = useAuth();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const assignRole = async () => {
    setLoading(true);
    try {
      const setCustomClaims = firebase.functions().httpsCallable('setCustomClaims');
      const result = await setCustomClaims({ email, role, firstName, lastName });
      setMessage(result.data.message);

      // Force token refresh to apply new claims
      await firebase.auth().currentUser.getIdToken(true);
    } catch (error) {
      setMessage(`Error assigning role: ${error.message}`);
    }
    setLoading(false);
  };

  firebase.auth().currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      console.log(idTokenResult.claims);
    })
    .catch((error) => {
      console.error('Error getting custom claims:', error);
    });

  return (
    <div className="assign-role">
      <h2>Assign Role</h2>
      <input
        type="email"
        placeholder="User Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        required
      />
      <select value={role} onChange={(e) => setRole(e.target.value)} required>
        <option value="" disabled>Select Role</option>
        <option value="student">Student</option>
        <option value="advisor">Advisor</option>
        <option value="admin">Admin</option>
        <option value="super_admin">Super Admin</option>
      </select>
      <button onClick={assignRole} disabled={loading}>
        {loading ? 'Assigning...' : 'Assign Role'}
      </button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AssignRole;
