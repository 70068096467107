import React, { useState, useEffect } from 'react';
import './PathwayCollapsibleClassSearch.module.css';

const PathwayCollapsibleClassSearch = ({ classData, onAddClass, inPlan, isRec }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [isInPlan, setIsInPlan] = useState(inPlan);

  useEffect(() => {
    setIsInPlan(inPlan);
  }, [inPlan]);

  const getBackgroundGradient = (score) => {
    const green = [144, 238, 144];
    const yellow = [255, 255, 224];
    const red = [255, 182, 193];
    let startColor, endColor;
    let percentage;

    if (score >= 50) {
      startColor = yellow;
      endColor = green;
      percentage = (score - 50) / 50;
    } else {
      startColor = red;
      endColor = yellow;
      percentage = score / 50;
    }

    const interpolateColor = (start, end, factor) =>
      start.map((startComponent, index) =>
        Math.round(startComponent + factor * (end[index] - startComponent))
      );

    const [r, g, b] = interpolateColor(startColor, endColor, percentage);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const toggleCollapse = () => setCollapsed(!collapsed);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    if (!isInPlan) {
      onAddClass(classData);
    }
  };

  return (
    <div className="border bg-white rounded-lg p-2 mb-2">
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleCollapse}>
        <div>
          <p className="font-bold">Course ID: {classData.courseInfo_courseNumber}</p>
          <p>
            Credits: {classData.Credits} {isRec ? (
              <span
                style={{ background: getBackgroundGradient(classData.recScore) }}
                className="p-1 mb-2 rounded-lg"
              >
                Rec Score {classData.recScore}
              </span>
            ) : ''}
          </p>
        </div>
        <p className="text-center flex-1">{classData.courseInfo_courseName}</p>
        <div className="flex items-center">
          <button
            onClick={handleButtonClick}
            className={`${
              isInPlan
                ? 'text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-500 dark:focus:ring-green-800'
                : 'text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800'
            } font-medium rounded-lg text-sm px-2 py-2 text-center flex items-center mr-2`}
          >
            {isInPlan && (
              <svg
                className="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                <path
                  fillRule="evenodd"
                  d="M16.293 8.293a1 1 0 00-1.414 0L10 13.172l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l6-6a1 1 0 000-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {isInPlan ? 'In Plan' : 'Add to Cart'}
          </button>
          {collapsed ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          )}
        </div>
      </div>
      {!collapsed && (
        <div className="mt-3">
          <div className="d-flex justify-content-between">
            <p className="mb-2"><strong>Instructor:</strong> {classData.Instructor}</p>
            <p className="mb-2 text-right"><strong>Typically Offered:</strong> {classData.Offering}</p>
            <p className="mb-2 text-center"><strong>Mode:</strong> {classData.Mode}</p>
          </div>
          <p className="mb-2"><strong>Description:</strong> {classData.Description}</p>
        </div>
      )}
    </div>
  );
};

export default PathwayCollapsibleClassSearch;
