// ElectivesSection.js

import React from 'react';

const ElectivesSection = ({ electives, setElectives, setShowClassSearch }) => {
  const handleRemoveElective = (index) => {
    const updatedElectives = electives.filter((_, idx) => idx !== index);
    setElectives(updatedElectives);
  };

  const handleSelectElective = (index) => {
    setShowClassSearch('electives', index);
  };

  const handleAddElective = () => {
    setElectives([
      ...electives,
      {
        courseInfo_courseNumber: '',
        courseYear: '',
        CourseSem: '',
        Description: '',
        Requirements: '',
        Possibilities: '',
        courseType: 'Elective',
      },
    ]);
  };

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Electives</h3>
      {electives.length === 0 ? (
        <p>No electives currently included</p>
      ) : (
        <ul>
          {electives.map((elective, index) => (
            <li key={index} className="mt-2">
              <div className="flex items-center justify-between">
                <span>{elective.courseInfo_courseNumber || 'No elective selected'}</span>
                <button
                  type="button"
                  onClick={() => handleSelectElective(index)}
                  className="bg-blue-600 text-white px-2 py-1 rounded"
                >
                  Select Elective
                </button>
                <button
                  type="button"
                  onClick={() => handleRemoveElective(index)}
                  className="bg-red-600 text-white px-2 py-1 rounded ml-2"
                >
                  Remove
                </button>
              </div>
              <div className="flex mt-2">
                <select
                  value={elective.courseYear}
                  onChange={(e) => {
                    const updatedElectives = [...electives];
                    updatedElectives[index].courseYear = e.target.value;
                    setElectives(updatedElectives);
                  }}
                  className="w-1/2 p-2 border border-gray-300 rounded"
                >
                  <option value="" disabled>
                    Select Course Year
                  </option>
                  {[1, 2, 3, 4].map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <select
                  value={elective.CourseSem}
                  onChange={(e) => {
                    const updatedElectives = [...electives];
                    updatedElectives[index].CourseSem = e.target.value;
                    setElectives(updatedElectives);
                  }}
                  className="w-1/2 p-2 border border-gray-300 rounded ml-2"
                >
                  <option value="" disabled>
                    Select Semester
                  </option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                </select>
              </div>
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={handleAddElective}
        className="w-full mb-4 mt-2 bg-blue-600 text-white p-2 rounded"
      >
        Add Elective
      </button>
    </div>
  );
};

export default ElectivesSection;
