import React, { useState, useEffect } from 'react';
import { TextInput } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import { getDatabase, ref, get, child } from 'firebase/database';

const normalizeKeys = (data) => {
  return data.map(item => {
    return {
      ...item,
      DegreeType: item['Degree Type'] || '',
      GPARequirement: item['GPA Requirement'] || '',
      OfficeLocation: item['Office Location'] || '',
      DegreeLevel: item['Degree Level'] || '',
      // add other normalizations if needed
    };
  });
};

const fetchCUBPathways = async () => {
  const db = getDatabase();
  const cubPathwaysRef = ref(db, '1U2CarXeOMX2zCAUFSDnO1ndxuE3tPDYfY3EOOqH7s_M/CUB_Pathways');
  try {
    const snapshot = await get(cubPathwaysRef);
    const data = snapshot.val();
    return normalizeKeys(Object.values(data)); // Normalize keys after converting to an array
  } catch (error) {
    console.error('Error fetching CUB Pathways data:', error);
    return [];
  }
};

const fetchSFUPathways = async () => {
  const db = getDatabase();
  const sfuPathwaysRef = ref(db, '16zznokkTgjsqu1rYYo5rD8dES40-9dq1guLOAYA0cqI/SFU_Pathways');
  try {
    const snapshot = await get(sfuPathwaysRef);
    const data = snapshot.val();
    return normalizeKeys(Object.values(data)); // Normalize keys after converting to an array
  } catch (error) {
    console.error('Error fetching SFU Pathways data:', error);
    return [];
  }
};

const PathwayList = () => {
  const [pathways, setPathways] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterDepartment, setFilterDepartment] = useState('');
  const [filterDegreeType, setFilterDegreeType] = useState('');
  const [filterGPARange, setFilterGPARange] = useState('');
  const [filterCreditsRange, setFilterCreditsRange] = useState('');
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [degreeTypes, setDegreeTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Switch between fetchCUBPathways and fetchSFUPathways by commenting/uncommenting
        // const fetchedData = await fetchCUBPathways();
        const fetchedData = await fetchSFUPathways();
        console.log("Fetched Data:", fetchedData);

        setPathways(fetchedData);

        // Extract unique departments and degree types
        const uniqueDepartments = [...new Set(fetchedData.map(item => item.Department))];
        const uniqueDegreeTypes = [...new Set(fetchedData.map(item => item.DegreeType))];
        setDepartments(uniqueDepartments);
        setDegreeTypes(uniqueDegreeTypes);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterDepartment = (event) => {
    setFilterDepartment(event.target.value);
  };

  const handleFilterDegreeType = (event) => {
    setFilterDegreeType(event.target.value);
  };

  const handleFilterGPARange = (event) => {
    setFilterGPARange(event.target.value);
  };

  const handleFilterCreditsRange = (event) => {
    setFilterCreditsRange(event.target.value);
  };

  const parseRange = (range) => {
    const [min, max] = range.split('-').map(Number);
    return { min, max };
  };

  const filteredPathways = pathways.filter(pathway => {
    const matchesSearch = pathway?.Major?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesDepartment = filterDepartment ? pathway?.Department?.toLowerCase() === filterDepartment.toLowerCase() : true;
    const matchesDegreeType = filterDegreeType ? pathway?.DegreeType?.toLowerCase() === filterDegreeType.toLowerCase() : true;

    let matchesGPARequirement = true;
    if (filterGPARange) {
      const { min, max } = parseRange(filterGPARange);
      const gpa = parseFloat(pathway?.GPARequirement);
      matchesGPARequirement = gpa >= min && gpa <= max;
    }

    let matchesCreditsRequired = true;
    if (filterCreditsRange) {
      const { min, max } = parseRange(filterCreditsRange);
      const credits = parseInt(pathway?.CreditsRequired, 10);
      matchesCreditsRequired = credits >= min && credits <= max;
    }

    return matchesSearch && matchesDepartment && matchesDegreeType && matchesGPARequirement && matchesCreditsRequired;
  });

  return (
    <div className="flex-1 p-6 mx-auto">
      <div className="w-full max-w-4xl bg-white p-4 shadow-md flex flex-col space-y-4 z-20 sticky top-0">
        <div className="relative w-full">
          <TextInput
            id="search-pathway"
            type="text"
            placeholder="Search pathways..."
            required={true}
            icon={HiSearch}
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <div className="search-filter-container flex gap-4 w-full">
          <select value={filterDepartment} onChange={handleFilterDepartment} className="p-2 border border-gray-300 rounded w-full md:w-1/5 min-w-[170px]">
            <option value="">All Departments</option>
            {departments.map(department => (
              <option key={department} value={department}>{department}</option>
            ))}
          </select>
          <select value={filterDegreeType} onChange={handleFilterDegreeType} className="p-2 border border-gray-300 rounded w-full md:w-1/5 min-w-[200px]">
            <option value="">All Degree Types</option>
            {degreeTypes.map(degreeType => (
              <option key={degreeType} value={degreeType}>{degreeType}</option>
            ))}
          </select>
          <select value={filterGPARange} onChange={handleFilterGPARange} className="p-2 border border-gray-300 rounded w-full md:w-1/5 min-w-[200px]">
            <option value="">All GPA Requirements</option>
            <option value="2.5-3.0">2.5 - 3.0</option>
            <option value="3.0-3.5">3.0 - 3.5</option>
            <option value="3.5-4.0">3.5 - 4.0</option>
          </select>
          <select value={filterCreditsRange} onChange={handleFilterCreditsRange} className="p-2 border border-gray-300 rounded w-full md:w-1/5 min-w-[200px]">
            <option value="">All Credits Required</option>
            <option value="30-60">30 - 60</option>
            <option value="60-90">60 - 90</option>
            <option value="90-120">90 - 120</option>
          </select>
        </div>
      </div>
      <div className="pathway-list mt-4">
        {loading ? (
          <p>Loading...</p>
        ) : (
          filteredPathways.map((pathway, index) => (
            <div key={index} className="collapsible-pathway mb-2 p-4 border border-gray-300 rounded shadow-md">
              <div className="flex justify-between items-center cursor-pointer">
                <h3 className="font-semibold text-lg">{pathway?.Major}</h3>
              </div>
              <div className="pathway-details mt-2">
                <p><strong>GPA Requirement:</strong> {pathway?.GPARequirement ? pathway.GPARequirement : 'N/A'}</p>
                <p><strong>Office Location:</strong> {pathway?.OfficeLocation ? pathway.OfficeLocation : 'N/A'}</p>
                <p><strong>School:</strong> {pathway?.School ? pathway.School : 'N/A'}</p>
                <p><strong>Degree Level:</strong> {pathway?.DegreeLevel ? pathway.DegreeLevel : 'N/A'}</p>
                <ul className="course-list mt-2">
                  {/* Add any additional list items here */}
                </ul>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PathwayList;
