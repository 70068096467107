// AuditAnalysisTable.js
import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

const AuditAnalysisTable = ({ data }) => {
  if (!data || data.length === 0) return <div className="text-center p-4">No data available.</div>;

  return (
    <div className="bg-white shadow-md rounded p-4 h-full">
      <div className="flex justify-center items-center mb-2">
        <h2 className="text-sm font-medium uppercase tracking-wide">Audit Analysis</h2>
        <InformationCircleIcon className="w-4 h-4 ml-1 text-gray-500" />
      </div>
      <table className="min-w-full text-left text-sm font-light">
        <thead className="border-b bg-gray-100">
          <tr>
            <th scope="col" className="px-2 py-2">Requirement</th>
            <th scope="col" className="px-2 py-2">Summer 2021 or earlier</th>
            <th scope="col" className="px-2 py-2">Fall 2021</th>
            <th scope="col" className="px-2 py-2">Spring 2022</th>
            <th scope="col" className="px-2 py-2">Summer 2022</th>
            <th scope="col" className="px-2 py-2">Fall 2022</th>
            <th scope="col" className="px-2 py-2">Planned (Later)</th>
            <th scope="col" className="px-2 py-2">Remaining</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              <td className="px-2 py-2">{row.requirementName}</td>
              <td className="px-2 py-2">{row.takenInSummer2021OrEarlier}</td>
              <td className="px-2 py-2">{row.fall2021}</td>
              <td className="px-2 py-2">{row.spring2022}</td>
              <td className="px-2 py-2">{row.summer2022}</td>
              <td className="px-2 py-2">{row.fall2022}</td>
              <td className="px-2 py-2">{row.plannedLater}</td>
              <td className="px-2 py-2">{row.remaining}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AuditAnalysisTable;
