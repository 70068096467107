import React, { useState } from 'react';
import { Sidebar } from 'flowbite-react';
import { HiChartPie } from 'react-icons/hi';
import { PiStudent } from 'react-icons/pi';
import { IoIosPeople } from 'react-icons/io';
import { BsGraphUp } from 'react-icons/bs';
import { CiMap, CiEdit } from 'react-icons/ci';
import { FiBookOpen } from 'react-icons/fi';
import { BsChatDots, BsPersonCircle } from 'react-icons/bs';
import { FaUserGroup } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import ProfileDrawer from './ProfileDrawer';
import '../styles.css';

export default function CustomSidebar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <Sidebar aria-label="Sidebar with logo branding example" className="sidebar fixed top-0 left-0 h-full z-50">
        <Sidebar.Logo href="/Profile" img={`${process.env.PUBLIC_URL}/edviseicon.png`} imgAlt="Edvise logo" className="custom-logo">
          Edvise
        </Sidebar.Logo>
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            <Sidebar.Item as={Link} to="/AdminDashboard" icon={HiChartPie}>
              Dashboard
            </Sidebar.Item>
            <Sidebar.Item as={Link} to="/AdminDashboard/students" icon={PiStudent}>
              Students
            </Sidebar.Item>
            <Sidebar.Item as={Link} to="/AdminDashboard/pathways" icon={CiMap}>
              Pathways
            </Sidebar.Item>
            <Sidebar.Item as={Link} to="/AdminDashboard/analytics" icon={BsGraphUp}>
              Analytics
            </Sidebar.Item>
            <Sidebar.Item as={Link} to="/AdminDashboard/messages" icon={BsChatDots}>
              Messages
            </Sidebar.Item>
            <Sidebar.Item as={Link} to="/AdminDashboard/EditCourseData" icon={CiEdit}>
              Edit Courses
            </Sidebar.Item>
            <Sidebar.Item as={Link} to="/AdminDashboard/staff" icon={FaUserGroup}>
              Staff
            </Sidebar.Item>
            <Sidebar.Item icon={BsPersonCircle} onClick={toggleDrawer}> {/* Add Profile button */}
              Profile
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
      <ProfileDrawer isOpen={isDrawerOpen} onClose={toggleDrawer} /> {/* Include ProfileDrawer */}
    </>
  );
}
