import React from 'react';

const GPABreakdown = ({ students }) => {
  const gpaRanges = ['4.0', '3.5 - 3.9', '3.0 - 3.4', '2.5 - 2.9', '2.0 - 2.4', '< 2.0'];
  const gpaData = gpaRanges.map(range => ({
    range,
    count: students.filter(student => {
      const gpa = parseFloat(student.gpa);
      if (range === '4.0') return gpa === 4.0;
      if (range === '< 2.0') return gpa < 2.0;
      const [min, max] = range.split(' - ').map(parseFloat);
      return gpa >= min && gpa <= max;
    }).length,
  }));

  const maxCount = gpaData.length > 0 ? Math.max(...gpaData.map(gpa => gpa.count)) : 1;

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow p-4 md:p-6">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h2 className="text-xl font-bold mb-2">GPA Breakdown</h2>
        </div>
      </div>
      <div>
        {gpaData.map((gpa, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <div className="flex-1">
              <span className="text-sm font-medium text-gray-900">{gpa.range}</span>
              <div className="w-full h-2 bg-gray-200 rounded-full mt-1">
                <div
                  className="h-full rounded-full bg-green-600"
                  style={{ width: `${(gpa.count / maxCount) * 100}%` }}
                ></div>
              </div>
            </div>
            <div className="ml-2 text-sm font-medium text-gray-900">{gpa.count}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GPABreakdown;
