import React, { useState, useEffect } from 'react';
import './FinalizedCourses.css';

const fetchDataFromBackend = async () => {
  const apiUrl = 'http://localhost:4000/prerequisites';

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      console.error(`Error fetching data. Status: ${response.status}`);
      throw new Error(`Error fetching data. Status: ${response.status}`);
    }

    const data = await response.json();
    return data || {};
  } catch (error) {
    console.error('Network error:', error);
    return {};
  }
};

const FinalizedCourse = ({ course, onRemove, onKeepCourse, isPrereqMet }) => {
  const [isCardExpanded, setCardExpanded] = useState(false);

  const handleCardClick = () => {
    setCardExpanded(!isCardExpanded);
  };

  return (
    <div
      className={`course-card ${isCardExpanded ? 'expanded-card' : 'collapsed-card'} ${
        isPrereqMet ? '' : 'unmet-prereq'
      }`}
      onClick={handleCardClick}
    >
      <p>{course.courseInfo_courseName}</p>
      <div className={isCardExpanded ? 'expanded-details' : 'collapsed-details'}>
        <p>Teacher: {course.sectionInfo_teacherDisplay}</p>
        <p>Credits: {course.Credits}</p>
        {!isPrereqMet && (
          <p style={{ color: 'black', fontWeight: 'bold' }}>Warning: Prerequisites not met.</p>
        )}
      </div>

      {isCardExpanded && (
        <div className="button-container">
          <button className="btn btn-success m-2 modern-button" onClick={() => onKeepCourse(course)}>
            Keep
          </button>

          <button className="btn btn-danger m-2 modern-button" onClick={() => onRemove(course)}>
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

const FinalizedCourses = ({ finalizedCourses, onRemove, prereqClasses, yearNumber, onKeepCourse }) => {
  const [loading, setLoading] = useState(true);
  const [prerequisites, setPrerequisites] = useState([]);

  useEffect(() => {
    const fetchPrerequisites = async () => {
      try {
        const fetchedPrerequisites = await fetchDataFromBackend();
        setPrerequisites(fetchedPrerequisites || []);
      } catch (error) {
        console.error('Error fetching prerequisites:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrerequisites();
  }, []);

  if (loading) {
    return <p>Loading courses...</p>;
  }

  if (!finalizedCourses || finalizedCourses.length === 0) {
    return <p>No finalized courses available</p>;
  }

  const courseCategoriesOrder = [
    'Math',
    'English',
    'Social Studies',
    'Science',
    'Foreign Language',
    'Art',
    'Misc',
  ];

  const sortedCourses = finalizedCourses
    .filter((course) => course && course.courseCat)
    .sort((a, b) => {
      const categoryA = a.courseCat;
      const categoryB = b.courseCat;

      return courseCategoriesOrder.indexOf(categoryA) - courseCategoriesOrder.indexOf(categoryB);
    });

  const updatedCourses = finalizedCourses.map((course) => {
    let prerequisiteMet = false;
    const found = prerequisites.hasOwnProperty(course.courseInfo_courseNumber);

    if (found) {
      const prerequisiteInfo = prerequisites[course.courseInfo_courseNumber];
      const prerequisiteString = String(prerequisiteInfo.Prerequisite);
      const prerequisiteClassIds = prerequisiteString.split(',');
      const prereqYearClasses = Object.values(prereqClasses);

      for (let i = 0; i < prerequisiteClassIds.length; i++) {
        const classId = prerequisiteClassIds[i];
        const convertedPrereqYearClasses = prereqYearClasses[yearNumber - 1].map(String);
        if (convertedPrereqYearClasses.includes(classId)) {
          prerequisiteMet = true;
          break;
        }
      }
    } else {
      prerequisiteMet = true;
    }

    return {
      ...course,
      isPrereqMet: prerequisiteMet,
    };
  });

  return (
    <div style={{ textAlign: 'center', marginTop: 'auto' }}>
      {updatedCourses.map((course, index) => (
        <FinalizedCourse
          key={index}
          course={course}
          onRemove={onRemove}
          isPrereqMet={course.isPrereqMet}
          onKeepCourse={onKeepCourse}
        />
      ))}
    </div>
  );
};

export default FinalizedCourses;