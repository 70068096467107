import React from 'react';

const RelevantStudents = ({ students }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Name</th>
              <th className="py-2 px-4 border-b">Major</th>
              <th className="py-2 px-4 border-b">GPA</th>
              <th className="py-2 px-4 border-b">Graduation Year</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student) => (
              <tr key={student.id}>
                <td className="py-2 px-4 border-b">{student.name}</td>
                <td className="py-2 px-4 border-b">{student.major}</td>
                <td className="py-2 px-4 border-b">{student.gpa}</td>
                <td className="py-2 px-4 border-b">{student.gradYear}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RelevantStudents;
