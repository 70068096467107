import React, { useState } from 'react';
import CoursesAnalytics from './CoursesAnalytics';
import StudentsAnalytics from './StudentsAnalytics';
import AuditAnalytics from './AuditAnalytics';
import CustomSidebar from '../Sidebar/Sidebar';
import '../styles.css'; // Ensure you import your CSS

const Analytics = () => {
  const [activeTab, setActiveTab] = useState('courses');

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-64">
        <div className="fixed top-3.5 right-0 w-full bg-white dark:bg-gray-800 z-10" style={{ width: 'calc(100% - 16rem)' }}>
          <div className="flex justify-center">
            <ul
              className="flex space-x-4 py-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400"
              id="default-tab"
              data-tabs-toggle="#default-tab-content"
              role="tablist"
              style={{ listStyleType: 'none' }}
            >
              <li role="presentation">
                <button
                  className={`inline-block py-4 px-6 whitespace-nowrap ${
                    activeTab === 'courses'
                      ? 'text-blue-600 mx-auto'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="courses-tab"
                  type="button"
                  role="tab"
                  aria-controls="courses"
                  aria-selected={activeTab === 'courses'}
                  onClick={() => setActiveTab('courses')}
                >
                  Courses
                </button>
              </li>
              <li role="presentation">
                <button
                  className={`inline-block py-4 px-6 whitespace-nowrap ${
                    activeTab === 'students'
                      ? 'text-blue-600 mx-auto'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="students-tab"
                  type="button"
                  role="tab"
                  aria-controls="students"
                  aria-selected={activeTab === 'students'}
                  onClick={() => setActiveTab('students')}
                >
                  Students
                </button>
              </li>
              <li role="presentation">
                <button
                  className={`inline-block py-4 px-6 whitespace-nowrap ${
                    activeTab === 'audit'
                      ? 'text-blue-600 mx-auto'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="audit-tab"
                  type="button"
                  role="tab"
                  aria-controls="audit"
                  aria-selected={activeTab === 'audit'}
                  onClick={() => setActiveTab('audit')}
                >
                  Audit
                </button>
              </li>
            </ul>
          </div>
          <div className="border-b border-gray-200 dark:border-gray-700"></div>
        </div>
        <div id="default-tab-content" style={{ marginTop: '64px' }}>
          {activeTab === 'courses' && (
            <div
              className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="courses"
              role="tabpanel"
              aria-labelledby="courses-tab"
            >
              <CoursesAnalytics />
            </div>
          )}
          {activeTab === 'students' && (
            <div
              className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="students"
              role="tabpanel"
              aria-labelledby="students-tab"
            >
              <StudentsAnalytics />
            </div>
          )}
          {activeTab === 'audit' && (
            <div
              className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="audit"
              role="tabpanel"
              aria-labelledby="audit-tab"
            >
              <AuditAnalytics />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
