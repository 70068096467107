// CoursesSection.js
import React from 'react';

const CoursesSection = ({ courses = [], setCourses, setShowClassSearch }) => {
  const handleRemoveClass = (courseToRemove) => {
    const updatedCourses = courses.filter(
      (course) => course.courseInfo_courseNumber !== courseToRemove.courseInfo_courseNumber
    );
    setCourses(updatedCourses);
  };

  const handleSelectCourse = (index) => {
    setShowClassSearch({
      open: true,
      onSelect: (selectedClasses) => {
        const updatedCourses = [...courses];
        const selectedClass = selectedClasses[0];
        updatedCourses[index] = {
          ...updatedCourses[index],
          courseInfo_courseNumber: selectedClass.courseInfo_courseNumber,
        };
        setCourses(updatedCourses);
        setShowClassSearch({ open: false, onSelect: null, section: '', index: null });
      },
      section: 'courses',
    });
  };

  const handleSelectPossibilities = (index) => {
    setShowClassSearch({
      open: true,
      onSelect: (selectedClasses) => {
        const updatedCourses = [...courses];
        const newPossibilities = selectedClasses
          .map((selectedClass) => selectedClass.courseInfo_courseNumber)
          .join(', ');

        updatedCourses[index].Possibilities = updatedCourses[index].Possibilities
          ? `${updatedCourses[index].Possibilities}, ${newPossibilities}`
          : newPossibilities;

        setCourses(updatedCourses);
        setShowClassSearch({ open: false, onSelect: null, section: '', index: null });
      },
      section: 'possibilities',
    });
  };

  const handleAddCourse = () => {
    const newCourse = {
      courseInfo_courseNumber: '',
      courseYear: '',
      CourseSem: '',
      Description: '',
      Possibilities: '',
      Requirements: '',
      courseType: 'Major Course',
    };
    setCourses([...courses, newCourse]);
  };

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Included Courses</h3>
      {courses.length === 0 ? (
        <p>No courses currently included</p>
      ) : (
        <ul>
          {courses.map((course, index) => (
            <li key={index} className="mt-2">
              <div className="flex items-center justify-between">
                <span>{course.courseInfo_courseNumber || 'No course selected'}</span>
                <button
                  type="button"
                  onClick={() => handleSelectCourse(index)}
                  className="bg-blue-600 text-white px-2 py-1 rounded"
                >
                  Select Course
                </button>
                <button
                  type="button"
                  onClick={() => handleSelectPossibilities(index)}
                  className="bg-purple-600 text-white px-2 py-1 rounded ml-2"
                >
                  Select Possibilities
                </button>
                <button
                  type="button"
                  onClick={() => handleRemoveClass(course)}
                  className="bg-red-600 text-white px-2 py-1 rounded ml-2"
                >
                  Remove
                </button>
              </div>
              <select
                value={course.courseYear}
                onChange={(e) => {
                  const updatedCourses = [...courses];
                  updatedCourses[index].courseYear = e.target.value;
                  setCourses(updatedCourses);
                }}
                className="w-full p-2 border border-gray-300 rounded mt-2"
              >
                <option value="" disabled>
                  Select Course Year
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              <select
                value={course.CourseSem}
                onChange={(e) => {
                  const updatedCourses = [...courses];
                  updatedCourses[index].CourseSem = e.target.value;
                  setCourses(updatedCourses);
                }}
                className="w-full p-2 border border-gray-300 rounded mt-2"
              >
                <option value="" disabled>
                  Select Semester
                </option>
                <option value="S1">S1</option>
                <option value="S2">S2</option>
              </select>
              {course.Possibilities && (
                <p className="mt-2 text-sm bg-gray-100 p-2 rounded">
                  <strong>Possibilities:</strong> {course.Possibilities}
                </p>
              )}
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={handleAddCourse}
        className="w-full mb-4 bg-blue-600 text-white p-2 rounded"
      >
        Add Course
      </button>
    </div>
  );
};

export default CoursesSection;
