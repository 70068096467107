import React from 'react';
import { Link } from 'react-router-dom';

const StaffGroup = ({ group }) => {
  return (
    <div className="staff-group">
      <div className="group-info">
        <h3>{group.name}</h3>
        <p>{group.members} members</p>
        <p>Last Updated: {group.lastUpdated}</p>
      </div>
      <div className="group-actions">
        <Link to="#" className="group-action">One Advising Block For Group</Link>
        <Link to="#" className="group-action">Milestones</Link>
        <Link to="#" className="group-action">Note Referrals</Link>
        <Link to="#" className="group-action">Notes Visibility</Link>
        <Link to="#" className="group-action">Workflows</Link>
      </div>
    </div>
  );
};

export default StaffGroup;
