import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CollapsibleClassSearchEditPage = ({ classData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent the click from toggling collapse
    navigate(`/AdminDashboard/EditCourseData/${classData.courseInfo_courseNumber}`, { state: { course: classData } });
  };

  return (
    <div className="border bg-white rounded-lg p-2 mb-2">
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleCollapse}>
        <div>
          <p className="font-bold">Course ID: {classData.courseInfo_courseNumber}</p>
          <p>Credits: {classData.Credits}</p>
        </div>
        <p className="text-center flex-1">{classData.courseInfo_courseName}</p>
        <div className="flex items-center">
          <button
            className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-2 py-2 text-center flex items-center mr-2"
            onClick={handleEditClick}
          >
            Edit
          </button>
          {collapsed ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          )}
        </div>
      </div>
      {!collapsed && (
        <div className="mt-3">
          <p className="mb-2"><strong>Instructor:</strong> {classData.Instructor}</p>
          <p className="mb-2"><strong>Description:</strong> {classData.Description}</p>
          <p className="mb-2"><strong>Department(s):</strong> {classData.Major}</p>
          <p className="mb-2"><strong>Mode:</strong> {classData.Mode}</p>
          <p><strong>Typically Offered:</strong> {classData.Offering}</p>
        </div>
      )}
    </div>
  );
};

export default CollapsibleClassSearchEditPage;
