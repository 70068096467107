import React from 'react';
import './VolunteerCart.css';

const VolunteerCart = ({ Volunteers, onRemoveVolunteer }) => {
  return (
    <div>
      {Volunteers.length === 0 ? (
        <p>Add classes to the cart</p>
      ) : (
        <div>
          {Object.entries(Volunteers).map((VolunteerName, Extracurricular) => (
            <div key={VolunteerName} className="class-cart-item">
              <p style={{ fontSize: '1.1em' }}>{VolunteerName[1]}</p>
              {/* Add more details if needed */}
              <div className="remove-button-container">
                <button className="btn btn-danger remove-button" onClick={() => onRemoveVolunteer(VolunteerName[1])}>
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VolunteerCart;
