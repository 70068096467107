// Pathways.js

import React, { useState } from 'react';
import PathwayFormContent from './PathwayFormContent/PathwayFormContent';
import PathwayList from './PathwayList';
import CustomSidebar from '../Sidebar/Sidebar';

const Pathways = () => {
  const [activeTab, setActiveTab] = useState('list');

  const handleSavePathway = () => {
    setActiveTab('list'); // Switch to the list tab after saving
  };

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-64">
        <div
          className="fixed top-3.5 right-0 w-full bg-white dark:bg-gray-800 z-10"
          style={{ width: 'calc(100% - 16rem)' }}
        >
          <div className="flex justify-center">
            <ul
              className="flex space-x-4 py-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400"
              id="default-tab"
              data-tabs-toggle="#default-tab-content"
              role="tablist"
              style={{ listStyleType: 'none' }}
            >
              <li role="presentation">
                <button
                  className={`inline-block py-4 px-6 whitespace-nowrap ${
                    activeTab === 'list'
                      ? 'text-blue-600 mx-auto'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="list-tab"
                  type="button"
                  role="tab"
                  aria-controls="list"
                  aria-selected={activeTab === 'list'}
                  onClick={() => setActiveTab('list')}
                >
                  Pathway List
                </button>
              </li>
              <li role="presentation">
                <button
                  className={`inline-block py-4 px-6 whitespace-nowrap ${
                    activeTab === 'form'
                      ? 'text-blue-600 mx-auto'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  id="form-tab"
                  type="button"
                  role="tab"
                  aria-controls="form"
                  aria-selected={activeTab === 'form'}
                  onClick={() => setActiveTab('form')}
                >
                  Create New Pathway
                </button>
              </li>
            </ul>
          </div>
          <div className="border-b border-gray-200 dark:border-gray-700"></div>
        </div>
        <div id="default-tab-content" style={{ marginTop: '80px' }}>
          {activeTab === 'list' ? (
            <PathwayList />
          ) : (
            <PathwayFormContent
              onClose={() => setActiveTab('list')}
              onSave={handleSavePathway}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Pathways;
