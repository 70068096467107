import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../FinalizedCourses.css';
import PossibilitiesModal from './PossibilitiesModal';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import ElectiveList from './ElectiveList';
import { FaCheck, FaCheckDouble } from 'react-icons/fa';

const FinalizedCourse = ({
  course,
  onRemove,
  onKeepCourse,
  isPrereqMet,
  onCheckboxChange,
  semester,
  year,
  onPossibilityClick,
  selectedPossibility,
  selectedCourses,
  onShowPossibilitiesClick,
  onShowPossibilitiesClick1,
  onElectiveClick,
  isSelected // Receive isSelected as a prop
}) => {
  const [isCardExpanded, setCardExpanded] = useState(false);
  const [showPossibilitiesButton, setShowPossibilitiesButton] = useState(false);

  const handleCardClick = () => {
    if (!course.isKept) {
      onCheckboxChange(course, !isSelected);
    }
  };

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setCardExpanded(!isCardExpanded);
    setShowPossibilitiesButton(!isCardExpanded);
    console.log('Kept or not: ', course.isKept);
  };

  const hasPossibilities = course.Possibilities && course.Possibilities.trim().length > 0;

  return (
    <>
      {course.courseType !== 'Milestone' && (
        <div
          className={`flex justify-between card ${isSelected ? '!border-black' : ''} ${
            isPrereqMet ? '' : 'bg-orange-200'
          } ${isCardExpanded ? 'expanded' : ''} ${course.isKept ? 'bg-blue-100' : ''}`}
          onClick={
            course.courseType === 'Elective'
              ? () => onElectiveClick(course.Possibilities, year, semester, course.Requirements)
              : !course.courseInfo_courseName
              ? () => onShowPossibilitiesClick(course)
              : handleCardClick
          }
        >
          {course.courseType === 'Elective' ? (
            <div className="flex items-center justify-between p-2">
              <div className='w-1/5'>
                <div className="course-number mr-4">
                  <strong>Elective</strong>
                </div>
                {course.minorName && (
                  <div className="course-degree w-full text-left truncate">
                    <span className='text-yellow-500 text-xs bg-yellow-100 bg-opacity-50 rounded px-2 py-1 font-semibold'>
                      {course.minorName}
                    </span>
                  </div>
                )}
                {course.degreeName && (
                  <div className="course-number mr-4">
                    <span className='!text-green-500 text-xs !bg-green-100 bg-opacity-50 rounded px-2 py-1 font-semibold'>
                      {course.degreeName}
                    </span>
                  </div>
                )}
              </div>
              <div className="course-name flex-1 w-1/5 text-left pl-4 text-sm truncate">
                <span>{course.Requirements}</span>
              </div>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between p-2">
                {course.courseInfo_courseName ? (
                  <>
                    <div className='w-1/5'>
                      <div className="course-number mr-4">
                        <strong>{course.courseInfo_courseNumber}</strong>
                      </div>
                      {course.minorName && (
                        <div className="course-degree w-full text-left truncate">
                          <span className="text-yellow-500 text-xs bg-yellow-100 bg-opacity-50 rounded px-2 py-1 font-semibold">
                            {course.minorName}
                          </span>
                        </div>
                      )}
                      {course.degreeName && (
                        <div className="course-number mr-4">
                          <span className='!text-green-500 text-xs !bg-green-100 bg-opacity-50 rounded px-2 py-1 font-semibold'>
                            {course.degreeName}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={`course-name flex-1 w-1/5 text-left pl-4 text-sm font truncate ${isCardExpanded ? 'expanded' : ''}`}>
                      <span>{course.courseInfo_courseName}</span>
                    </div>
                    <div className="course-credits text-left">
                      <strong>{course.Credits}</strong>
                    </div>
                    <div
                      className={`ml-4 cursor-pointer ${course.isKept ? 'text-gray-500' : ''}`}
                      onClick={handleExpandClick}
                    >
                      {isCardExpanded ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 15.75l7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="course-number mr-4 w-full">
                        <strong>Requirement</strong>
                      </div>
                      {course.minorName && (
                        <div className="course-degree w-full text-left truncate">
                          <span className='text-yellow-500 text-xs bg-yellow-100 bg-opacity-50 rounded px-2 py-1 font-semibold'>
                            {course.minorName}
                          </span>
                        </div>
                      )}
                      {course.degreeName && (
                        <div className="course-number mr-4">
                          <span className='!text-green-500 text-xs !bg-green-100 bg-opacity-50 rounded px-2 py-1 font-semibold'>
                            {course.degreeName}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={`course-name flex-1 w-1/5 text-left pl-4 text-sm font truncate`}>
                      <span>{course.Requirements}</span>
                    </div>
                  </>
                )}
              </div>
              <div className={`expandable-content ${isCardExpanded ? 'expanded' : ''}`}>
                <div className="p-4 text-sm">
                  <p className="flex justify-start mb-2">Teacher: {course.Instructor}</p>
                  <p className="flex justify-start mb-2 text-left">Description: {course.Description}</p>
                  {!isPrereqMet && <p className="font-bold">Warning: Prerequisites not met.</p>}
                  {(isCardExpanded && hasPossibilities && showPossibilitiesButton) && course.courseInfo_courseNumber !== 'FNCE 3030' && (
                    <div className="mb-2">
                      <button
                        className="justify-start text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:scale-105 show-possibilities-button" // Added class for Joyride target
                        onClick={() => onShowPossibilitiesClick(course)}
                      >
                        Show Possibilities
                      </button>
                    </div>
                  )}
                  {isCardExpanded && hasPossibilities && !showPossibilitiesButton && (
                    <div>
                      <strong>Possibilities:</strong>
                      <ul className="list-disc pl-4">
                        {course.Possibilities.split(',').map((possibility, index) => (
                          <li key={index}>{possibility}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {course.courseInfo_courseNumber === 'FNCE 3030' && (
                    <div className="mb-2">
                      <button
                        className="text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:scale-105 show-possibilities-button" // Added class for Joyride target
                        onClick={() => onShowPossibilitiesClick1(course)}
                      >
                        Show Possibilities
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const FinalizedCoursesTwo = ({
  finalizedCourses,
  onRemove,
  prereqClasses,
  yearNumber,
  onCourseSelection, 
  onKeepCourse,
  onKeepCourses,
  semester,
  electiveData,
  onElectiveClick,
  tabs,
  activeTab,
  selectedMajor1,
  selectedElectiveCategory,
  majorRecData, 
  finalizedCourses1
}) => {
  const [loading, setLoading] = useState(true);
  const [prerequisites, setPrerequisites] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]); // Array of course IDs
  const [selectedSemester, setSelectedSemester] = useState(semester);
  const [selectedYear, setSelectedYear] = useState(yearNumber);
  const [finalizedSchedule, setFinalizedSchedule] = useState({});
  const [selectedPossibility, setSelectedPossibility] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedPrerequisites = prereqClasses;
        setPrerequisites(fetchedPrerequisites || []);

        const db = getFirestore();
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const finalizedSchedule = docSnap.data().finalizedSchedule;
          setFinalizedSchedule(finalizedSchedule || {});
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser.uid, prereqClasses]);

  const handleCheckboxChange = (course, isChecked) => {
    if (isChecked) {
      setSelectedCourses([...selectedCourses, course.courseInfo_courseNumber]);
    } else {
      setSelectedCourses(selectedCourses.filter(id => id !== course.courseInfo_courseNumber));
    }
  };

  const handleKeepCourseClick = (sel, yr, sem, keepAll = false) => {
    let coursesToKeep;

    if (keepAll) {
      // When keeping all, filter out Elective, Requirement, and Milestone courses
      coursesToKeep = finalizedCourses.filter(
        (course) =>
          course.courseType !== 'Elective' &&
          course.courseType !== 'Requirement' &&
          course.courseType !== 'Milestone'
      );
    } else {
      // Map selected course IDs back to course objects
      coursesToKeep = finalizedCourses.filter((course) =>
        sel.includes(course.courseInfo_courseNumber)
      );
    }

    if (coursesToKeep.length > 0) {
      onKeepCourse(coursesToKeep, yr, sem);
    }

    // Clear selectedCourses after keeping
    setSelectedCourses([]);
  };

  const handleShowPossibilitiesClick = (course) => {
    setCurrentCourse(course);
    setShowModal(true);
    console.log('Possibilities course: ', course);
  };

  const handleShowPossibilitiesClick1 = (course) => {
    setCurrentCourse(course);
    setShowModal1(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentCourse(null);
  };

  const handlePossibilitySelection = async (course, possibility) => {
    const db = getFirestore();
    const docRef = doc(db, 'users', currentUser.uid);

    const updatedSchedule = { ...finalizedSchedule };
    if (!updatedSchedule[`year${selectedYear}`]) {
      updatedSchedule[`year${selectedYear}`] = {};
    }
    if (!updatedSchedule[`year${selectedYear}`][`S${selectedSemester}`]) {
      updatedSchedule[`year${selectedYear}`][`S${selectedSemester}`] = { courses: [] };
    }

    updatedSchedule[`year${selectedYear}`][`S${selectedSemester}`].courses.push(possibility);

    await setDoc(docRef, { finalizedSchedule: updatedSchedule }, { merge: true });

    setFinalizedSchedule(updatedSchedule);

    const courseWithPossibility = {
      ...course,
      courseInfo_courseNumber: possibility,
      isKept: true,
    };

    onKeepCourse([courseWithPossibility], selectedYear, selectedSemester);
    setSelectedPossibility(null);
    setShowModal(false);
  };

  if (!finalizedCourses || finalizedCourses.length === 0) {
    return <p>No finalized courses available</p>;
  }

  const updatedCourses = finalizedCourses.map((course) => {
    let prerequisiteMet = false;
    const found = prerequisites.hasOwnProperty(course.courseInfo_courseNumber);

    if (found) {
      const prerequisiteInfo = prerequisites[course.courseInfo_courseNumber];
      const prerequisiteString = String(prerequisiteInfo.Prerequisite);
      const prerequisiteClassIds = prerequisiteString.split(',');
      const prereqYearClasses = Object.values(prereqClasses);

      for (let i = 0; i < prerequisiteClassIds.length; i++) {
        const classId = prerequisiteClassIds[i];
        const convertedPrereqYearClasses = prereqYearClasses[yearNumber - 1].map(String);
        if (convertedPrereqYearClasses.includes(classId)) {
          prerequisiteMet = true;
          break;
        }
      }
    } else {
      prerequisiteMet = true;
    }

    return {
      ...course,
      isPrereqMet: prerequisiteMet,
    };
  });

  return (
    <div style={{ textAlign: 'center', marginTop: 'auto', padding: 0 }}>
      <div className="majorpage-courses">
        {updatedCourses.map((course, index) => (
          <FinalizedCourse
            key={index}
            course={course}
            onRemove={onRemove}
            isPrereqMet={course.isPrereqMet}
            onKeepCourse={handleKeepCourseClick}
            onCheckboxChange={handleCheckboxChange}
            selectedPossibility={selectedPossibility}
            finalizedSchedule={finalizedSchedule}
            selectedCourses={selectedCourses}
            onShowPossibilitiesClick={handleShowPossibilitiesClick}
            onShowPossibilitiesClick1={handleShowPossibilitiesClick1}
            year={yearNumber}
            semester={semester}
            isSelected={selectedCourses.includes(course.courseInfo_courseNumber)} // Use ID for selection
            onElectiveClick={onElectiveClick}
          />
        ))}
      </div>
      <div className="flex justify-between items-center mb-4" style={{ padding: 0, marginTop: 15 }}>
        <div className="flex items-center">
          <div className="mr-2">
            <select
              onChange={(e) => setSelectedSemester(parseInt(e.target.value))}
              className="form-select form-select-sm tutorial-dropdown-semester"
              value={selectedSemester}
            >
              <option value={1}>Fall</option>
              <option value={2}>Spring</option>
            </select>
          </div>
          <div>
            <select
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              className="form-select form-select-sm tutorial-dropdown-year"
              value={selectedYear}
            >
              <option value={1}>Year 1</option>
              <option value={2}>Year 2</option>
              <option value={3}>Year 3</option>
              <option value={4}>Year 4</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end items-center space-x-2">
          <button
            type="button"
            onClick={() => handleKeepCourseClick(selectedCourses, selectedYear, selectedSemester)}
            disabled={selectedCourses.length === 0}
            className={`!text-blue-700 !hover:text-white border border-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-2 py-2 text-center transition-all duration-300 flex items-center justify-center ${
              selectedCourses.length === 0
                ? 'hover:bg-blue-200 cursor-not-allowed'
                : 'hover:bg-blue-700 hover:!text-white'
            }`}
          >
            <FaCheck className="w-3 h-3 mr-2" />
            Keep Selected
          </button>
          <button
            type="button"
            onClick={() => handleKeepCourseClick(selectedCourses, selectedYear, selectedSemester, true)}
            disabled={finalizedCourses.length === 0}
            className={`!text-blue-700 hover:!text-white border border-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:bg-blue-500 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-2 py-2 text-center transition-all duration-300 flex items-center justify-center ${
              finalizedCourses.length === 0
                ? 'border-gray-500 cursor-not-allowed'
                : 'border-gray-500 hover:!bg-blue-700'
            }`}
          >
            <FaCheckDouble className="w-3 h-3 mr-2" />
            Keep All
          </button>
        </div>
      </div>
      {showModal && currentCourse && (
        <PossibilitiesModal
          course={currentCourse}
          isOpen={true}
          onClose={handleCloseModal}
          onKeepCourse={handlePossibilitySelection}
          year={selectedYear}
          semester={selectedSemester}
          finalizedSchedule={finalizedSchedule}
          majorRecData={majorRecData}
          finalizedCourses={finalizedCourses1}
        />
      )}
      {showModal1 && currentCourse && (
        // Render another modal if needed
        null
      )}
    </div>
  );
};

export default FinalizedCoursesTwo;