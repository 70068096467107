import React from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const ProfileDrawer = ({ isOpen, onClose }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const userFullName = () => {
    if (currentUser && currentUser.firstName && currentUser.lastName) {
      return `${currentUser.firstName} ${currentUser.lastName}`;
    }
    return 'User';
  };

  return (
    <div className={`fixed top-0 left-64 h-full w-80 bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out z-40`}>
      <div className="p-4 border-b border-gray-200 text-center">
        <div className="w-16 h-16 bg-gray-300 rounded-full mx-auto mb-4 flex items-center justify-center">
          <img className="w-16 h-16 rounded-full" src={currentUser?.photoUrl || '/default.jpg'} alt="user photo" />
        </div>
        <h2 className="text-xl font-semibold">{userFullName()}</h2>
        <button className="mt-2 text-sm text-blue-600 hover:underline" onClick={handleLogout}>Logout</button>
      </div>
      <div className="p-4">
        <ul className="space-y-4">
          <li className="text-blue-600 hover:underline cursor-pointer">Profile</li>
          <li className="text-blue-600 hover:underline cursor-pointer">Notifications</li>
          <li className="text-blue-600 hover:underline cursor-pointer">Settings</li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileDrawer;
