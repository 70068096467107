import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.min.css'; // Import Bulma CSS

const AddExtracurricularForm = () => {
  const [formData, setFormData] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  useEffect(() => {
    // Fetch extracurricular data from the backend
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:4000/extracurricular');
        const data = await response.json();

        // Check if data is not undefined or null
        if (!data || typeof data !== 'object') {
          console.error('Extracurricular data not found or is not an object.');
          return;
        }

        // Assuming data is an object, get the values (rows) as an array
        const dataArray = Object.values(data);

        // Check if dataArray is not empty
        if (!dataArray || dataArray.length === 0) {
          console.error('Extracurricular data is empty.');
          return;
        }

        // Extract the keys (column names) from the first row (assuming it's an array)
        const columns = Object.keys(dataArray[0]);
        setFormFields(columns);

        // Initialize formData with empty values for each field
        const initialFormData = {};
        columns.forEach((column) => {
          initialFormData[column] = '';
        });
        setFormData(initialFormData);
      } catch (error) {
        console.error('Error fetching extracurricular data:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRatingChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:4000/extracurricular', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        console.error('Error adding extracurricular. Status:', response.status);
        setSubmissionStatus('error');
        return;
      }

      const responseData = await response.json();
      console.log('Extracurricular added successfully:', responseData);

      // Optionally, reset the form after successful submission
      const resetFormData = {};
      formFields.forEach((field) => {
        resetFormData[field] = '';
      });
      setFormData(resetFormData);

      // Set submission status to success
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error adding extracurricular:', error);
      setSubmissionStatus('error');
    }
  };

  return (
    <div className="section">
      <div className="container">
        <div className="box">
          <h2 className="title is-2 has-text-centered">Add New Extracurricular</h2>
          {submissionStatus === 'success' && <p className="notification is-success">Extracurricular added successfully!</p>}
          {submissionStatus === 'error' && <p className="notification is-danger">Error adding extracurricular. Please try again.</p>}
          <form onSubmit={handleSubmit}>
            {formFields
              .filter((field) => !field.toLowerCase().includes('rating'))
              .map((field) => (
                <div key={field} className="field">
                  <label htmlFor={field} className="label">
                    {field.replace(/([A-Z])/g, ' $1').trim()}:
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      id={field}
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      className="input"
                    />
                  </div>
                </div>
              ))}

            <h3 className="title is-4 mt-4 has-text-centered">Rating Questions:</h3>
            {formFields
              .filter((field) => field.toLowerCase().includes('rating'))
              .map((field) => (
                <div key={field} className="field">
                  <label htmlFor={field} className="label">
                    {field.replace(/([A-Z])/g, ' $1').trim()}:
                  </label>
                  <div className="control">
                    <input
                      type="range"
                      id={field}
                      name={field}
                      min="1"
                      max="5"
                      value={formData[field]}
                      onChange={(e) => handleRatingChange(field, e.target.value)}
                      className="slider has-output"
                    />
                    <output htmlFor={field} className="output">
                      {formData[field]}
                    </output>
                  </div>
                </div>
              ))}

            <div className="field is-grouped is-grouped-centered mt-4">
              <div className="control">
              <button type="submit" className="button" style={{ backgroundColor: '#235dfc', color: 'white', padding: '8px 16px', borderRadius: '5px', outline: 'none' }}>
                  Add Extracurricular
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddExtracurricularForm;
