// MilestonesSection.js

import React from 'react';

const MilestonesSection = ({ milestones, setMilestones }) => {
  const handleRemoveMilestone = (index) => {
    const updatedMilestones = milestones.filter((_, idx) => idx !== index);
    setMilestones(updatedMilestones);
  };

  const handleAddMilestone = () => {
    setMilestones([
      ...milestones,
      {
        courseInfo_courseNumber: '',
        courseYear: '',
        CourseSem: '',
        Description: '',
        courseType: 'Milestone',
      },
    ]);
  };

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Milestones</h3>
      {milestones.length === 0 ? (
        <p>No milestones currently included</p>
      ) : (
        <ul>
          {milestones.map((milestone, index) => (
            <li key={index} className="mt-2">
              <div className="flex items-center justify-between">
                <input
                  type="text"
                  value={milestone.Description}
                  onChange={(e) => {
                    const updatedMilestones = [...milestones];
                    updatedMilestones[index].Description = e.target.value;
                    setMilestones(updatedMilestones);
                  }}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Enter Milestone Description"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveMilestone(index)}
                  className="bg-red-600 text-white px-2 py-1 rounded ml-2"
                >
                  Remove
                </button>
              </div>
              <div className="flex mt-2">
                <select
                  value={milestone.courseYear}
                  onChange={(e) => {
                    const updatedMilestones = [...milestones];
                    updatedMilestones[index].courseYear = e.target.value;
                    setMilestones(updatedMilestones);
                  }}
                  className="w-1/2 p-2 border border-gray-300 rounded"
                >
                  <option value="" disabled>
                    Select Course Year
                  </option>
                  {[1, 2, 3, 4].map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <select
                  value={milestone.CourseSem}
                  onChange={(e) => {
                    const updatedMilestones = [...milestones];
                    updatedMilestones[index].CourseSem = e.target.value;
                    setMilestones(updatedMilestones);
                  }}
                  className="w-1/2 p-2 border border-gray-300 rounded ml-2"
                >
                  <option value="" disabled>
                    Select Semester
                  </option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                </select>
              </div>
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={handleAddMilestone}
        className="w-full mb-4 mt-2 bg-blue-600 text-white p-2 rounded"
      >
        Add Milestone
      </button>
    </div>
  );
};

export default MilestonesSection;
