import React, { useState, useEffect } from 'react';
import { TextInput, Button } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import DemandGraph from './Course/DemandGraph';
import Instructors from './Course/Instructors';
import PopularPrograms from './Course/PopularPrograms';
import StudentDistribution from './Course/StudentDistribution';
import GradeDistribution from './Course/GradeDistribution';
import CustomSidebar from '../Sidebar/Sidebar';

const CoursesAnalytics = () => {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('/courseData.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCourses(data.courses);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchCourses();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length > 0) {
      const filteredSuggestions = courses
        .map(course => course.name)
        .filter(name => name.toLowerCase().includes(value.toLowerCase()));
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setSuggestions([]);
  };

  const handleSearch = () => {
    const course = courses.find(course => course.name.toLowerCase() === searchTerm.toLowerCase());
    setSelectedCourse(course);
  };

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-64 p-6 mx-auto">
        <div className="w-full max-w-4xl bg-white p-4 shadow-md flex justify-center items-center space-x-4 z-20 sticky top-0 ml-0">
          <div className="relative w-full">
            <TextInput
              id="search-course"
              type="text"
              placeholder="Search for a course"
              required={true}
              icon={HiSearch}
              value={searchTerm}
              onChange={handleInputChange}
            />
            {suggestions.length > 0 && (
              <ul className="absolute bg-white border border-gray-300 rounded w-full mt-1 max-h-40 overflow-y-auto z-10">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <Button color="blue" onClick={handleSearch}>
            Search
          </Button>
        </div>
        {selectedCourse && (
          <div>
            <div className="mt-6 w-full"><DemandGraph course={selectedCourse} /></div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-6">
              <StudentDistribution course={selectedCourse} />
              <Instructors course={selectedCourse} />
              <PopularPrograms course={selectedCourse} />
              <GradeDistribution course={selectedCourse} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursesAnalytics;
