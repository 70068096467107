import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../NavBar';
import CollapsibleClassSearch from './CollapsibleClassSearch';
import { useNavigate, useLocation } from 'react-router-dom';
import { useClassContext } from '../ClassContext';
import ClassCart from './ClassCart';
import { db, auth } from '../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const ClassSearchOverlay = () => {
  const { fetchedCourseData, fetchedPrereqData, fetchedElective, fetchedMajorRecs } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const yearNumber = parseInt(searchParams.get('yearNumber'));
  const semester = searchParams.get('semester');
  const [displayedData, setDisplayedData] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [displayedCourseCount, setDisplayedCourseCount] = useState(0);

  const { currentUser } = useAuth();
  const [showRecommendedCourses, setShowRecommendedCourses] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [subjectFilter, setSubjectFilter] = useState('');
  const [difficultyFilter, setDifficultyFilter] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [majorFilter, setMajorFilter] = useState('');
  const [creditsFilter, setCreditsFilter] = useState('');
  const [filteredResultsCount, setFilteredResultsCount] = useState(0);

  const [userRecsData, setUserRecsData] = useState({});

  const [loading, setLoading] = useState(true);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [finalizedCourseIds, setFinalizedCourseIds] = useState([]);
  const [showInfo, setShowInfo] = useState(false);


  const { selectedClasses, setSelectedClasses } = useClassContext();
  const [classesInCart, setClassesInCart] = useState([]);
  const history = useNavigate();
  const sentinelRef = useRef(null);

  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);
  useEffect(() => {
    if (fetchedMajorRecs) {
      const transformedMajorRecData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});
      setTransformedMajorRecData(transformedMajorRecData);
    }
  }, [fetchedMajorRecs]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = currentUser;
  
        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();
  
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || '';
            const courseIds = userData.schedules[activeTab] || [];
            const backendSurveyAnswers = userData.surveyAnswers || [];
            const backendUserRecData = userData.recommendationRatings || {};
            setFinalizedCourseIds(courseIds);
            setSurveyAnswers(backendSurveyAnswers);
            setUserRecsData(backendUserRecData);
  
            const allCourseIds = [];
  
            for (const yearKey in courseIds) {
              const yearObj = courseIds[yearKey];
  
              // Iterate over each semester in the year
              for (const semesterKey in yearObj) {
                const semesterIds = yearObj[semesterKey].courses;
  
                // Append semesterIds to allCourseIds
                allCourseIds.push(...semesterIds);
              }
            }
            const uniqueCourseIds = [...new Set(allCourseIds)];
  
            setSelectedClasses(uniqueCourseIds);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      setLoading(false);
    };
  
    fetchUserData();
  }, [currentUser]);
  

  const filterDataAndSetDisplay = () => {
    if (fetchedCourseData) {
      const filtered = Object.values(fetchedCourseData).filter(item => {
        const majors = item.Major.split(',').map(major => major.trim());
        if (
          selectedClasses.some(selected => selected.courseInfo_courseName === item.courseInfo_courseName) ||
          (subjectFilter !== '' && item.courseCat.toLowerCase() !== subjectFilter.toLowerCase()) ||
          (difficultyFilter !== '' && item.courseDif.toLowerCase() !== difficultyFilter.toLowerCase()) ||
          (levelFilter !== '' && parseInt(item.courseYear) !== parseInt(levelFilter)) ||
          (majorFilter !== '' && !majors.includes(majorFilter)) ||
          (creditsFilter !== '' && parseFloat(item.Credits) !== parseFloat(creditsFilter))
        ) {
          return false;
        }

        const nameMatch = item.courseInfo_courseName.toLowerCase().includes(searchQuery.toLowerCase());
        const numberMatch = item.courseInfo_courseNumber.toLowerCase().includes(searchQuery.toLowerCase());

        return nameMatch || numberMatch;
      });
  
      let sortedData;
      if (showRecommendedCourses) {
        const calculateRecScore = require('../CourseRecommendation');
        sortedData = filtered
          .map((course) => {
            const recScore = calculateRecScore(
              transformedMajorRecData[course.Major],
              userRecsData,
              course,
              surveyAnswers
            );
            return { ...course, recScore };
          })
          .sort((a, b) => b.recScore - a.recScore);
      } else {
        sortedData = filtered.sort((a, b) =>
          a.courseInfo_courseNumber.localeCompare(b.courseInfo_courseNumber)
        );
      }
  
      setFilteredData(sortedData);
      setRecommendedCourses(sortedData);
      setDisplayedData(sortedData.slice(0, 100));
      setCurrentBatch(1);
      setDisplayedCourseCount(sortedData.length);
    }
  };

  useEffect(() => {
    filterDataAndSetDisplay();
  }, [
    fetchedCourseData,
    selectedClasses,
    subjectFilter,
    difficultyFilter,
    searchQuery,
    levelFilter,
    majorFilter,
    creditsFilter,
    showRecommendedCourses,
    surveyAnswers,
    userRecsData
  ]);

  const loadMoreData = () => {
    const nextBatch = currentBatch + 1;
    const newData = filteredData.slice(0, nextBatch * 100);
    setDisplayedData(newData);
    setCurrentBatch(nextBatch);
  };


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        console.log(entries[0].isIntersecting, entries[0].intersectionRatio > 0, entries[0].intersectionRatio, displayedData, filteredData, 'hiasdf')
        if (entries[0].isIntersecting && displayedData.length < filteredData.length) {
          loadMoreData();
        }
      },
      {
        // Set the threshold to 0 (any part of the element visible triggers the observer)
        threshold: 0,
        // Trigger the callback when the target element is 100px away from the viewport
        rootMargin: '100px'
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [displayedData, filteredData]);

  const handleResetFilters = () => {
    setSubjectFilter('');
    setDifficultyFilter('');
    setLevelFilter('');
    setMajorFilter('');
    setCreditsFilter('');
    setShowRecommendedCourses(false);
  };

  const handleShowRecommendations = async () => {
    try {
      setShowRecommendedCourses(!showRecommendedCourses);
    } catch (error) {
      console.error('Error fetching recommended courses:', error);
    }
  };

  const handleAddClass = (classData) => {
    const isClassAlreadyAdded = selectedClasses.some(selected => selected.courseInfo_courseNumber === classData.courseInfo_courseNumber) || classesInCart.some(cartItem => cartItem.courseInfo_courseNumber === classData.courseInfo_courseNumber);

    if (!isClassAlreadyAdded) {
      setClassesInCart(prevClasses => [...prevClasses, classData]);
    } else {
      toast.error(`Class with course number ${classData.courseInfo_courseNumber} is already in your cart or schedule.`);
    }
  };


  const handleRemoveClass = (classToRemove) => {
    const updatedClassesInCart = classesInCart.filter((classData) => classData !== classToRemove);
    setClassesInCart(updatedClassesInCart);
  };
  
  const handleAddCourses = async () => {
    const userId = currentUser.uid;
  
    try {
      const userDocRef = db.collection('users').doc(userId);
      const userDoc = await userDocRef.get();
      const userData = userDoc.data();
      const activeTab = userData.activeTab;
      const previousFinalizedSchedule = userData.schedules[activeTab] || {};
      const completedSemesters = userData.completedSemesters || {};
      const numYears = previousFinalizedSchedule?.numYears || 4; // Default to 4 years if no finalizedSchedule
      const lastYear = `year${numYears}`;
      const lastSemester = "S2"; // Spring semester
  
      const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
  
      // Helper function to find the earliest incomplete semester
      const findEarliestIncompleteSemester = (courseNumber) => {
        for (let year = 1; year <= numYears; year++) {
          const yearKey = `year${year}`;
          for (const sem of ["S1", "S2"]) {
            // Initialize year and semester if not present
            if (!updateObj[yearKey]) {
              updateObj[yearKey] = {};
            }
            if (!updateObj[yearKey][sem]) {
              updateObj[yearKey][sem] = { courses: [] };
            }
  
            // Check if the semester is completed
            if (completedSemesters[yearKey] && completedSemesters[yearKey][sem]) {
              continue; // Skip completed semesters
            }
  
            // Check if the course is already in this semester
            if (!updateObj[yearKey][sem].courses.includes(courseNumber)) {
              return { targetYear: yearKey, targetSemester: sem };
            }
          }
        }
        // If all semesters are completed or full, return null
        return null;
      };
  
      for (const selectedClass of classesInCart) {
        const courseNumber = selectedClass.courseInfo_courseNumber;
        let courseSemester = selectedClass.CourseSem;
        let courseYear = selectedClass.courseYear;
  
        // Handle courses without a target semester
        if (!courseSemester) {
          // Assume default semester if not provided, e.g., "S1"
          courseSemester = "S1"; // or derive based on your logic
        }
  
        // Determine the target year and semester
        let targetYear = `year${courseYear}`;
        let targetSemester = courseSemester;
  
        // Initialize year and semester if not present
        if (!updateObj[targetYear]) {
          updateObj[targetYear] = {};
        }
        if (!updateObj[targetYear][targetSemester]) {
          updateObj[targetYear][targetSemester] = { courses: [] };
        }
  
        // Function to convert semester codes to readable format
        const getReadableSemester = (sem) => (sem === "S1" ? "Semester 1" : "Semester 2");
  
        // **Scenario 1:** If the target semester is marked as complete
        if (completedSemesters[targetYear] && completedSemesters[targetYear][targetSemester]) {
          const earliestSemester = findEarliestIncompleteSemester(courseNumber);
          if (earliestSemester) {
            targetYear = earliestSemester.targetYear;
            targetSemester = earliestSemester.targetSemester;
  
            // Display info toast notification for completed semester
            toast.info(
              `${getReadableSemester(courseSemester)} of Year ${courseYear} is marked as complete. "${courseNumber}" has been added to ${getReadableSemester(targetSemester)} of ${targetYear.charAt(0).toUpperCase() + targetYear.slice(1)}.`,
              { position: "top-right", autoClose: 5000 }
            );
          } else {
            // No available semesters to add the course
            toast.error(`No available semesters to add "${courseNumber}". Please review your schedule.`, { position: "top-right", autoClose: 5000 });
            continue; // Skip adding this course
          }
        }
        // **Scenario 2:** If the course is already in the target semester
        else if (updateObj[targetYear][targetSemester].courses.includes(courseNumber)) {
          const earliestSemester = findEarliestIncompleteSemester(courseNumber);
          if (earliestSemester) {
            targetYear = earliestSemester.targetYear;
            targetSemester = earliestSemester.targetSemester;
  
            // Display info toast notification for duplicate course in semester
            toast.info(
              `The course "${courseNumber}" is already in ${getReadableSemester(courseSemester)} of Year ${courseYear}. It has been moved to ${getReadableSemester(targetSemester)} of ${targetYear.charAt(0).toUpperCase() + targetYear.slice(1)}.`,
              { position: "top-right", autoClose: 5000 }
            );
          } else {
            // No available semesters to add the course
            toast.error(`No available semesters to add "${courseNumber}". Please review your schedule.`, { position: "top-right", autoClose: 5000 });
            continue; // Skip adding this course
          }
        }
  
        // Ensure that the target semester is not completed
        if (completedSemesters[targetYear] && completedSemesters[targetYear][targetSemester]) {
          toast.error(`Cannot add "${courseNumber}" to a completed semester (${getReadableSemester(targetSemester)} of ${targetYear.charAt(0).toUpperCase() + targetYear.slice(1)}).`, { position: "top-right", autoClose: 5000 });
          continue; // Skip adding this course
        }
  
        // Add the course to the determined semester
        if (!updateObj[targetYear][targetSemester].courses) {
          updateObj[targetYear][targetSemester].courses = [];
        }
        if (!updateObj[targetYear][targetSemester].courses.includes(courseNumber)) {
          updateObj[targetYear][targetSemester].courses.push(courseNumber);
          console.log(`Course ${courseNumber} added to ${targetYear} ${targetSemester} successfully.`);
        } else {
          console.log(`Course ${courseNumber} is already present in ${targetYear} ${targetSemester}.`);
        }
      }
  
      await userDocRef.update({ [`schedules.${activeTab}`]: updateObj });
  
      setClassesInCart([]);
      history('/Plan'); // Navigate immediately; ToastContainer is global
    } catch (error) {
      console.error('Error adding courses to finalizedSchedule:', error);
      toast.error('An error occurred while adding courses. Please try again.', { position: "top-right", autoClose: 5000 });
    }
  };
  
  const [uniqueMajors, setUniqueMajors] = useState([]);

  useEffect(() => {
    if (fetchedCourseData && Object.keys(fetchedCourseData).length > 0) {
      const uniqueMajorsArray = Array.from(
        new Set(
          Object.values(fetchedCourseData).flatMap(item => 
            item.Major.split(',').map(major => major.trim())
          )
        )
      ).filter(Boolean);
      
      setUniqueMajors(uniqueMajorsArray);
    }
  }, [fetchedCourseData]);
  if (loading) {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const toggleInfo = () => {
    setShowInfo(prev => !prev);
  };

  return (
    <div>
      <NavBar />
      <div className="mt-20 grid grid-cols-10 gap-3">
        <div className="col-span-2">
          <div className="p-3 bg-gray-100 rounded-lg">
            <h5 className="text-lg text-center font-bold mb-4">Filters</h5>
            <div className="mb-2">
              <label htmlFor="searchQuery" className="block text-sm font-medium text-gray-700 mb-1">Search:</label>
              <input
                type="text"
                id="searchQuery"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              />
            </div>
            <div className="mb-2">
              <label htmlFor="levelFilter" className="block text-sm font-medium text-gray-700 mb-1">Level:</label>
              <select
                id="levelFilter"
                value={levelFilter}
                onChange={(e) => setLevelFilter(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              >
                <option value="">Select Level</option>
                <option value="1">100</option>
                <option value="2">200</option>
                <option value="3">300</option>
                <option value="4">400</option>
                <option value="5">500</option>
                <option value="6">600</option>
                <option value="7">700</option>
                <option value="8">800</option>
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="majorFilter" className="block text-sm font-medium text-gray-700 mb-1">Major:</label>
              <select
                id="majorFilter"
                value={majorFilter}
                onChange={(e) => setMajorFilter(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              >
                <option value="">Select Major</option>
                {uniqueMajors.map((major, index) => (
                  <option key={index} value={major}>{major}</option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="creditsFilter" className="block text-sm font-medium text-gray-700 mb-1">Credits:</label>
              <select
                id="creditsFilter"
                value={creditsFilter}
                onChange={(e) => setCreditsFilter(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              >
                <option value="">Select Credits</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            {/* <div className="mt-2 text-left">
              <p><strong>{filteredResultsCount} {filteredResultsCount === 1 ? 'result' : 'results'}</strong></p>
            </div> */}
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-1"
              onClick={handleResetFilters}
            >
              Reset Filters
            </button>
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-1"
              onClick={handleShowRecommendations}
            >
              {showRecommendedCourses ? 'Hide Recommendations' : 'Show Recommendations' }
            </button>
            <div className="flex justify-center mt-2">
              <span className="bg-gray-200 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
                {displayedCourseCount} Courses Displayed
              </span>
            </div>
          </div>
        </div>

        <div className="col-span-6">
          <div className="p-3 bg-gray-100 rounded-lg w-full min-h-[600px]">
            
          <div>
            <div className="flex items-center justify-center mb-4">
              <h5 className="text-lg text-center font-bold">
                {showRecommendedCourses ? 'Recommended Courses' : 'Courses'}
              </h5>
              {showRecommendedCourses && (
                <div className="relative inline-block ml-2">
                  <button
                    className="p-1 rounded h-full flex items-center"
                    onClick={toggleInfo}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </button>
                </div>
              )}
            </div>

            {showInfo && (
              <div id="alert-additional-content-1" className="fixed top-4 right-4 z-50 p-4 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800" role="alert" style={{ width: '25rem' }}>
                <div className="flex items-center">
                  <svg className="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="sr-only">Info</span>
                  <h3 className="text-lg font-medium">Recommendation Information</h3>
                </div>
                <div className="mt-2 mb-4 text-sm">
                  Recommendation scores are determined by two factors: <br/>
                  1. The alignment of course content with your interests, skills, and personality traits, as indicated by Holland Codes. <br/>
                  2. The compatibility of course characteristics (e.g., project-based versus test-based) reported by past students with your preferences from the survey.
                </div>
                <div className="flex">
                  <button 
                    type="button" 
                    className="text-blue-800 bg-transparent border border-blue-800 hover:!bg-blue-900 hover:!text-white focus:!ring-4 focus:!outline-none focus:!ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:!bg-blue-900 dark:!border-blue-800 dark:!text-blue-400 dark:hover:!text-white dark:focus:!ring-blue-800" 
                    onClick={toggleInfo} 
                    aria-label="Close"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            )}
          </div>
                <div className="h-[550px] overflow-y-auto rounded">
            {(
            <>
                  {showRecommendedCourses ? (
                    <div>
                      {recommendedCourses.slice(0, currentBatch * 100).map((classData, index) => (
                        <CollapsibleClassSearch
                          key={index}
                          classData={classData}
                          onAddClass={handleAddClass}
                          inPlan={selectedClasses.includes(classData.courseInfo_courseNumber)} // Pass inPlan prop
                          isRec={true}
                        />
                      ))}
                    </div>
                  ) : (
                    <div>
                      {displayedData.map((classData, index) => (
                        <CollapsibleClassSearch
                          key={index}
                          classData={classData}
                          onAddClass={handleAddClass}
                          inPlan={selectedClasses.includes(classData.courseInfo_courseNumber)} // Pass inPlan prop
                          isRec={false}
                        />
                      ))}
                    </div>
                  )}
                </>
          )}
          <div ref={sentinelRef}></div>
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <ClassCart
            classesInCart={classesInCart}
            onRemoveClass={handleRemoveClass}
            onAddCourses={handleAddCourses}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassSearchOverlay;
