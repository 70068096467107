import React, { useState, useEffect, useRef } from 'react';
import PathwayCollapsibleClassSearch from './PathwayCollapsibleClassSearch';
import PathwayClassCart from '../PathwayClassSearch/PathwayClassCart';
import { useAuth } from '../../../../contexts/AuthContext';
import { db } from '../../../firebase';

const PathwayClassSearchOverlay = ({ onClose, onAddClass }) => {
  const { fetchedCourseData, currentUser } = useAuth();
  const [displayedData, setDisplayedData] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [displayedCourseCount, setDisplayedCourseCount] = useState(0);
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [subjectFilter, setSubjectFilter] = useState('');
  const [difficultyFilter, setDifficultyFilter] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [majorFilter, setMajorFilter] = useState('');
  const [creditsFilter, setCreditsFilter] = useState('');
  const [filteredResultsCount, setFilteredResultsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [duplicateClassMessage, setDuplicateClassMessage] = useState('');
  const [classesInCart, setClassesInCart] = useState([]);
  const sentinelRef = useRef(null);
  const [selectedClasses, setSelectedClasses] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = currentUser;
        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            const courseIds = userData.finalizedSchedule || [];

            setSelectedClasses(Array.isArray(courseIds) ? courseIds : []);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = fetchedCourseData;
        setData(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchedCourseData]);

  useEffect(() => {
    const filterDataAndSetDisplay = () => {
      const filtered = Object.values(data).filter(item => {
        const majors = item.Major.split(',').map(major => major.trim());
        if (
          (Array.isArray(selectedClasses) && selectedClasses.some(selected => selected.courseInfo_courseName === item.courseInfo_courseName)) ||
          (subjectFilter !== '' && item.courseCat.toLowerCase() !== subjectFilter.toLowerCase()) ||
          (difficultyFilter !== '' && item.courseDif.toLowerCase() !== difficultyFilter.toLowerCase()) ||
          (levelFilter !== '' && parseInt(item.courseYear) !== parseInt(levelFilter)) ||
          (majorFilter !== '' && !majors.includes(majorFilter)) ||
          (creditsFilter !== '' && parseFloat(item.Credits) !== parseFloat(creditsFilter))
        ) {
          return false;
        }
        return Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredData(filtered);
      setDisplayedData(filtered.slice(0, 100));
      setCurrentBatch(1);
      setDisplayedCourseCount(filtered.length);
    };
    filterDataAndSetDisplay();
  }, [data, selectedClasses, subjectFilter, difficultyFilter, searchQuery, levelFilter, majorFilter, creditsFilter]);

  const loadMoreData = () => {
    const nextBatch = currentBatch + 1;
    const newData = filteredData.slice(0, nextBatch * 100);
    setDisplayedData(newData);
    setCurrentBatch(nextBatch);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && displayedData.length < filteredData.length) {
          loadMoreData();
        }
      },
      { threshold: 1.0 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [displayedData, filteredData]);

  const handleResetFilters = () => {
    setSubjectFilter('');
    setDifficultyFilter('');
    setLevelFilter('');
    setMajorFilter('');
    setCreditsFilter('');
  };

  const handleAddClass = (classData, section) => {
    const isClassAlreadyAdded =
      (Array.isArray(selectedClasses) &&
        selectedClasses.some(
          (selected) =>
            selected.courseInfo_courseNumber ===
            classData.courseInfo_courseNumber
        )) ||
      classesInCart.some(
        (cartItem) =>
          cartItem.courseInfo_courseNumber ===
          classData.courseInfo_courseNumber
      );
  
    if (!isClassAlreadyAdded) {
      setClassesInCart([...classesInCart, { ...classData, section }]);
      setDuplicateClassMessage('');
    } else {
      setDuplicateClassMessage(
        `Class with course number ${classData.courseInfo_courseNumber} is already in your cart or schedule.`
      );
    }
  };  

  const handleRemoveClass = (classToRemove) => {
    const updatedClassesInCart = classesInCart.filter((classData) => classData !== classToRemove);
    setClassesInCart(updatedClassesInCart);
  };

  const handleAddToPathway = () => {
    if (classesInCart.length > 0) {
      // Assume section and index are passed or set correctly within the component
      const section = classesInCart[0].section || 'courses'; // Default to 'courses' if not set
      const index = classesInCart[0].index || 0; // Set a default index if not provided
  
      // Pass the classes along with the correct section and index
      onAddClass(classesInCart, section, index);
      setClassesInCart([]);
      onClose(); // Close the class search overlay after adding the class
    }
  };  

  const uniqueMajors = Array.from(new Set(Object.values(data).flatMap(item => item.Major.split(',').map(major => major.trim())))).filter(Boolean);

  return (
    <div className="pathway-class-search-overlay">
      <button
        className="bg-gray-600 text-white px-4 py-2 rounded mb-4"
        onClick={onClose}
      >
        Back to Survey
      </button>      <div className="grid grid-cols-10 gap-3">
        <div className="col-span-2">
          <div className="p-3 bg-gray-200">
            <h5 className="text-lg text-center font-bold mb-4">Filters</h5>
            <div className="mb-2">
              <label htmlFor="searchQuery" className="block text-sm font-medium text-gray-700 mb-1">Search:</label>
              <input
                type="text"
                id="searchQuery"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              />
            </div>
            <div className="mb-2">
              <label htmlFor="levelFilter" className="block text-sm font-medium text-gray-700 mb-1">Level:</label>
              <select
                id="levelFilter"
                value={levelFilter}
                onChange={(e) => setLevelFilter(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              >
                <option value="">Select Level</option>
                <option value="1">1000</option>
                <option value="2">2000</option>
                <option value="3">3000</option>
                <option value="4">4000</option>
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="majorFilter" className="block text-sm font-medium text-gray-700 mb-1">Major:</label>
              <select
                id="majorFilter"
                value={majorFilter}
                onChange={(e) => setMajorFilter(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              >
                <option value="">Select Major</option>
                {uniqueMajors.map((major, index) => (
                  <option key={index} value={major}>{major}</option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="creditsFilter" className="block text-sm font-medium text-gray-700 mb-1">Credits:</label>
              <select
                id="creditsFilter"
                value={creditsFilter}
                onChange={(e) => setCreditsFilter(e.target.value)}
                className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
              >
                <option value="">Select Credits</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-1"
              onClick={handleResetFilters}
            >
              Reset Filters
            </button>
            <div className="flex justify-center mt-2">
              <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
                {displayedCourseCount} Courses Displayed
              </span>
            </div>
          </div>
        </div>
        <div className="col-span-6">
          <div className="p-3 bg-gray-200 w-full min-h-[600px]">
            <h5 className="text-lg text-center font-bold mb-4">Courses</h5>
            <div className="h-[550px] overflow-y-auto rounded">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <div>
                  {displayedData.map((classData, index) => (
                    <PathwayCollapsibleClassSearch
                      key={index}
                      classData={classData}
                      onAddClass={handleAddClass}
                      inPlan={Array.isArray(selectedClasses) && selectedClasses.some(selected => selected.courseInfo_courseNumber === classData.courseInfo_courseNumber)}
                    />
                  ))}
                </div>
              )}
              <div ref={sentinelRef}></div>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <PathwayClassCart
            classesInCart={classesInCart}
            onRemoveClass={handleRemoveClass}
            onAddCourses={handleAddToPathway} // Ensure this function is passed correctly
          />
        </div>
      </div>
      {duplicateClassMessage && <p className="text-red-600">{duplicateClassMessage}</p>}
    </div>
  );
};

export default PathwayClassSearchOverlay;
