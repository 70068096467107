import React, { useState, useEffect } from 'react';
import { TextInput, Select, Button } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import ExceptionsSummary from './Audit/ExceptionsSummary';
import AuditAnalysisTable from './Audit/AuditAnalysisTable';
import TopUsers from './Audit/TopUsers';
import CustomSidebar from '../Sidebar/Sidebar';

const addDropdownEventListeners = () => {
  const dropdowns = document.querySelectorAll('.dropdown');

  dropdowns.forEach((dropdown) => {
    const toggle = dropdown.querySelector('.dropdown-toggle');
    if (toggle) {
      toggle.addEventListener('click', () => {
        dropdown.classList.toggle('is-active');
      });
    }
  });
};

const AuditAnalytics = () => {
  const [searchProgram, setSearchProgram] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [showAuditData, setShowAuditData] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredExceptions, setFilteredExceptions] = useState(null);  // New state for exceptions data
  const [filteredUsers, setFilteredUsers] = useState([]);  // New state for users data
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    fetch('/auditData.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setPrograms(data.programs);
      })
      .catch(error => console.error('Error loading the audit data:', error));
  }, []);

  useEffect(() => {
    if (showAuditData) {
      addDropdownEventListeners();
    }
  }, [showAuditData]);

  const handleSearch = () => {
    if (searchProgram && selectedYear) {
      const programData = programs.find(prog => prog.name.toLowerCase() === searchProgram.toLowerCase());
      if (programData) {
        const yearData = programData.years[selectedYear];
        if (yearData) {
          // Transform the data for the audit analysis table
          const transformedData = yearData.popularRequirements.map(req => ({
            program: programData.name,
            year: selectedYear,
            ...req
          }));
  
          // Aggregate user data for the selected year
          const usersData = yearData.users.map(user => ({
            program: programData.name,
            year: selectedYear,
            ...user
          }));
  
          setFilteredData(transformedData);
          setFilteredExceptions(yearData ? { exceptions: yearData.exceptions, students: yearData.students } : null);  // Set exceptions data
          setFilteredUsers(usersData);  // Set users data
          setShowAuditData(true);
        }
      }
    }
  };
  

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchProgram(value);
    if (value.length > 0) {
      const filteredSuggestions = programs
        .map(prog => prog.name)
        .filter(name => name.toLowerCase().includes(value.toLowerCase()));
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchProgram(suggestion);
    setSuggestions([]);
  };

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-64 p-6 mx-auto">
        <div className="w-full max-w-4xl bg-white p-4 shadow-md flex justify-center items-center space-x-4 z-20 sticky top-0 ml-0">
          <div className="relative w-full">
            <TextInput
              id="search-program"
              type="text"
              placeholder="Search for a program"
              required={true}
              icon={HiSearch}
              value={searchProgram}
              onChange={handleInputChange}
            />
            {suggestions.length > 0 && (
              <ul className="absolute bg-white border border-gray-300 rounded w-full mt-1 max-h-40 overflow-y-auto z-10">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <Select
            id="select-year"
            required={true}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="w-40"  // Adjust the width as needed
          >
            <option value="">Year</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            {/* Add more options as needed */}
          </Select>
          <Button color="blue" onClick={handleSearch}>
            Search
          </Button>
        </div>
        {showAuditData && (
          <div className="pt-6">
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-1 space-y-4">
                <ExceptionsSummary data={filteredExceptions} />  {/* Pass exceptions data */}
                <TopUsers data={filteredUsers} />  {/* Pass users data */}
              </div>
              <div className="col-span-3">
                <AuditAnalysisTable data={filteredData} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuditAnalytics;
