import React, { useEffect, useState } from 'react';
import Talk from 'talkjs';

const ChatComponent = ({ currentUser }) => {
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);

    const otherUser = {
        id: '654321',
        name: 'Bob',
        email: 'bob@example.com',
        photoUrl: 'https://talkjs.com/images/avatar-5.jpg'
    };

    const initializeChat = () => {
        if (currentUser && currentUser.id) {
            Talk.ready.then(() => {
                const me = new Talk.User({
                    id: currentUser.id,
                    name: currentUser.name,
                    email: currentUser.email,
                    photoUrl: currentUser.photoUrl,
                    role: currentUser.role || 'default',
                });

                const other = new Talk.User({
                    id: otherUser.id,
                    name: otherUser.name,
                    email: otherUser.email,
                    photoUrl: otherUser.photoUrl,
                    role: 'default',
                });

                const session = new Talk.Session({
                    appId: 'tm9gzpVl',
                    me: me,
                });

                const conversation = session.getOrCreateConversation(
                    Talk.oneOnOneId(me, other)
                );

                conversation.setParticipant(me);
                conversation.setParticipant(other);

                const inbox = session.createInbox({ selected: conversation });
                inbox.mount(document.getElementById('talkjs-container'));
            }).catch((error) => {
                // console.error('TalkJS initialization error:', error);
                setError('Failed to initialize chat. Retrying...');
                setRetryCount(retryCount + 1);
            });
        }
    };

    useEffect(() => {
        if (retryCount < 3) {
            initializeChat();
        } else {
            setError('Failed to initialize chat after multiple attempts. Please try again later.');
        }
    }, [currentUser, retryCount]);

    return (
        <div>
            {error ? (
                <div className="text-red-500">{error}</div>
            ) : (
                <div id="talkjs-container" style={{ height: '550px', width: '100%' }} />
            )}
        </div>
    );
};

export default ChatComponent;