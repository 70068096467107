import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextInput } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { FaFilter, FaGraduationCap, FaBook, FaUser } from 'react-icons/fa'; // Import necessary icons
import AdSemesterCard from './Advisor Review/AdSemCardComp';

const SearchComponent = ({ onSearch, onFilter }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [availableFilters, setAvailableFilters] = useState({ gradYear: [], program: [], advisors: [] });
    const [selectedFilters, setSelectedFilters] = useState({ gradYear: [], program: [], advisors: [] });
    const [visibleDropdown, setVisibleDropdown] = useState(null); // State to track which dropdown is open
    const [isFilterBarVisible, setIsFilterBarVisible] = useState(false);
    const dropdownRef = useRef(null); // Reference for the dropdown container

    const fetchFilters = useCallback(async () => {
        try {
            const response = await fetch('/filters.json');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAvailableFilters(data);
        } catch (error) {
            console.error('Error fetching filters:', error);
        }
    }, []);

    useEffect(() => {
        fetchFilters();
    }, [fetchFilters]);

    useEffect(() => {
        if (searchTerm !== '') {
            onSearch(searchTerm);
        }
    }, [searchTerm, onSearch]);

    useEffect(() => {
        onFilter(selectedFilters);
    }, [selectedFilters, onFilter]);

    const handleCheckboxChange = (category, value) => {
        setSelectedFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            if (newFilters[category].includes(value)) {
                newFilters[category] = newFilters[category].filter((item) => item !== value);
            } else {
                newFilters[category].push(value);
            }
            return newFilters;
        });
    };

    const toggleDropdownVisibility = (category) => {
        setVisibleDropdown((prevCategory) => (prevCategory === category ? null : category));
    };

    const toggleFilterBarVisibility = () => {
        setIsFilterBarVisible(!isFilterBarVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setVisibleDropdown(null); // Close dropdown when clicking outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderDropdown = (category, label, Icon) => (
        <div className="relative mt-2" ref={dropdownRef}>
            <div className="flex flex-col items-center">
                <button
                    className="flex items-center justify-center bg-white text-blue-600 border border-blue-600 rounded-full text-sm px-4 py-2.5 transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md mb-2"
                    type="button"
                    onClick={() => toggleDropdownVisibility(category)}
                >
                    <Icon className="mr-2" />
                    {label}
                    <svg
                        className="w-4 h-4 ml-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path d="M1 1l4 4 4-4" stroke="currentColor" />
                    </svg>
                </button>
                {visibleDropdown === category && (
                    <div className="z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 absolute top-full mt-2">
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                            {availableFilters[category].map((item, idx) => (
                                <li key={idx} className="px-4 py-2 flex items-center">
                                    <input
                                        type="checkbox"
                                        id={`${category}-${item}`}
                                        checked={selectedFilters[category].includes(item)}
                                        onChange={() => handleCheckboxChange(category, item)}
                                        className="form-checkbox rounded-full"
                                    />
                                    <label htmlFor={`${category}-${item}`} className="ml-2 text-gray-700">
                                        {item}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <div className="bg-white dark:bg-gray-800 p-4 border-b border-gray-300 shadow-sm sticky top-0 z-40">
            <div className="relative w-full mb-4">
                <TextInput
                    id="search-student"
                    type="text"
                    placeholder="Search for students"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 w-full"
                />
                <HiSearch className="absolute left-2 top-2 w-6 h-6 text-gray-500" />
            </div>
            {/* Filter Button */}
            <div className="flex justify-center mb-4">
                <button
                    onClick={toggleFilterBarVisibility}
                    className="text-gray-500 hover:text-gray-900 focus:outline-none"
                >
                    <FaFilter className="text-xl" />
                </button>
            </div>
            <div
                className={`flex flex-wrap justify-center space-x-4 transition-transform duration-500 ease-in-out ${
                    isFilterBarVisible ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                } overflow-visible`}
            >
                {renderDropdown('gradYear', 'Grad Year', FaGraduationCap)}
                {renderDropdown('program', 'Program', FaBook)}
                {renderDropdown('advisors', 'Advisors', FaUser)}
            </div>
        </div>
    );
};

const ResultsComponent = ({ students }) => {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [activeStudent, setActiveStudent] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [studentForPlan, setStudentForPlan] = useState(null); // State to hold the selected student for plan review
  const sidebarRef = useRef(); // Reference for sidebar
  const navigate = useNavigate();

  const handleStudentClick = (studentId) => {
      if (!isSelectingMultiple) {
          setActiveStudent(activeStudent === studentId ? null : studentId);
      } else {
          setSelectedStudents((prevSelected) => {
              if (prevSelected.includes(studentId)) {
                  return prevSelected.filter((id) => id !== studentId);
              } else {
                  return [...prevSelected, studentId];
              }
          });
      }
  };

  const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setIsSidebarVisible(false);
      }
  };

  useEffect(() => {
      if (isSidebarVisible) {
          document.addEventListener('mousedown', handleOutsideClick);
      } else {
          document.removeEventListener('mousedown', handleOutsideClick);
      }

      return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
      };
  }, [isSidebarVisible]);

  const handleAction = (action, studentId) => {
      console.log(`Action: ${action}, Student ID: ${studentId}`);
      if (action === 'Send Message') {
          navigate(`/messages/${studentId}`);
      } else if (action === 'Review Plan') {
          const student = students.find((s) => s.id === studentId);
          setStudentForPlan(student); // Update state to display AdSemesterCard for the selected student
      }
  };

  const renderActions = (studentId) => (
      <div className="absolute z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 mt-2">
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
              <li className="px-4 py-2">
                  <button
                      onClick={() => handleAction('Send Message', studentId)}
                      className="text-gray-700 dark:text-gray-200 text-left w-full"
                  >
                      Send Message
                  </button>
              </li>
              <li className="px-4 py-2">
                  <button
                      onClick={() => handleAction('Prompt to schedule meeting', studentId)}
                      className="text-gray-700 dark:text-gray-200 text-left w-full"
                  >
                      Prompt to schedule meeting
                  </button>
              </li>
              <li className="px-4 py-2">
                  <button
                      onClick={() => handleAction('Review Plan', studentId)}
                      className="text-gray-700 dark:text-gray-200 text-left w-full"
                  >
                      Review Plan
                  </button>
              </li>
              <li className="px-4 py-2">
                  <button
                      onClick={() => handleAction('Open Student Profile', studentId)}
                      className="text-gray-700 dark:text-gray-200 text-left w-full"
                  >
                      Open Student Profile
                  </button>
              </li>
          </ul>
      </div>
  );

  const getClassesTakenPercentage = (classesTaken) => {
      return (classesTaken / 40) * 100; // Calculate the percentage of classes taken
  };

  const getClassesRegisteredPercentage = (classesRegistered) => {
      return (classesRegistered / 40) * 100; // Calculate the percentage of classes registered
  };

  return (
      <div className="">
          {/* Sidebar */}
          <button
              onClick={() => setIsSidebarVisible(!isSidebarVisible)}
              className="fixed top-1/2 right-0 z-40 !bg-edvise text-white p-2 rounded-l"
          >
              {isSidebarVisible ? '→' : '←'}
          </button>

          <div
              ref={sidebarRef}
              className={`fixed top-0 right-0 h-full bg-white dark:bg-gray-800 shadow-md transition-transform transform ${
                  isSidebarVisible ? 'translate-x-0' : 'translate-x-full'
              } w-1/2 z-30 overflow-y-auto`}
          >
              <SearchComponent
                  onSearch={(term) => console.log(`Searching for: ${term}`)}
                  onFilter={(filters) => console.log('Applying filters:', filters)}
              />

              {/* Students List */}
              <div className="mt-4 p-4">
                  <ul>
                      {students.map((student) => (
                          <li
                              key={student.id}
                              className={`border p-4 rounded-lg flex items-center shadow-sm mb-4 cursor-pointer transition-colors ${
                                  selectedStudents.includes(student.id) || activeStudent === student.id
                                      ? 'bg-green-100'
                                      : 'bg-white hover:bg-gray-100'
                              }`}
                              onClick={() => handleStudentClick(student.id)}
                          >
                              <div className="flex-1">
                                  <h4 className="font-bold text-base m-0">{student.name}</h4>
                                  <div className="text-sm text-gray-600">
                                      <p>{student.major || 'N/A'}</p>
                                      <p>
                                          {student.minor !== 'No Minor'
                                              ? `${student.minor} Minor`
                                              : 'No Minor'}
                                      </p>
                                  </div>
                              </div>

                              {/* Course Progress UI */}
                              <div className="flex-1 text-center">
                                  <div className="font-bold text-sm text-gray-600">Course Progress</div>
                                  <div className="w-full bg-gray-200 rounded-full h-4 mt-2 relative">
                                      <div
                                          className="bg-green-500 h-full rounded-l-full transition-all duration-300"
                                          style={{
                                              width: `${getClassesRegisteredPercentage(student.planned || 0)}%`,
                                          }}
                                      ></div>
                                      <div
                                          className="bg-yellow-300 h-full rounded-r-full transition-all duration-300 absolute top-0 left-0"
                                          style={{
                                              width: `${getClassesTakenPercentage(student.taken || 0)}%`,
                                              marginLeft: `${getClassesRegisteredPercentage(student.planned || 0)}%`,
                                          }}
                                      ></div>
                                  </div>
                                  <div className="flex justify-between text-xs mt-1">
                                      <span className="bg-green-100 text-green-700 px-2 py-1 rounded">
                                          {student.planned || 0} Planned
                                      </span>
                                      <span className="bg-yellow-100 text-yellow-700 px-2 py-1 rounded">
                                          {student.taken || 0} Taken
                                      </span>
                                      <span className="bg-gray-100 text-gray-700 px-2 py-1 rounded">
                                          {40 - (student.taken || 0)} Remaining
                                      </span>
                                  </div>
                              </div>

                              {!isSelectingMultiple && activeStudent === student.id && renderActions(student.id)}
                          </li>
                      ))}
                  </ul>
              </div>
          </div>

          {/* Conditional message or plan display */}
          {studentForPlan ? (
              <div className="ml-4 mt-4 w-full">
                  <h2 className="text-xl font-bold mb-4">{studentForPlan.name}'s 4-Year Plan</h2>
                  <AdSemesterCard student={studentForPlan} className="w-full" />
              </div>
          ) : (
              <div className="flex items-center justify-center h-full">
                  <p className="text-gray-500 text-xl">Select a plan to review</p>
              </div>
          )}
      </div>
  );
};


export default ResultsComponent;
